import { makeStyles, createStyles } from "@material-ui/core/styles";
import { blue ,red, green} from "@material-ui/core/colors";

export const customStyles = makeStyles((theme) =>
  createStyles({
    primaryColor: {
      color: theme.palette.tertiary.main + " !important",
    },
    greyButton: {
      backgroundColor: theme.palette.quaternary.main + " !important",
      color: theme.palette.whiteColor.main + " !important",
      marginTop: "10px",
      marginRight:"10px",
    },
    secondaryButton: {
      backgroundColor: theme.palette.secondary.main + " !important",
      color: theme.palette.whiteColor.main + " !important",
      marginTop: "10px",
      marginRight:"10px",
    },
    redButton: {
      color: theme.palette.whiteColor.main + " !important",
    },
    printSize: {
      width: "100%",
      height: "100%",
      padding: "3%",
    },
    printDisplay: {
      display: "none",
    },
    greyColor: {
      color: theme.palette.quaternary.main + " !important",
      marginTop: "10px",
      marginBottom:"10px"
    },
    lightblueColor: {
      background: blue[50],
      padding: "10%",
    },
    lightblueColorPayment: {
      background: blue[50],
      padding:"5%"
    },
    lightblueColorButton: {
      background: blue[50],
      padding: "10%",
      borderTop: "1px solid",
      borderTopColor: theme.palette.quaternary.main + " !important",
    },
    amountTertiary: {
      color: theme.palette.tertiary.main + " !important",
    },
    maindivPayment: {
      marginLeft: "10px",
      marginRight: "10px",
      height: "auto"
    },
    accountsList: {
      color: theme.palette.primary.main + " !important",
      marginTop: "10px",
    },
    accountsListFirst: {
      marginBottom: "10px !important"
    },
    impInstruction:{
      color: theme.palette.primary.main + " !important",
    },
    linkAccountNotFound:{
      color: theme.palette.secondary.main + " !important",
      marginRight: "20px",
      display: "flex",
      justifyContent: "flex-end",
      cursor: "pointer",
      textTransform: "lowercase"
    },
    textAlignRight:{
      textAlign: "right"
    },
    floatLeft:{
      floatLeft: "left"
    },
    paymentBtn:{
      color: theme.palette.whiteColor.main + " !important",
      marginBottom: "10px",
      marginTop: "10px",
    },
    accentButton: {
      background: theme.palette.tertiary.main + "!important",
      textAlign: "center !important",
      color: theme.palette.whiteColor.main+" !important",
      border: "none",
    },
    redcolor:{
      color:red[400] + "!important"
    },
    greenColor:{
      color:green[400] + '!important'
    }
  })
);
export const useCustomCheckboxStyles = makeStyles((theme) =>
createStyles({
  icon: {
    width: 17,
    height: 17,
    marginLeft: "5px",
    marginRight: "5px",
    "input:disabled ~ &": {
      boxShadow: "none",
      background: theme.palette.quaternary.main + " !important",
    }
  }
})
);