import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../../app/base-style";
import "../../app/base-style";
import { appService } from "../../../services/app.service";
import "../home.component.css";
import profileImage from "../../../assests/images/profile-light.png";
import { Grid, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import { routePaths } from "../../config/route-paths";
import { HomeStyles } from "../home-style";
import { store } from "../../../helpers/store";
import { connect, useSelector } from "react-redux";
import { appActions } from "../../../actions/index";

function ProfileDetailsCard(props) {
  const { profileDetails } = props.profileDetails;
  let phoneNumber = profileDetails.phoneNumber;
  const classes = useStyles();
  const homeclasses = HomeStyles();
  let labels = {};
  let linkLabel = "";
  if (appService.getLabels()) {
    labels = appService.getLabels().getLabels.PROFILE_DETAILS;
    linkLabel = appService.getLabels().getLabels.PROFILE_MANAGMENT
      .PROFILE_HEADER_TITLE;
  }
  let widgets = props.widgets;
  let isProfileDetails = false;
  let dashboard = [];

  let globalStore = null;
  if (globalStore === null) {
    globalStore = store.getState();
  }
  let name = "";
  let address = "";
  const [profileImg, setProfileImg] = useState(null);
  let wordbreakQuaternary=`wordBreakProfile ${classes.paragraphQuaternary}`
  if (globalStore) {
    if (globalStore.accountInformation.demographicDetails) {
      if (
        (globalStore.accountInformation.demographicDetails.personOrBusiness =
          "P")
      ) {
        if (globalStore.accountInformation.demographicDetails.title) {
          let title =
            globalStore.accountInformation.demographicDetails.title ;
          let firstName =
            globalStore.accountInformation.demographicDetails.firstName;
          let lastName =
            globalStore.accountInformation.demographicDetails.lastName;
          let middleName =
            globalStore.accountInformation.demographicDetails.middleName;
          if (middleName) {
            name = (title ? (title + " "):"" )+ (firstName ? (firstName + " "):"") + (middleName ? (middleName + " "):"") + (lastName ? lastName : "");
          } else {
            name = (title ? (title + " "):"" )+ (firstName ? (firstName + " "):"")+ (lastName ? lastName : "");
          }
        } else {
          name = globalStore.accountInformation.demographicDetails.primaryName;
        }
      } else {
        name = globalStore.accountInformation.demographicDetails.primaryName;
      }
    }
    if (globalStore.accountInformation.demographicDetails) {
      address =
        globalStore.accountInformation.demographicDetails.correspondenceddress;
    }
  }
  if (widgets) {
    widgets.forEach((item, key) => {
      if (item.widgetCode === "SIDEBAR.DASHBOARD") {
        dashboard = item.children;
      }
    });
  }
  if (dashboard) {
    dashboard.forEach((dashboardItem, k) => {
      if (
        dashboardItem.widgetCode === "DASHBOARD.PROFILE_DETAILS" &&
        dashboardItem.widgetDefaultValue === "true"
      ) {
        isProfileDetails = true;
      }
    });
  }
  const icon = useSelector(
    (state) => state?.parametersWidgets?.ParameterLookup?.PROFILE_LITE_ICON
  );
  const iconInStore = useSelector(
    (state) => state?.profileIcon?.assetPath
    )  

  useEffect(() => {
    if (icon && !iconInStore) {
      props.getprofileIcon(icon);
    }
  }, [icon]);

  const profile_image = useSelector((state) => state?.profileIcon);
  useEffect(() => {
    setProfileImg(profile_image);
  }, [profile_image]);

  return (
    <React.Fragment>
      {isProfileDetails && (
        <Card className="card">
          <CardContent>
            <Typography color="primary" variant="h6" align="center">
              <b>{labels.DASHBOARD_TITLE1}</b>
            </Typography>

            {profileDetails && (
              <Grid
                container
                direction="row"
                justify="center"
                alignContent="center"
              >
                <Grid item lg={2} md={3} sm={2} xs={3}>
                  <Hidden mdUp xsDown>
                    <Grid item>&nbsp;</Grid>
                  </Hidden>

                  <img
                    src={
                      profileImg && profileImg.assetPath
                        ? profileImg.assetPath
                        : profileImage
                    }
                    alt="profile_image"
                    loading= "lazy"
                    width="100%"
                    height="auto"
                  />
                </Grid>
                <Grid item xs={1}>
                  &nbsp;
                </Grid>

                <Grid item xs={8}>
                  <div className="wordBreakMargin profileMargin">
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      spacing={0}
                    >
                      <Grid item xs={12} md={4} lg={3} align="left">
                        <Typography
                          className={classes.paragraphQuaternary}
                          variant="h6"
                        >
                          <b>{labels.PROFILE_DETAILS_LABEL_NAME}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={9} align="left" >
                        <Typography
                          className={wordbreakQuaternary}
                          variant="h6"
                        >
                          {name ? name : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Hidden mdUp>
                      <br />
                    </Hidden>

                    <Grid
                      container
                      direction="row"
                      justify="center"
                      spacing={0}
                    >
                      <Grid item xs={12} md={4} lg={3} align="left">
                        <Typography
                          className={classes.paragraphQuaternary}
                          variant="h6"
                        >
                          <b>{labels.PROFILE_DETAILS_LABEL_EMAIL}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={9} align="left">
                        <Typography
                          className={wordbreakQuaternary}
                          variant="h6"
                        >
                          {profileDetails.email ? profileDetails.email : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Hidden mdUp>
                      <br />
                    </Hidden>

                    <Grid
                      container
                      direction="row"
                      justify="center"
                      spacing={0}
                    >
                      <Grid item xs={12} md={4} lg={3} align="left">
                        <Typography
                          className={classes.paragraphQuaternary}
                          variant="h6"
                        >
                          <b>{labels.PROFILE_DETAILS_LABEL_NUMBER}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={8} lg={9} align="left">
                        <Typography
                          className={wordbreakQuaternary}
                          variant="h6"
                        >
                          {phoneNumber ? phoneNumber : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Hidden mdUp>
                      <br />
                    </Hidden>
                    {address && (
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        spacing={0}
                      >
                        <Grid item xs={12} md={4} lg={3} align="left">
                          <Typography
                            className={classes.paragraphQuaternary}
                            variant="h6"
                          >
                            <b>{labels.PROFILE_DETAILS_LABEL_ADDRESS}</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={8} lg={9} align="left">
                          <Typography
                          className={wordbreakQuaternary}
                          variant="h6"
                          >
                            {address ? address : "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                </Grid>
              </Grid>
            )}
          </CardContent>
          <div className="wordBreakMargin">
            {linkLabel && (
              <Link to={routePaths.PROFILE} className={homeclasses.lowerFont}>
                <Typography
                  color="secondary"
                  align="center"
                  className="margin-top-50"
                >
                  {linkLabel}
                </Typography>
              </Link>
            )}
          </div>
        </Card>
      )}
    </React.Fragment>
  );
}

const actionCreators = {
  getprofileIcon: appActions.getprofileIcon,
};

const connectedProfileDetailsCard = connect(
  null,
  actionCreators
)(ProfileDetailsCard);
export { connectedProfileDetailsCard as ProfileDetailsCard };
