import axios from "axios";
import { commonService } from "../../services/common.service";
import { config } from "../../enviroment";
import { refreshBearerService } from "../../services/refresh.bearer.service";
import { accountDetails } from "../../actions/home.action";
import { authService } from "../../services/auth.service";
import { routePaths } from "../config/route-paths";
import { history, store } from "../../helpers/index";
import { checkAppVersion } from "./check-build-version";
import { gettingAppVersion } from "./check-build-version";

const TIMEOUT = 5 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
const envConfig = config;
let errCount = 0;
let refreshing_token = null;
export var accountUpdating = null;
const getExpirationDate = (jwtToken) => {
  if (!jwtToken) {
    return null;
  }
  const jwt = JSON.parse(atob(jwtToken.split(".")[1]));
  // multiply by 1000 to convert seconds into milliseconds
  return (jwt && jwt.exp && jwt.exp * 1000) || null;
};

const isExpired = (exp) => {
  if (!exp) {
    return false;
  }
  return Date.now() > exp;
};

const setupAxiosInterceptors = () => {
  const onRequestSuccess = async (config) => {
    if(!gettingAppVersion && !window.location.href.includes(routePaths.PAYMENT_CONFIRMATION)) {
      checkAppVersion();
    }
    let bearer = localStorage.getItem("bearer");
    localStorage.setItem("getRetryPage", "false");
    bearer = JSON.parse(bearer);
    let accessToken = bearer.acessToken;
    let dataExpiration = getExpirationDate(accessToken);
    let timeCheck = isExpired(dataExpiration);
    if (config.url !== envConfig.urls.REFRESH_BEARER) {
      if (timeCheck && !config._retry) {
        config._retry = true;
        refreshing_token = refreshing_token ? refreshing_token : refreshBearerService.refreshBearerToken();
        await refreshing_token;
        refreshing_token = null;
      }
    }
    bearer = localStorage.getItem("bearer");
    bearer = JSON.parse(bearer);
    accessToken = bearer.acessToken;
    if (
      bearer &&
      window.location.pathname !== config.url.BILL_PAY &&
      window.location.pathname !== config.url.QUICK_PAY &&
      window.location.pathname !== config.url.PAYMENT &&
      window.location.pathname !== config.url.PAYMENT_BILLPAY &&
      window.location.pathname !== config.url.VIEW_BALANCE &&
      window.location.pathname !== config.url.NSA
    ) {
      const sessionId = bearer.sessionId;
      config.headers.SessionId = `${sessionId}`;
      config.headers.tenantCode = envConfig.constants.BASE_TENANT_CODE;
      config.headers.personId = bearer.personId;
      config.headers.username = bearer.userName;
      config.headers["x-client-tenantCode"] =
        envConfig.constants.BASE_TENANT_CODE;

      if (config.url !== envConfig.urls.EXPIRE_SESSION) {
        config.headers.accessToken = `${accessToken}`;
      }
    } else {
      if (
        window.location.href !== config.url.BILL_PAY &&
        window.location.href !== config.url.QUICK_PAY &&
        window.location.href !== config.url.PAYMENT &&
        window.location.href !== config.url.PAYMENT_BILLPAY &&
        window.location.href !== config.url.VIEW_BALANCE &&
        window.location.href !== config.url.NSA
      ) {
        commonService.logoutUser();
      }
    }

    let accountUpdateTimeStamp = localStorage.getItem("accountUpdateTimeStamp");
    if(accountUpdateTimeStamp && isExpired(Number(accountUpdateTimeStamp))) {
      accountUpdating = accountUpdating ? accountUpdating : updateAccountDetails();
    } else if(!accountUpdateTimeStamp){
      accountUpdating = accountUpdating ? accountUpdating : updateAccountDetails();
    }
    return config;
    // }
  };

  const onResponseSuccess = (response) => response;
  const onResponseError = async (err) => {
    const status = err.status || (err.response ? err.response.status : 0);
    let serviceWidget = false;
    if (store.getState().parametersWidgets.WidgetLookUp) {
      store.getState().parametersWidgets.WidgetLookUp.forEach((item) => {
        if (item.widgetCode === "SERVICE_UNAVAILABLE") {
          if (item.widgetDefaultValue === "true") {
            serviceWidget = true;
          }
        }
      });
    }
    if (
      err.config.url === envConfig.urls.AUTH_SERVICE ||
      err.config.url === envConfig.urls.PROFILE_DETAILS_DATA ||
      err.config.url === envConfig.urls.LABELS ||
      err.config.url === envConfig.urls.USAGE_SERVICE_BASE_URL ||
      err.config.url === envConfig.urls.ADMIN_SERVICE_BASE_URL
    ) {
        if (status === 500 || status === 0) {
          if (serviceWidget === true) {
          localStorage.setItem("getRetryPage", "true");
        }
      }
    }
  
    let errMessage = "";
    if (err.response) {
      if (err.response.data.message) {
        errMessage = err.response.data.message;
      } else {
        errMessage = Array.isArray(err.response.data.errors)
          ? err.response.data.errors[0]["message"]
          : "";
      }
    }
    if(status === 403 && errMessage.toLowerCase()==='access denied'){
      commonService.logoutUser();
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

const updateAccountDetails = () => {
  return authService
    .accountDetais()
    .then(res => {
      let bearer = JSON.parse(localStorage.getItem("bearer"));
      localStorage.removeItem("bearer");
      bearer.accountDetails = res;
      localStorage.setItem("bearer", JSON.stringify(bearer));
      store.dispatch(accountDetails());
      updateTimeStamp();
      accountUpdating = null;
    })
    .catch(err => {
      accountUpdating = null;
    });
}

const updateTimeStamp = () => {
  let d = new Date();
  let coolDown;
  if(config.constants.ACCOUNT_DETAILS_UPDATE_MINIMUM_INTERVAL == "ACCOUNT_DETAILS_UPDATE_MINIMUM_INTERVAL_TIME" || !Number(config.constants.ACCOUNT_DETAILS_UPDATE_MINIMUM_INTERVAL)) {
    coolDown = Number(config.constants.ACCOUNT_DETAILS_UPDATE_MINIMUM_INTERVAL_FALLBACK);
  } else {
    coolDown = Number(config.constants.ACCOUNT_DETAILS_UPDATE_MINIMUM_INTERVAL);
  }
  d.setMilliseconds(d.getMilliseconds() + coolDown);
  localStorage.setItem("accountUpdateTimeStamp", d.getTime());
}
export default setupAxiosInterceptors;
