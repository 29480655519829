import { combineReducers } from "redux";
import { parametersWidgets, snackbar, appLogo,profileIcon ,profileDarkIcon, isPasswordUpdated} from "./app.reducer";
import { labels } from "../reducers/labels.reducer";
import { profileDetails, accountInformation } from "./home.reducer";
import { meterDetails } from "./app.usgae.reducer";
import { usageMeterDetails, usageUserData } from "./usgae.reducer";
import profileReducer from "./profile.reducer";
import { ticketCategory,getConnectionDetails } from "./ticket.reducer";
const rootReducer = combineReducers({
  parametersWidgets,
  snackbar,
  labels,
  profileDetails,
  accountInformation,
  meterDetails,
  usageMeterDetails,
  usageUserData,
  profileReducer,
  ticketCategory,
  appLogo,
  profileIcon,
  profileDarkIcon,
  getConnectionDetails,
  isPasswordUpdated
});

export default rootReducer;
