import React, { useState, useEffect } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Grid, Hidden } from "@material-ui/core";
import { inactiveAccountBannerStyles } from "./inactive-account-banner-style";
import moment from "moment";
import { store } from "../../../helpers/store";
import { adminService } from "../../../services/admin.service";

function dateFormat(date) {
  return moment(date, "YYYY-MM-DD").format("MMM DD, YYYY");
}

let profileBlockedTenure = 6;
store.subscribe(() => {
  if (store.getState().parametersWidgets.ParameterLookup !== undefined) {
    profileBlockedTenure = store.getState().parametersWidgets.ParameterLookup
      .INVALID_SA_BLOCK_TENURE;
  }
});

export default function InactiveAccountBanner(props) {
  const [content, setContent] = useState(null);
  const customStyle = inactiveAccountBannerStyles();
  let bannerContent = "";
  let bannerHeading = "";

  if (store.getState().parametersWidgets.ParameterLookup !== undefined) {
    profileBlockedTenure = store.getState().parametersWidgets.ParameterLookup
      .INVALID_SA_BLOCK_TENURE;
  }

  useEffect(() => {
    if (store.getState().labels) {
      adminService
        .getContentData("en", "INACTIVE_ACCOUNT_BANNER")
        .then((res) => {
          setContent(res.data.getContents.INACTIVE_ACCOUNT_BANNER);
        });
    }
  }, [store.getState().labels]);

  const [isInactiveAccount, setisInactiveAccount] = useState(false);
  const [dtAccDeactivated, setdtAccDeactivated] = useState(null);

  store.subscribe((e) => {
    const meterDetails = store.getState().meterDetails;
    if (meterDetails) {
      meterDetails.saDetails &&
        meterDetails.saDetails.forEach((w) => {
          if (w.stopDate !== "") {
            if (dtAccDeactivated !== null) {
              if (
                moment(dtAccDeactivated).format("YYYY-MM-DD") <
                moment(w.stopDate).format("YYYY-MM-DD")
              ) {
                setdtAccDeactivated(w.stopDate);
              }
            } else {
              setdtAccDeactivated(w.stopDate);
            }
            setisInactiveAccount(true);
            return false;
          } else {
            setisInactiveAccount(false);
            return false;
          }
        });
    }
  });

  if (content) {
    content.forEach((element) => {
      if (element.contentCode === "ACCOUNT_INACTIVE_TEXT") {
        bannerContent = element.answer;
      }
      if (element.contentCode === "ACCOUNT_INACTIVE_HEADING") {
        bannerHeading = element.answer;
      }
    });
    bannerContent = bannerContent.replace(
      `**TCK_TDT**`,
      dtAccDeactivated !== null ? dateFormat(dtAccDeactivated) : ""
    );
    bannerContent = bannerContent.replace(
      `**TCK_ADT**`,
      dtAccDeactivated !== null
        ? dateFormat(
            moment(dtAccDeactivated, "YYYY-MM-DD").add(
              profileBlockedTenure,
              "months"
            )
          )
        : ""
    );
    bannerContent = bannerContent
      .replace(`**linkClass**`, customStyle.linkText)
      .replace(`**linkClass**`, customStyle.linkText);
    bannerContent = bannerContent.replace(`**TCK_TOS**`, `terms of service`);
    bannerContent = bannerContent.replace(`**TCK_RAT**`, `raise a ticket`);
  }

  return isInactiveAccount ? (
    <div>
      <Hidden xlUp smDown>
        <Grid className={customStyle.container}>
          <Alert icon={false} severity="error">
            <AlertTitle>
              <strong> {bannerHeading !== "" ? bannerHeading : ""}</strong>
            </AlertTitle>
            <span
              dangerouslySetInnerHTML={{
                __html: bannerContent !== "" ? bannerContent : "",
              }}
            ></span>
          </Alert>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid className={customStyle.containerSM}>
          <Alert icon={false} severity="error">
            <AlertTitle>
              <strong> {bannerHeading !== "" ? bannerHeading : ""}</strong>
            </AlertTitle>
            <span
              className={customStyle.insideData}
              dangerouslySetInnerHTML={{
                __html: bannerContent !== "" ? bannerContent : "",
              }}
            ></span>
          </Alert>
        </Grid>
      </Hidden>
      <Hidden smUp mdDown xlDown>
        <Grid className={customStyle.containerSM}>
          <Alert icon={false} severity="error">
            <AlertTitle>
              <strong> {bannerHeading !== "" ? bannerHeading : ""}</strong>
            </AlertTitle>
            <span
              className={customStyle.insideData}
              dangerouslySetInnerHTML={{
                __html: bannerContent !== "" ? bannerContent : "",
              }}
            ></span>
          </Alert>
        </Grid>
      </Hidden>
    </div>
  ) : null;
}
