import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import HomeWorkRoundedIcon from "@material-ui/icons/HomeWorkRounded";
import InfoIcon from "@material-ui/icons/Info";
import { List, Tooltip } from "@material-ui/core";
import { appService } from "../../../services/index";
import { useStyles } from "./sidebar-style";
import { StyledMenuItem } from "./sidebar-style";
import ScrollableTabsButtonForce from "./mobile-tabs";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { store } from "../../../helpers/store";
import { useState } from "react";
import AccountBalanceWallet from "@material-ui/icons/AccountBalanceWallet";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import SpeedIcon from "@material-ui/icons/Speed";

let labels = {};
export default function Sidebar(props) {
  let widgets = store.getState().parametersWidgets.WidgetLookUp;
  let components = [];
  if (appService.getLabels()) {
    labels = appService.getLabels().getLabels.SIDEBAR;
    components = [
      {
        title: labels.SIDEBAR_HOME,
        route: "",
        icon: <HomeWorkRoundedIcon style={{ fontSize: 40 }} />,
        widgetCode: "SIDEBAR.DASHBOARD",
        desc: "",
        selected: true,
      },
      {
        title: labels.SIDEBAR_USAGE,
        route: "",
        icon: <SpeedIcon style={{ fontSize: 40 }} />,
        widgetCode: "SIDEBAR.USAGE",
        desc: "",
        selected: false,
      },
      {
        title: labels.SIDEMENU_BILLING,
        route: "",
        icon: <AccountBalanceWallet style={{ fontSize: 40 }} />,
        widgetCode: "SIDEBAR.BILLING",
        desc: "",
        selected: false,
      },
      {
        title: "Profile",
        route: "",
        icon: <AccountCircleIcon style={{ fontSize: 40 }} />,
        widgetCode: "",
        desc: "",
        selected: false,
      },
      {
        title: labels.SIDEBAR_SELFHELP,
        route: "",
        icon: <InfoIcon style={{ fontSize: 40 }} />,
        widgetCode: "SIDEBAR.SELFHELP",
        desc: "",
        selected: false,
      },
      {
        title: labels.SIDEBAR_SUPPORT,
        route: "",
        icon: <QuestionAnswerIcon style={{ fontSize: 40 }} />,
        widgetCode: "SIDEBAR.SUPPORT",
        desc: "",
        selected: false,
        tooltip: labels.SIDEBAR_SUPPORT_TOOLTIP,
      },
    ];
  }
  let location = useLocation();
  useEffect(() => {}, [location]);
  let [presetComponents, setItems] = useState([]);
  if (widgets && presetComponents.length === 0) {
    widgets.forEach((item, key) => {
      components.forEach((com, k) => {
        if (item.widgetCode === com.widgetCode) {
          if (item.widgetType === "MENU") {
            if (item.widgetDefaultValue === "true") {
              com.route = item.widgetRoute;
              presetComponents.push(com);
            }
          }
        }
      });
    });
  }
  if (presetComponents) {
    presetComponents.forEach((item, key) => {
      if (item.route === props.history.location.pathname) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
  }
  const classes = useStyles();

  const setComponent = (component, k) => {
    presetComponents.forEach((item) => {
      if (item.widgetCode === component.widgetCode) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
    setItems([...presetComponents]);
    props.history.push(component.route);
  };
  return (
    <div>
      {props.view ? (
        <List className={classes.listitemCls}>
          {presetComponents && labels ? (
            <div>
              {presetComponents.map((component, k) => (
                <StyledMenuItem
                  id={component.title}
                  key={k}
                  onClick={() => setComponent(component, k)}
                  disabled={component.selected}
                >
                  {component.tooltip ? (
                    <Tooltip title={component.tooltip} arrow>
                      <span className={classes.textdecnon}>
                        <div className={classes.sideMenuItem}>
                          <div>{component.icon}</div>
                          <span>{component.title}</span>
                        </div>
                      </span>
                    </Tooltip>
                  ) : (
                    <span className={classes.textdecnon}>
                      <div className={classes.sideMenuItem}>
                        <div>{component.icon}</div>
                        <span>{component.title}</span>
                      </div>
                    </span>
                  )}
                </StyledMenuItem>
              ))}
            </div>
          ) : null}
        </List>
      ) : (
        <ScrollableTabsButtonForce
          propsDetails={props}
          className={classes.fixedmenu}
        />
      )}
    </div>
  );
}
