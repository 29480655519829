import { makeStyles } from "@material-ui/core/styles";

export const mobiletabStyles = makeStyles(theme =>({
    root: {
      flexGrow: 1,
      maxWidth: "100%"
    },
    tabIcons: {
      color: theme.palette.primary.main,
      fontSize: "x-small"
    },
    tab: {
      width: "100%"
    },
    dialog: {
      height: "80%",
      marginTop: "55px",
      zIndex: "112348 !important",
    },
    appBar: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      marginTop: "55px",
      textAlign: "center"
    },
    list: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      marginTop: "45px",
      textAlign: "center",
      height: "800px"
    },
    listItem: {
      textAlign: "center",
      backgroundColor: theme.palette.primary.main
    },
    divider: {
      backgroundColor: "white",
      width: "70%",
      alignContent: "center",
      marginLeft: "14%"
    },
    sidebaricons: {
      fontSize: "35", 
      color: theme.palette.primary.main
    },
    footer:{
      bottom:"0px",
      backgroundColor: theme.palette.primary.main,
      marginTop: "130px",
      position:"absolute",
    },
    footerLinks:{
      color:"white !important",
      bottom:"0px",
    },
    mobilebulletCls:{
        color: "white !important",
        margin: "7px 7px 0px",
        fontSize:"10px"
      },
  }));
