import React, { useState } from "react";
import { ProfileDetailsCard } from "./profile-details/profile-details-component";
import { Grid } from "@material-ui/core";
import CurrentBillUsage from "./account-usage-summary/account-usage-currentbill-component";
import { connect } from "react-redux";
import { updateMeterDetails } from "./../../actions/home.action";
import { store } from "../../helpers/store";
import BillComparison from "../billing/bill-comparison";
import "./account-usage-summary/usage-style.css";
import "../app/base-style.css";
import UsageComparison from "../usage/usage-comparison/usage-comparison";
import moment from "moment";

const ProfileDetails = React.lazy(() => import("./profile-details/profile-details-component"));
const AccountDetailsCard = React.lazy(() => import("../home/account-details/account-details-component"));
const AccountSummaryUsage = React.lazy(() => import("./account-usage-summary/account-usage-component"));


function GetParameters(props) {
  let widgets = store.getState().parametersWidgets.WidgetLookUp;
  return (
    <div>
      <ProfileDetailsCard profileDetails={props} widgets={widgets} />
    </div>
  );
}
function AccountSummaryUsageDetails(props) {
  const [isInactiveAccount, setisInactiveAccount] = useState(false);
  const [dtAccDeactivated, setdtAccDeactivated] = useState(null);
  store.subscribe((e) => {
    const meterDetails = store.getState().meterDetails;
    if (meterDetails) {
      meterDetails.saDetails &&
        meterDetails.saDetails.forEach((w) => {
          if (w.stopDate !== "") {
            if (dtAccDeactivated !== null) {
              if (
                moment(dtAccDeactivated).format("YYYY-MM-DD") <
                moment(w.stopDate).format("YYYY-MM-DD")
              ) {
                setdtAccDeactivated(w.stopDate);
              }
            } else {
              setdtAccDeactivated(w.stopDate);
            }
            setisInactiveAccount(true);
            return false;
          } else {
            setisInactiveAccount(false);
            return false;
          }
        });
    }
  });
  let widgets = store.getState().parametersWidgets.WidgetLookUp;
  let account_summary_card = false;
  if (widgets) {
    widgets.forEach((item) => {
      if (item.widgetCode === "SIDEBAR.DASHBOARD") {
        item.children.forEach((dashChild) => {
          if (dashChild.widgetCode === "DASHBOARD.ACCOUNT_SUMMARY_USAGE") {
            if (dashChild.widgetDefaultValue === "true") {
              account_summary_card = true;
            } else {
              account_summary_card = false;
            }
          }
        });
      }
    });
  }
  return (
    <div>{account_summary_card ?      
        <AccountSummaryUsage props={props} widgets={widgets} isInactiveAccount={isInactiveAccount}/>
      : null}
    </div>
  );
}

function CurrentBillUsageDetails() {
  let widgets = store.getState().parametersWidgets.WidgetLookUp;
  let account_summary_card = false;
  let meterDetails = "";
  if (store.getState().meterDetails.currentBalance) {
    meterDetails = store.getState().meterDetails;
  }
  if (widgets) {
    widgets.forEach((item) => {
      if (item.widgetCode === "SIDEBAR.DASHBOARD") {
        item.children.forEach((dashChild) => {
          if (dashChild.widgetCode === "DASHBOARD.ACCOUNT_SUMMARY_USAGE") {
            if (dashChild.widgetDefaultValue === "true") {
              account_summary_card = true;
            }
          }
        });
      }
    });
  }
  return (
    <div>
      {account_summary_card && meterDetails ? (
        <CurrentBillUsage meterDetails={meterDetails} />
      ) : null}
    </div>
  );
}

function BillComparisonWidget() {
  let widgets = store.getState().parametersWidgets.WidgetLookUp;
  let accountId = null;
  let bill_comparison_card = false;
  if (store.getState().meterDetails.accountId) {
    accountId = store.getState().meterDetails.accountId;
  }
  if (widgets) {
    widgets.forEach((item) => {
      if (item.widgetCode === "SIDEBAR.DASHBOARD") {
        item.children.forEach((dashChild) => {
          if (dashChild.widgetCode === "BILLING_COMPARISON") {
            if (dashChild.widgetDefaultValue === "true") {
              bill_comparison_card = true;
            }
          }
        });
      }
    });
  }

  return (
    <div>
      {accountId && bill_comparison_card ? (
        <BillComparison accountId={accountId} />
      ) : null}
    </div>
  );
}

function UsageComparisonDetails() {
  let widgets = store.getState().parametersWidgets.WidgetLookUp;
  let usage_comparison_card = true;
  let connectionId = null;
  if (store.getState().meterDetails.saDetails) {
    connectionId = store.getState().meterDetails.saDetails[0].connectionId;
  }
  if (widgets) {
    widgets.forEach((item) => {
      if (item.widgetCode === "SIDEBAR.DASHBOARD") {
        item.children.forEach((dashChild) => {
          if (dashChild.widgetCode === "DASHBOARD.USAGE_COMPARISON") {
            if (dashChild.widgetDefaultValue === "true") {
              usage_comparison_card = true;
            }
          }
        });
      }
    });
  }
  return (
    <div>
      {usage_comparison_card && connectionId ? (
        <UsageComparison widgets={widgets} connectionId={connectionId} />
      ) : null}
    </div>
  );
}

class Home extends React.Component {
  state = {};

  render() {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item sm={6} className="width100">
            <GetParameters profileDetails={this.props.profileDetails} />
          </Grid>

          <Grid item sm={6} className="width100">
            {this.props.accountInformation ? (
              <AccountDetailsCard store={store} accountDetails={this.props} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item sm={12}>
            <AccountSummaryUsageDetails accountDetails={this.props} />
          </Grid>
          <Grid item sm={12} md={6} className="width100">
            <BillComparisonWidget />
          </Grid>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return state;
};
const mapDispachToProps = {
  updateMeterDetails,
};
const connectedApp = connect(mapStateToProps, mapDispachToProps)(Home);
export { connectedApp as Home };
