//@flow

import { config } from "../enviroment";
import axios from "axios";
import moment from "moment";

type AssestResponse = {
  assetCode: string,
  assetPath: string,
  fallBackLabelKey: string,
};

export const commonService = {
  encodeString: encodeString,
  decodeString: decodeString,
  logoutUser: logoutUser,
  getAssestUrl: getAssestWithCode,
  convertHexToRGBA: convertHexToRGBA,
  dateFormat: dateFormat,
  get_days: get_days,
  downloadPdf: downloadPdf,
  getDueDays: getDueDays,
};

function encodeString(inputString: string) {
  return inputString;
}

function decodeString(encodedString: string) {
  return encodedString;
}

async function getAssestWithCode(assestCode: string): Promise<AssestResponse> {
  return new Promise((resolve, reject) => {
    axios
      .get(config.urls.ASSEST_CODE_API + assestCode)
      .then(function(response) {
        let returnData: AssestResponse = {
          assetCode: response.data.assetCode,
          assetPath: config.urls.ASSEST_URL_ENDPOINT + response.data.assetPath,
          fallBackLabelKey: response.data.fallBackLabelKey,
        };
        resolve(returnData);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function logoutUser() {
  const bearer = JSON.parse(localStorage.getItem("bearer"));
  if (bearer) {
    await axios
      .post(
        config.urls.EXPIRE_SESSION,
        {
          sessionId: bearer.sessionId,
          email: bearer.email,
        }
      )
      .then(function(response) {
        resetSession();
      })
      .catch(function(error) {
        resetSession();
      });
  } else {
    resetSession();
  }
}

function resetSession() {
  localStorage.clear();
  if (
    window.location.href !== config.urls.SIGNUP ||
    window.location.href !== config.urls.LOGIN_VIA_OTP ||
    window.location.href !== config.urls.QUICK_PAY ||
    !window.location.href.startsWith(config.urls.PAYMENT)
  ) {
     window.location.href = config.urls.LOGIN_APP;
  }
}

function convertHexToRGBA(hex: string, opacity: number) {
  const tempHex = hex.replace("#", "");
  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);
  return `rgba(${r},${g},${b},${opacity / 100})`;
}

function dateFormat(date: string, dateFormat: string) {
  return moment(date).format(dateFormat);
}

function get_days(selectedDate: string) {
  let date = new Date(selectedDate);
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  return new Date(year, month, 0).getDate();
}

function downloadPdf(data, pdffileName) {
  const base64 = data;
  const linkSource = `data:application/pdf;base64,${base64}`;
  const downloadLink = document.createElement("a");
  const fileName = pdffileName + ".pdf";
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

function getDueDays(dueDate: string) {
  let start = moment(dueDate, "YYYY-MM-DD");
  let end = moment(new Date()).format("YYYY-MM-DD");
  return moment.duration(start.diff(end)).asDays();
}
