import React, { Suspense } from "react";
import { Switch, Route, Redirect, Router } from "react-router-dom";
import { PrivateRoute } from "../config/private-route";
import { routePaths } from "../config/route-paths";
import { Home } from "../home/home.component";
import { PaymentConfirmation } from "../payment/payment-confirmation/payment-confirmation-component";
import Usage from "../usage/usage-components";

const Logout = React.lazy(() => import("../config/logout.component"));
// const Usage = React.lazy(() => import("../usage/usage-components")); chnging in sidebar
const SelfHelp = React.lazy(() => import("../self-help/self-help"));
const Tariff = React.lazy(() => import("../tariff/tariff-component"));
const PaymentReceipt = React.lazy(() =>
  import("../payment/payment-receipt-component")
);
const Billing = React.lazy(() =>
  import("../billing-history/billing-history-component")
);
const GetSupportTicket = React.lazy(() => import("../ticket/get-support"));
const BillDetails = React.lazy(() => import("../bill-details/bill-details"));
const ProfileManagment = React.lazy(() =>
  import("../profile-managment/profile")
);
const InitiatePayment = React.lazy(() =>
  import("../payment/multiple-payment/initiate-payment-component")
);
const RetryMain = React.lazy(() => import("../common/retry/retry.jsx"));

// import Contacts from "../contacts/contacts-component";
// import Cards from "../common/cards/cards-components";
// import { ResComp } from "../responsiveness/responsive-component";

const Routes = () => {
  return (
    <Suspense fallback={<div></div>}>
      <Switch>
        <Route path={routePaths.LOGOUT} component={Logout} />
        <PrivateRoute exact path={routePaths.HOME} component={Home} />
        <PrivateRoute exact path={routePaths.USAGE} component={Usage} />
        <PrivateRoute exact path={routePaths.SELF_HELP} component={SelfHelp} />
        <PrivateRoute exact path={routePaths.TARIFF} component={Tariff} />
        <PrivateRoute
          exact
          path={routePaths.PAYMENT_RECEIPT}
          component={PaymentReceipt}
        />
        <PrivateRoute exact path={routePaths.BILLING} component={Billing} />
        <PrivateRoute
          exact
          path={routePaths.GET_SUPPORT}
          component={GetSupportTicket}
        />
        <PrivateRoute path={routePaths.BILL_DETAILS} component={BillDetails} />
        <PrivateRoute
          exact
          path={routePaths.PROFILE}
          component={ProfileManagment}
        />
        <PrivateRoute
          exact
          path={routePaths.INITIATE_PAYMENT}
          component={InitiatePayment}
        />
        <PrivateRoute
          exact
          path={routePaths.PAYMENT_CONFIRMATION}
          component={PaymentConfirmation}
        />
        <PrivateRoute exact path={routePaths.RETRY} component={RetryMain} />
        {/* <PrivateRoute exact path={routePaths.CONTACTS} component={Contacts} /> */}
        {/* <PrivateRoute exact path={routePaths.RES} component={ResComp} />
      <PrivateRoute exact path={routePaths.CARDS} component={Cards} /> */}
        <Redirect from="*" to={routePaths.HOME} />
      </Switch>
     </Suspense>
  );
};

export default Routes;
