import { appConstants } from "../constants/app.constants";
import { profileConstants } from "../constants/profile.constants";
import { appService, commonService } from "../services/index";
import { batch } from "react-redux";

export const appActions = {
  getParametersWidgets,
  getSnackbar,
  getAppLogo,
  getprofileIcon,
  getprofileDarkIcon,
  isPasswordUpdated
};
export{
  getAppReady_widget
}
function getAppReady_widget(){
  return dispatch => {
    dispatch(appActions.getParametersWidgets())
  }
}
function getParametersWidgets() {
  return (dispatch) => {
    appService.getParametersWidgets().then((res) => {
      if (res) {
        dispatch(success(res));
      } else {
        dispatch(failure());
      }
    });
  };

  function success(params) {
    return { type: appConstants.PARAMETER_WIDGET_SUCCESS, params };
  }
  function failure() {
    return { type: appConstants.PARAMETER_WIDGET_FAILURE };
  }
}

function isPasswordUpdated(updatePasswordStatus) {
  return (dispatch) => {
    batch(() => {
      dispatch({type: appConstants.PASSWORD_UPDATE_SUCCESS, updatePasswordStatus: updatePasswordStatus})
      dispatch({type: profileConstants.UPDATE_SELECTED_USER_PROFILE, updatedValAction: 1});
    })
  }
}

function getSnackbar(snack) {
  return (dispatch) => {
    dispatch(success(snack));
  };

  function success(snack) {
    return { type: appConstants.SNACKBAR_SUCCESS, snack };
  }
}

function getAppLogo(tenant) {
  return (dispatch) => {
    commonService.getAssestUrl(tenant).then((res) => {
      if (res) {
        dispatch(success(res));
      } else {
        dispatch(failure());
      }
    });
  };

  function success(logoResponse) {
    return { type: appConstants.APPLOGO_SUCCESS, logoResponse };
  }
  function failure() {
    return { type: appConstants.APPLOGO_FAILURE };
  }
}

function getprofileIcon(icon) {
  return (dispatch) => {
    commonService.getAssestUrl(icon).then((res) => {
      if (res) {
        dispatch(success(res));
      } else {
        dispatch(failure());
      }
    });
  };

  function success(iconResponse) {
    return { type: appConstants.PROFILE_ICON_SUCCESS, iconResponse };
  }
  function failure() {
    return { type: appConstants.PROFILE_ICON_FAILURE };
  }
}

function getprofileDarkIcon(darkIcon) {
  return (dispatch) => {
    commonService.getAssestUrl(darkIcon).then((res) => {
      if (res) {
        dispatch(success(res));
      } else {
        dispatch(failure());
      }
    });
  };

  function success(darkIconResponse) {
    return { type: appConstants.PROFILE_DARK_ICON_SUCCESS, darkIconResponse };
  }
  function failure() {
    return { type: appConstants.PROFILE_DARK_ICON_FAILURE };
  }
}