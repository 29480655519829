import React, { useState, useEffect } from "react";
import "../app/app.css";
import Card from "@material-ui/core/Card";
import { appService } from "../../services/app.service";
import "../app/base-style.css";
import { Grid } from "@material-ui/core";
import customStyles from "./layout-style";
import Typography from "@material-ui/core/Typography";
import { selfHelpService } from "../self-help/self-help.service";
import { useStyles } from "../app/base-style";
import { refreshBearerService } from "../../services/refresh.bearer.service";

export default function Footer(props) {
  const classes = customStyles();
  const usestyle = useStyles();
  const [policyLink, setPolicyLink] = useState(null);
  const [LinkdinLink, setLinkdinLink] = useState(null);
  const [FacebookLink, setFacebookLink] = useState(null);
  let bearer = localStorage.getItem("bearer");
  let main = `${usestyle.backgroundWhite} ${classes.footer}`;
  let labels = {};
  if (appService.getLabels()) {
    labels = appService.getLabels().getLabels.FOOTER;
  }
  let widgets = props.store.getState().parametersWidgets.WidgetLookUp;
  let isFooter = false;
  if (widgets) {
    widgets.forEach((item, key) => {
      if (item.widgetCode === "FOOTER" && item.widgetDefaultValue === "true") {
        isFooter = true;
      }
    });
  }
  const getExpirationDate = (jwtToken?: string): number | null => {
    if (!jwtToken) {
      return null;
    }
    const jwt = JSON.parse(atob(jwtToken.split(".")[1]));
    // multiply by 1000 to convert seconds into milliseconds
    return (jwt && jwt.exp && jwt.exp * 1000) || null;
  };

  const isExpired = (exp?: number) => {
    if (!exp) {
      return false;
    }
    return Date.now() > exp;
  };
  useEffect(() => {
    if (bearer) {
      bearer = JSON.parse(bearer);
      let accessToken = bearer.acessToken;
      let dataExpiration = getExpirationDate(accessToken);
      let timeCheck = isExpired(dataExpiration);
      if (timeCheck) {
        setTimeout(() => {
          footerApi();
        }, 1000);
      } else {
        footerApi();
      }
    }
  }, [bearer]);

  function footerApi() {
    selfHelpService.selfHelp("en", "FOOTER").then(
      (response) => {
        response.FOOTER.forEach((item) => {
          item.question === "Terms" && setPolicyLink(item.answer);
          item.contentCode === "FACEBOOK" && setFacebookLink(item.answer);
          item.contentCode === "LINKEDIN" && setLinkdinLink(item.answer);
        });
      },
      (error) => {
        return (
          <div>
            <p>Failed to Load the Resource</p>
          </div>
        );
      }
    );
  }

  return (
    <div className="footer-component">
      {isFooter ? (
        <Card className={main}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={5} lg={3}>
              <Typography className="privacy-terms">
                <a
                  className={classes.links}
                  rel="noopener noreferrer"
                  href={policyLink}
                  target="_blank"
                >
                  {labels.FOOTER_TERMS_AND_POLICIES}
                </a>
                <span className={classes.bulletCls}>&#8226;</span>
                <a
                  className={classes.links}
                  href={FacebookLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {labels.FOOTER_FACEBOOK}
                </a>
                <span className={classes.bulletCls}>&#8226;</span>
                <a
                  className={classes.links}
                  href={LinkdinLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {labels.FOOTER_LINKEDIN}
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={6}>
              <div align="center">
                <Typography className={usestyle.linkColor}>
                  &nbsp;
                  {labels.FOOTER_COPY_RIGHT_TITLE}&nbsp;
                  {labels.FOOTER_COPY_RIGHT_COMPANY_NAME}&nbsp;
                  {labels.FOOTER_COPY_RIGHT_COMPANY_RIGHTS}
                  {labels.FOOTER_COPY_RIGHT_PRIVATE}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <span className={classes.links}>
                <Typography onClick={scrollToTop} align="center">
                  {labels.FOOTER_SCROLL_BAR_TITLE}
                </Typography>
              </span>
            </Grid>
          </Grid>
        </Card>
      ) : null}
    </div>
  );
}

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
    pointer: "cursor",
  });
}
