import { makeStyles ,withStyles} from "@material-ui/core/styles";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";

export const billComparisonStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
    }
  }));

  export const STYLED_SELECT = withStyles((theme) => ({
    root: {
      color: theme.palette.secondary.main + " !important",
      fontSize: "1rem",
      cursor:"pointer"
    },
    icon: {
      color: theme.palette.secondary.main + " !important",
    }  
  }))(Select);
  
  export const STYLED_MENU_ITEM = withStyles((theme) => ({
    root: {
      color: theme.palette.secondary.main + " !important",
      fontSize: "15px",
      "&:hover": {
        backgroundColor: theme.palette.quaternary.main + " !important",
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          color: theme.palette.primary.main
        }
      },
      "&:focus": {
        backgroundColor: theme.palette.quaternary.main + " !important",
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          color: theme.palette.primary.main
        }
      }
    }, 
  }))(MenuItem);