import { makeStyles, createStyles } from "@material-ui/core/styles";

const drawerWidth = 105;
const customStyles = makeStyles((theme) =>
  createStyles({
    primaryColor: theme.palette.primary.main,
    root: {
      display: "flex",
      flexDirection: "column"
    },
    appBar: {
      background: "#ffffff",
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    sideNavigation: {
      padding: "20px 0px 20px 0px", //top - right - bottom - left      
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: drawerWidth,
      borderRadius: "10px",
      position: "fixed"
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      height: "60px",
    },
    rightMenu: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    notificationCls: {
      marginLeft: "auto",
      float: "left",
    },
    footer: {
      padding:"2% !important",
      borderRadius: "12px !important",
      margin: "2% 0 1% 0 !important"
    },
    links: {
      color: theme.palette.primary.main,
    },
    bulletCls: {
      color: theme.palette.primary.main,
      margin: "7px 5px 0px",
    },
    appLogo: {
      maxWidth: "150px !important",
      height: "100%",
      width: "250% !important"
    }
  })
);

export default customStyles;
