import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import amber from '@material-ui/core/colors/amber';
const primaryAmber = amber[700];

export const useStyles = makeStyles(theme =>
  createStyles({
    primaryColor: theme.palette.primary.main,
    secondaryColor: theme.palette.secondary.main,   
    themeDirection: theme.direction,
    cardElevation: 2,
    h1Primary: {
      fontSize: "40px !important",
      color: theme.palette.primary.main + " !important"
    },
    h2Primary: {
      fontSize: "20px !important",
      color: theme.palette.primary.main + " !important"
    },
    h3Primary: {
      fontSize: "16px !important",
      color: theme.palette.primary.main + " !important"
    },
    h1Secondary: {
      fontSize: "40px !important",
      color: theme.palette.secondary.main + " !important"
    },
    h2Secondary: {
      fontSize: "20px !important",
      color: theme.palette.secondary.main + " !important"
    },
    h3Secondary: {
      fontSize: "20px !important",
      color: theme.palette.secondary.main + " !important"
    },
    h2Tertiary: {
      fontSize: "20px !important",
      color: theme.palette.tertiary.main + " !important"
    },
    paragraphPrimary: {
      fontSize: "15px !important",
      color: theme.palette.primary.main + " !important"
    },
    paragraphSecondary: {
      fontSize: "15px !important",
      color: theme.palette.secondary.main + " !important"
    },
    paragraphQuaternary: {
      fontSize: "0.938rem !important",
      color: theme.palette.quaternary.main + " !important"
    },
    labelText: {
      fontSize: "20px !important",
      color: theme.palette.primary.main + " !important"
    },
    viewAllBills: {
      color: theme.palette.secondary.main + " !important",
      textAlign: "center !important",
      padding: "0% 25% !important",
      marginLeft: "15% !important"
    },
    primaryButton: {
      background: theme.palette.primary.main + " !important",
      height: "39px",
      width: "155px",
      textAlign: "center !important",
      color: "#FFFFFF !important",
      borderRadius: "20px !important",
      border: "none"
    },
    secondaryButton: {
      background: theme.palette.secondary.main + " !important",
      height: "39px",
      width: "155px",
      textAlign: "center !important",
      color: "#FFFFFF !important",
      borderRadius: "20px !important",
      border: "none"
    },
    primaryButtonLeft: {
      background: theme.palette.primary.main + " !important",
      height: "39px",
      width: "155px",
      textAlign: "center !important",
      color: "#FFFFFF !important",
      borderRadius: "20px 0px 0px 20px !important",
      border: "none"
    },
    secondaryButtonLeft: {
      background: theme.palette.secondary.main + " !important",
      height: "39px",
      width: "155px",
      textAlign: "center !important",
      color: "#FFFFFF !important",
      borderRadius: "20px 0px 0px 20px !important",
      border: "none"
    },
    primaryButtonRight: {
      background: theme.palette.primary.main + " !important",
      height: "39px",
      width: "155px",
      textAlign: "center !important",
      color: "#FFFFFF !important",
      borderRadius: "0px 20px 20px 0px !important",
      border: "none"
    },
    secondaryButtonRight: {
      background: theme.palette.secondary.main + " !important",
      height: "39px",
      width: "155px",
      textAlign: "center !important",
      color: "#FFFFFF !important",
      borderRadius: "0px 20px 20px 0px !important",
      border: "none"
    },
    teritiaryButton: {
      height: "39px",
      width: "155px",
      textAlign: "center !important",
      color: "#FFFFFF !important",
      borderRadius: "20px 20px 20px 20px !important",
      border: "none"
    },
    forthButton: {
      background: primaryAmber + " !important",
      textAlign: "center !important",
      color: theme.palette.whiteColor.main + " !important",
      borderRadius: "10px !important",
      border: "none",
    },
    payNowButtonNew: {
      textAlign: "center !important",
      color: "#FFFFFF !important",
      backgroundColor: theme.palette.secondary.main + " !important",
      borderRadius: "10px !important",
      border: "none"
    },
    viewBillButton: {
      color: "#FFFFFF !important",
      backgroundColor: theme.palette.quaternary.main + "!important",
    },
    whiteButtonLeft: {
      background: "#FFFFFF !important",
      height: "39px",
      width: "155px",
      textAlign: "center !important",
      color: theme.palette.primary.main + " !important",
      borderRadius: "20px 0px 0px 20px !important",
      borderColor: theme.palette.primary.main + " !important"
    },
    whiteButtonRight: {
      background: "#FFFFFF !important",
      height: "39px",
      width: "155px",
      textAlign: "center !important",
      color: "#FFFFFF !important",
      borderRadius: "0px 20px 20px 0px !important",
      borderColor: theme.palette.primary.main + " !important"
    },
    centerTitle: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      wordBreak: "break-all"
    },
    searchBox: {
      background: "#D7DBDF",
      padding: "5px",
      borderRadius: "5px",
      height: "35px"
    },
    primaryCard: {
      minHeight: "210px !important",
      borderRadius: "12px !important",
      padding: "20px !important",
      backgroundColor: theme.palette.secondary.main + " !important"
    },
    backgroundWhite: {
      backgroundColor: theme.palette.whiteColor.main + " !important"
    },
    whiteColor: {
      color: theme.palette.whiteColor.main + " !important"
    },
    linkColor: {
      color: theme.palette.tertiary.main
    },
    quaternaryColor: {
      color: theme.palette.quaternary.main,
    },
    rootList:{
      maxHeight:"500px !important",
      overflow:"auto !important"
      
    }
  })
);
export const ColorTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.quinary.main,
    color: theme.palette.primary.main,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);
