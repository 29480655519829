import axios from "axios";
import { config } from "../enviroment";
import {history} from "../helpers/history";
import {routePaths} from "../components/config/route-paths";
export const authService = {
  accountDetais
};

async function accountDetais() {
  let tenantCode = config.constants.BASE_TENANT_CODE;
  let bearer = localStorage.getItem("bearer");       
  let personId = null;  
  let accesstoken = null;
  let userName = null;

   if (bearer) {   
     bearer = JSON.parse(bearer); 
     personId =bearer.personId; 
     accesstoken = bearer.acessToken;
     userName = bearer.userName;
  }

  let APIquery =
    `{getAccountDetails(input:{tenantCode:"` +
    tenantCode +
    `",personId:"` +
    personId +
    `"})}`;
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.AUTH_SERVICE,
      method: "post",
      data: {
        query: APIquery,
      },
      headers:{
        username:userName
      }
    })
      .then(function(response) {
        resolve(response.data.data.getAccountDetails);
      })
      .catch(function(error) {
        // if(!error.response){
        //   history.push(routePaths.RETRY)
        // }
        // else if(error.response && error.response.status === 500 || error.response.status=== 404 ){
        //   history.push(routePaths.RETRY)
        // }
        reject(error);
      });
  });
}
