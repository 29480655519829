import React from "react";
import { useStyles } from "../../app/base-style";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  Button,
  Grid,
  Divider,
  Tooltip,
  Typography,
} from "@material-ui/core";
import "./usage-style.css";
import { appService } from "../../../services/app.service";
import { HomeStyles } from "../home-style";
import { commonService } from "../../../services/common.service";
import { routePaths } from "../../config/route-paths";
import { history } from "../../../helpers/index";

function CurrentBillUsage(props) {
  const accountDetails = props.meterDetails;
  const classes = useStyles();
  const homeclasses = HomeStyles();
  let labels = {};
  let noBill = null;
  if (appService.getLabels()) {
    labels = appService.getLabels().getLabels.BILLING_HISTORY;
    noBill = appService.getLabels().getLabels.BILLING_COMPARISON
      .BILLING_COMPARISON_NO_BILL;
  }
  let cts = null;
  if (accountDetails.lastPaymentDate) {
    cts = accountDetails.lastPaymentDate;
  }
  let dueDays = null;
  let overDueDays = null;
  let finalDate = null;
  let lastPaid = null;
  if (cts) {
    finalDate = commonService.dateFormat(cts, "MMM DD, YYYY");
  }
  if (accountDetails.lastPaymentAmount) {
    lastPaid = accountDetails.lastPaymentAmount;
  }
  if (accountDetails) {
    let dueDate = accountDetails.latestDueDate;
    dueDays = commonService.getDueDays(dueDate);
  }

  if (dueDays) {
    if (dueDays < 0) {
      overDueDays = dueDays * -1;
    }
  }

  function billOnClick() {
    history.push(routePaths.BILL_DETAILS);
  }

  function payNowClick() {
    history.push(routePaths.INITIATE_PAYMENT);
  }

  return (
    <Card className="card">
      {props.length !== 0 && labels ? (
        <CardContent>
          <Grid container spacing={1} direction="column" align="center">
            <Grid item xs={12}>
              <Typography
                color="primary"
                gutterBottom
                variant="h6"
                component="h2"
                align="center"
              >
                <b>{labels.CURENT_BILL_USAGE_TITLE}</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={homeclasses.quaternaryColor}
                align="center"
              >
                {labels.CURENT_BILL_USAGE_OUTSTANDING_AMOUNT}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="secondary" variant="h4" align="center">
                <b>
                  {accountDetails.currency} {Number(parseFloat(accountDetails.currentBalance).toFixed(2)).toLocaleString('en', {
                                      minimumFractionDigits: 2
                                  })}
                </b>
              </Typography>
            </Grid>
            <Grid item>
              {dueDays && (
                <Typography
                  className={homeclasses.quaternaryColor}
                  align="center"
                >
                  {Math.round(accountDetails.currentBalance) ===
                  0 ? null : Math.round(dueDays) > 0 ? (
                    <React.Fragment>
                      {Math.round(accountDetails.currentBalance) === 1 ? (
                        <b>
                          {" "}
                          {labels.CURENT_BILL_USAGE_DUE_IN} {dueDays}&nbsp;
                          {labels.CURRENT_BILL_DAY}
                        </b>
                      ) : (
                        <b>
                          {" "}
                          {labels.CURENT_BILL_USAGE_DUE_IN} {dueDays}&nbsp;
                          {labels.CURRENT_BILL_DAYS}
                        </b>
                      )}
                    </React.Fragment>
                  ) : (
                    <div>
                      {Math.round(dueDays) === 0 ? (
                        <b>{labels.CURRENT_BILL_NO_DUE_FOR_CURRENT_BILL}</b>
                      ) : (
                        <React.Fragment>
                          {overDueDays === 1 ? (
                            <b>
                              {labels.CURRENT_BILL_OVERDUE_BY} {overDueDays}
                              &nbsp;
                              {labels.CURRENT_BILL_DAY}
                            </b>
                          ) : (
                            <b>
                              {" "}
                              {labels.CURRENT_BILL_OVERDUE_BY} {overDueDays}
                              &nbsp;
                              {labels.CURRENT_BILL_DAYS}
                            </b>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  )}
                </Typography>
              )}
            </Grid>
          </Grid>
          <br />
          <Grid
            container
            spacing={2}
            alignItems="center"
            justify="center"
            direction="row"
          >
            {Math.round(accountDetails.currentBalance) === 0 ? (
              <Grid item>
                <Tooltip title={labels.CURENT_BILL_USAGE_TOOLTIP_VIEW_BILL}>
                  <Button
                    variant="contained"
                    className={classes.viewBillButton}
                    fullWidth
                    onClick={billOnClick}
                  >
                    {labels.CURENT_BILL_USAGE_VIEW_BILL}
                  </Button>
                </Tooltip>
              </Grid>
            ) : (
              <React.Fragment>
                <Grid item sm={5} xs={6}>
                  <Tooltip title={labels.CURENT_BILL_USAGE_TOOLTIP_VIEW_BILL}>
                    <Button
                      variant="contained"
                      className={classes.viewBillButton}
                      fullWidth
                      onClick={billOnClick}
                    >
                      {labels.CURENT_BILL_USAGE_VIEW_BILL}
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item sm={5} xs={6}>
                  <Tooltip title={labels.CURENT_BILL_USAGE_TOOLTIP_PAYNOW}>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      className={classes.whiteColor}
                      onClick={payNowClick}
                    >
                      <span>{labels.CURENT_BILL_NEW_USAGE_PAYNOW}</span>
                    </Button>
                  </Tooltip>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
          <br />
          {lastPaid && finalDate ? (
            <React.Fragment>
              <Divider className="dividerHorizontal" />
              <br />

              <Grid
                container
                spacing={2}
                alignItems="center"
                justify="center"
                direction="column"
              >
                <Grid item xs={12}>
                  <Typography
                    className={homeclasses.quaternaryColor}
                    align="center"
                  >
                    {labels.CURRENT_BILL_LAST_PAID}
                  </Typography>
                </Grid>
                <Grid>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="flex-end"
                  >
                    <Grid item>
                      <Typography
                        className={classes.linkColor}
                        variant="h5"
                        component="h3"
                      >
                        {accountDetails.currency} {Number(parseFloat(lastPaid).toFixed(2)).toLocaleString('en', {
                                      minimumFractionDigits: 2
                                  })}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={homeclasses.quaternaryColor}>
                        &nbsp;{labels.CURRENT_BILL_ON}&nbsp;{finalDate}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Link to={routePaths.BILLING}>
                    <Typography color="secondary">
                      {labels.CURENT_BILL_VIEWALLBILLS}
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </React.Fragment>
          ) : (
            <Link to={routePaths.BILLING}>
              <Typography color="secondary" align="center" position="fixed">
                {labels.CURENT_BILL_VIEWALLBILLS}
              </Typography>
            </Link>
          )}
        </CardContent>
      ) : (
        <CardContent>
          <Typography
            color="primary"
            gutterBottom
            variant="h6"
            component="h2"
            align="center"
          >
            <b>{labels.CURENT_BILL_USAGE_TITLE}</b>
          </Typography>
          <Typography className="cardFallback" align="center">
            <b className={classes.h2Secondary}>{noBill}</b>
          </Typography>
        </CardContent>
      )}
    </Card>
  );
}

export default CurrentBillUsage;
