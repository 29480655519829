import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Line } from "react-chartjs-2";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Backlink from "../self-help/backlink";
import { Hidden, TableCell, Typography } from "@material-ui/core";
import { useStyles } from "../app/base-style";
import "../app/base-style.css";
import "./electricity-usage-graph-style.css";
import {
  customStyles,
  customSelectStyles,
  STYLED_SELECT,
  STYLED_MENU_ITEM,
  STYLED_BORDER_LESS_SELECT,
} from "./electricty-usage-graph-style";
import { useMediaQuery } from "react-responsive";
import ElectricityUsageTable from "./electricity-usage-table";
import { store } from "./../../helpers/store";
import { usageService } from "./../../services/usage.service";
import TableRow from "@material-ui/core/TableRow";
import { config } from "./../../enviroment";
import { appService } from "./../../services/app.service";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";
import { commonService } from "../../services/common.service";
import moment from "moment";
import { updateMeterDetails } from "../../actions/home.action";
import { CircularProgress } from "@material-ui/core";

function ElectricityUsage(props) {
  const classes = useStyles();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 577 });
  const isTabletView = useMediaQuery({ maxWidth: 768 });
  const isTabletOrMobileIpadMax = useMediaQuery({ query: "(max-width: 1366px)" });
  const customStyleClass = customStyles();
  const customSelectStylesClasses = customSelectStyles();
  store.getState().snackbar = true;
  let lingGraph = "";
  if(isTabletView && isTabletOrMobileIpadMax && isTabletOrMobile === false){
    lingGraph = 160;
  }else if(isTabletOrMobile){
    lingGraph = 225;
  }
  else{
    lingGraph = 125;
  }
  return (
    <ElectricityUsageMain
      BaseStyle={classes}
      customStylesProps={customStyleClass}
      customSelectStylesProps={customSelectStylesClasses}
      media={{ isTabletOrMobile: isTabletOrMobile, isTabletView: isTabletView }}
      usageProps={props.usageProps}
      meterUpdate={props}
      lingGraph = {lingGraph}
    />
  );
}

let labels = {};
let nodataLabel = {};
let dashBoradGraph = {};
let updateExternalMeterDetail;
let saveUsageUserData;
let usageTrendUserYear, usageTrendUserInterval;
let appColor = {};
let labelConnection = "";

const ddlMenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  getContentAnchorEl: null,
};
class ElectricityUsageMain extends React.Component {
  stateInformation = store.getState();
  constructor(props) {
    super(props);
    if (
      this.stateInformation &&
      this.stateInformation.parametersWidgets.ParameterLookup
    ) {
      appColor = {
        primaryColor: this.stateInformation.parametersWidgets.ParameterLookup
          .PRIMARY_COLOR,
        secondaryColor: this.stateInformation.parametersWidgets.ParameterLookup
          .SECONDARY_COLOR,
        tertiaryColor: this.stateInformation.parametersWidgets.ParameterLookup
          .TERTIARY_COLOR,
        quaternaryColor: this.stateInformation.parametersWidgets.ParameterLookup
          .QUATERNARY_COLOR,
        quinaryColor: this.stateInformation.parametersWidgets.ParameterLookup
          .QUINARY_COLOR,
      };
    }
    updateExternalMeterDetail = props.usageProps.updateExternalMeterDetail;
    saveUsageUserData = props.usageProps.updateUsageUserData;
    let selectedOptionLabel;
    let selectedIntervalLabel;    
    usageTrendUserYear = this.stateInformation.usageUserData.usageTrendUserYear;
    usageTrendUserInterval = this.stateInformation.usageUserData
      .usageTrendUserInterval;

    this.state = {
      lingGraph: props.lingGraph,
      previousAccountID: null,
      selectedID: "2222",
      loading:true,
      accountId: "",
      isLoading: false,
      isTabletOrMobile: props.media,
      tableDataArray: [],
      usageUnit: "",
      meterDetails: {},
      displayMode: usageTrendUserInterval ? usageTrendUserInterval : "D2BM",
      dropdownYear: 0,
      selectedIntervalLabelOption: {
        value: usageTrendUserInterval ? usageTrendUserInterval : "D2BM",
        label: selectedIntervalLabel,
      },
      selectedOption: {
        value: usageTrendUserInterval ? usageTrendUserInterval : "D2BM",
        label: selectedOptionLabel,
      },
      selectedYear: {
        value: usageTrendUserYear
          ? usageTrendUserYear
          : new Date().getFullYear(),
        label: usageTrendUserYear
          ? usageTrendUserYear
          : new Date().getFullYear(),
      },
      possibleYears: [],
      databar: {
        labels: [],
        datasets: [
          {
            label: "",
            fill: true,
            lineTension: 0.6,
            backgroundColor: commonService.convertHexToRGBA(
              appColor.tertiaryColor,
              20
            ),
            borderColor: appColor.tertiaryColor,
            borderWidth: 3,
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        title: {
          display: false,
          text: "Usage Trends",
          fontSize: 20,
        },
        legend: {
          display: true,
          position: "top",
          align: "center",
          textDirection: "rtl",
          onClick: (e) => null,
          labels: {
            boxWidth: 10,
            fontColor: appColor.primaryColor,
            fontSize: 15,
            fontFamily: "NeoSansRegular"
          },
          ltr: true,
        },
        tooltips: {
          position: 'average',
          mode: 'label',
          intersect: false,
          backgroundColor: "white",
          titleFontColor: appColor.secondaryColor,
          bodyFontColor: appColor.secondaryColor,
          xPadding: 10,
          yPadding: 10,
          displayColors: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: appColor.primaryColor,
              },

              gridLines: {
                display: false,
                color: "rgba(0, 0, 0, 0.1)",
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)",
              },
              ticks: {
                beginAtZero: true,
                fontColor: appColor.primaryColor,
              },
            },
          ],
        },
      },
    };
  }
  componentDidMount() {
    labels = {};
    nodataLabel = {};
    dashBoradGraph = {};
    if (appService.getLabels()) {
      labels = appService.getLabels().getLabels.USAGE_TREND;
      nodataLabel = appService.getLabels().getLabels.CONFIGURATIONS;
      dashBoradGraph = appService.getLabels().getLabels.ACCOUNT_SUMMARY;
      labelConnection = labels.USAGE_ELECTRICITY_USAGE_METER_ID_TITLE.split(
        " "
      );
    }
    store.subscribe((stateChanged) => {
      const stateInformation = store.getState();
      if (
        stateInformation &&
        stateInformation.parametersWidgets.ParameterLookup
      ) {
        appColor = {
          primaryColor:
            stateInformation.parametersWidgets.ParameterLookup.PRIMARY_COLOR,
          secondaryColor:
            stateInformation.parametersWidgets.ParameterLookup.SECONDARY_COLOR,
          tertiaryColor:
            stateInformation.parametersWidgets.ParameterLookup.TERTIARY_COLOR,
          quaternaryColor:
            stateInformation.parametersWidgets.ParameterLookup.QUATERNARY_COLOR,
          quinaryColor:
            stateInformation.parametersWidgets.ParameterLookup.QUINARY_COLOR,
        };
      }
      if (stateInformation.meterDetails.saDetails[0].connectionId) {
        this.setState({
          connectionId: stateInformation.meterDetails.saDetails[0].connectionId,
          isLoading: true,
        });
      }

      this.setState({ isLoading: false });
    });

    if (this.stateInformation.meterDetails.saDetails[0].connectionId) {
      this.getDetailsAtFirstCall();
    } else {
      store.subscribe(() => {
        if (
          this.state.meterDetails.saDetails[0].connectionId !==
          store.getState().meterDetails.saDetails[0].connectionId
        ) {
          this.stateInformation = store.getState();
          this.getDetailsAtFirstCall();
        }
      });
    }
  }

  getDetailsAtFirstCall() {
    const account = this.stateInformation.accountInformation.accountDetails.filter(
      (m) => {
        return (
          m.saDetails[0].connectionId ===
          this.stateInformation.meterDetails.saDetails[0].connectionId
        );
      }
    )[0];

    const tempState = this.state;
    this.props.meterUpdate.updateMeterDetails(account);
    tempState.meterDetails = account;
    this.setState(tempState);

    this.updateYearLabels(account);
    this.getUsageTrendInformation(
      {
        connectionId: this.stateInformation.meterDetails.saDetails[0]
          .connectionId,
        tenantCode: config.constants.BASE_TENANT_CODE,
        displayMode: usageTrendUserInterval ? usageTrendUserInterval : "D2BM",
        year: usageTrendUserYear ? usageTrendUserYear : this.state.dropdownYear,
      },
      account.accountId
    );
  }

  updateYearLabels(storageInformation) {
    const year = [];
    const conStartDate = storageInformation.saDetails[0].startDate;
    let startYear;
    if (moment(conStartDate, "YYYY-MM-DD").isValid()) {
      startYear = moment(conStartDate, "YYYY-MM-DD").format("YYYY");
    }

    const endYear = new Date().getFullYear();

    for (let i = Number(startYear); i <= Number(endYear); i++) {
      year.push({
        value: i + "",
        label: i + "",
      });
    }

    const tempState = this.state;

    tempState.dropdownYear = endYear + "";
    tempState.possibleYears = year;
    this.setState(tempState);
  }
  yearChange = async (event) => {
    const selectedYear = event.target;
    saveUsageUserData({ usageTrendUserYear: selectedYear.value });

    const tempState = this.state;
    tempState.selectedYear = {
      value: selectedYear.value,
      label: selectedYear.value,
    };
    this.setState(tempState);
    this.getUsageTrendInformation({
      connectionId: this.stateInformation.meterDetails.saDetails[0]
        .connectionId,
      tenantCode: config.constants.BASE_TENANT_CODE,
      displayMode: this.state.displayMode,
      year: selectedYear.value,
    });
  };
  getUsageTrendInformation(apiInput, accntID) {
    const tempState = this.state;
    tempState.databar.datasets[0].data = [];
    tempState.databar.labels = [];
    tempState.isLoading = true;
    tempState.loading = true;
    tempState.tableDataArray = [];
    this.setState(tempState);
    const displayLables = [],
      quantityArray = [];
    let meterData = [];
    if (!accntID) {
      if (store.getState().meterDetails) {
        accntID = store.getState().meterDetails.accountId;
      }
    }
    let usageGraphWidget = "usageGraphWidget";
    usageService
      .electricityUsageTrendGraph( accntID,usageGraphWidget,apiInput.year)
      .then((usageTrendInformation) => {
        const usageUnit =
          usageTrendInformation.data.data.getUsageTrends[0].usageUnit;
        const usageQuantity = usageTrendInformation.data.data.getUsageTrends;
        let arrayusage = new Array(12).fill(null)
        usageQuantity.forEach((usageObject, index)=>{
          var datechart = new Date(usageObject.endDate)
          let monthsinChart = moment(datechart).month()+1          
          arrayusage[monthsinChart-1] = usageObject.usageQuantity
          quantityArray.push(usageObject.usageQuantity);         
        })
        usageQuantity.forEach((usageObject) => {
          displayLables.push(usageObject.intervalName);          
          meterData.push(
            <TableRow key="Test">
              <TableCell align="center">
                {(moment(usageObject.startdate).format("MMM DD, YYYY")).replace("-","")}
                &nbsp;to&nbsp;{(moment(usageObject.endDate).format("MMM DD, YYYY")).replace("-","")} 
              </TableCell>
              <TableCell align="center">{usageObject.usageQuantity}</TableCell>
            </TableRow>
          );
        });
        const tempState = this.state;
        tempState.loading=false;
        tempState.databar.datasets[0].data = arrayusage;
        tempState.databar.labels = moment.monthsShort();
        tempState.isLoading = false;
        tempState.usageUnit = usageUnit;
        tempState.databar.datasets[0].label =
        dashBoradGraph.MONTHLY_USAGE_SUB_TEXT+ " " + usageTrendInformation.data.data.getUsageTrends[0].usageUnit;
        tempState.tableDataArray = meterData;
        store.getState().snackbar = false;
        this.setState(tempState);
      })
      .catch((error) => {
        store.getState().snackbar = false;
        const tempState = this.state;
        tempState.loading=false;
        tempState.databar.datasets[0].data = [];
        tempState.databar.labels = [];
        tempState.isLoading = false;

        this.setState(tempState);
      });
  }

  handleExternalMeterChange = (event) => {
    const account = this.stateInformation.accountInformation.accountDetails.filter(
      (m) => {
        return m.saDetails[0].connectionId === event.target.value;
      }
    )[0];
    this.setState({
      selectedID: account.accountId,
    });
    this.props.meterUpdate.updateMeterDetails(account);
    const tempState = this.state;
    tempState.dropdownYear = this.state.dropdownYear;
    tempState.meterDetails = account;
    tempState.selectedYear = {
      value: this.state.dropdownYear,
      label: this.state.dropdownYear,
    };

    this.setState(tempState);
    updateExternalMeterDetail(
      this.stateInformation.accountInformation.accountDetails.filter((m) => {
        return m.saDetails[0].connectionId === event.target.value;
      })[0]
    );

    this.getUsageTrendInformation(
      {
        connetionId: event.target.value,
        tenantCode: config.constants.BASE_TENANT_CODE,
        displayMode: this.state.displayMode,
        year: this.state.dropdownYear,
      },
      account.accountId
    );

    this.updateYearLabels(account);
  };

  render() {
    const { selectedYear } = this.state;
    const isTabletOrMobile = this.props.media.isTabletOrMobile;
    return (
      <div>
        <Card className="card">
          <CardContent>
          <Hidden smUp >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
            <Grid item xs={3}>
            <Backlink />
          </Grid>
          <Grid item xs={9}>
            <Typography
              color="primary"
              gutterBottom
              variant="h6"
              component="h2"
              align="center"
            >
              <b>{labels.USAGE_ELECTRICITY_USAGE_TITLE}</b>
            </Typography>
          </Grid>
          </Grid>
          <div
                className={this.props.customStylesProps.headingLeftContainer}
              >
                <div
                  className={
                    isTabletOrMobile
                      ? this.props.customStylesProps.textAlignCenter
                      : this.props.customStylesProps.textAlignRight
                  }
                >
                  {this.stateInformation.accountInformation.accountDetails
                    .length === 1 && this.state.meterDetails
                    ? labelConnection[1]
                    : labels.USAGE_ELECTRICITY_USAGE_METER_ID_TITLE}
                </div>
                <div
                  className={
                    isTabletOrMobile
                      ? this.props.customStylesProps.textAlignCenter
                      : this.props.customStylesProps.textAlignRight
                  }
                >
                  {this.stateInformation.accountInformation.accountDetails
                    .length === 1 && this.state.meterDetails ? (
                    <Typography color="secondary">
                      {this.state.meterDetails.saDetails
                        ? this.state.meterDetails.saDetails[0].connectionId
                        : null}
                    </Typography>
                  ) : (
                    <STYLED_BORDER_LESS_SELECT
                      name="externalMeters"
                      value={
                        this.state.meterDetails.saDetails
                          ? this.state.meterDetails.saDetails[0].connectionId
                          : null
                      }
                      renderValue={(value) => `${value}`}
                      onChange={this.handleExternalMeterChange}
                      displayEmpty={true}
                      IconComponent={ExpandMoreSharpIcon}
                      disableUnderline={true}
                      MenuProps={ddlMenuProps}
                    >
                      {this.stateInformation.accountInformation
                        .accountDetails === undefined
                        ? null
                        : this.stateInformation.accountInformation.accountDetails.map(
                            (singleMeter) => (
                              <STYLED_MENU_ITEM
                                key={singleMeter.saDetails[0].connectionId}
                                value={singleMeter.saDetails[0].connectionId}
                                disabled={singleMeter.isDisabled}
                              >
                                <Grid container direction="column">
                                  <Grid
                                    item
                                    className={
                                      this.props.customStylesProps.listItem1
                                    }
                                  >
                                    <b>
                                      {singleMeter.saDetails[0].connectionId}
                                    </b>
                                  </Grid>
                                  <Grid
                                    item
                                    className={
                                      this.props.customStylesProps.listItem2
                                    }
                                  >
                                    {singleMeter.saDetails[0]
                                      .connectionDescription +
                                      ", " +
                                      singleMeter.accountName +
                                      " (" +
                                      singleMeter.accountRelationshipType +
                                      ")"}
                                  </Grid>
                                </Grid>
                              </STYLED_MENU_ITEM>
                            )
                          )}
                    </STYLED_BORDER_LESS_SELECT>
                  )}
                </div>
              </div>
      </Hidden>
        <Hidden xlUp smDown >
            <div className={this.props.customStylesProps.headingContainer}>
              <div className={this.props.customStylesProps.textAlignLeft}>
                <Backlink />{" "}
                {/* {this.state.isLoading ? (
                  <CircularProgress disableShrink={true} />
                ) : (
                  ""
                )} */}
              </div>
              <div
                className={this.props.customStylesProps.headingLeftContainer}
              >
                <div
                  className={
                    isTabletOrMobile
                      ? this.props.customStylesProps.textAlignCenter
                      : this.props.customStylesProps.textAlignRight
                  }
                >
                  {this.stateInformation.accountInformation.accountDetails
                    .length === 1 && this.state.meterDetails
                    ? labelConnection[1]
                    : labels.USAGE_ELECTRICITY_USAGE_METER_ID_TITLE}
                </div>
                <div
                  className={
                    isTabletOrMobile
                      ? this.props.customStylesProps.textAlignCenter
                      : this.props.customStylesProps.textAlignRight
                  }
                >
                  {this.stateInformation.accountInformation.accountDetails
                    .length === 1 && this.state.meterDetails ? (
                    <Typography color="secondary">
                      {this.state.meterDetails.saDetails
                        ? this.state.meterDetails.saDetails[0].connectionId
                        : null}
                    </Typography>
                  ) : (
                    <STYLED_BORDER_LESS_SELECT
                      name="externalMeters"
                      value={
                        this.state.meterDetails.saDetails
                          ? this.state.meterDetails.saDetails[0].connectionId
                          : null
                      }
                      renderValue={(value) => `${value}`}
                      onChange={this.handleExternalMeterChange}
                      displayEmpty={true}
                      IconComponent={ExpandMoreSharpIcon}
                      disableUnderline={true}
                      MenuProps={ddlMenuProps}
                    >
                      {this.stateInformation.accountInformation
                        .accountDetails === undefined
                        ? null
                        : this.stateInformation.accountInformation.accountDetails.map(
                            (singleMeter) => (
                              <STYLED_MENU_ITEM
                                key={singleMeter.saDetails[0].connectionId}
                                value={singleMeter.saDetails[0].connectionId}
                                disabled={singleMeter.isDisabled}
                              >
                                <Grid container direction="column">
                                  <Grid
                                    item
                                    className={
                                      this.props.customStylesProps.listItem1
                                    }
                                  >
                                    <b>
                                      {singleMeter.saDetails[0].connectionId}
                                    </b>
                                  </Grid>
                                  <Grid
                                    item
                                    className={
                                      this.props.customStylesProps.listItem2
                                    }
                                  >
                                    {singleMeter.saDetails[0]
                                      .connectionDescription +
                                      ", " +
                                      singleMeter.accountName +
                                      " (" +
                                      singleMeter.accountRelationshipType +
                                      ")"}
                                  </Grid>
                                </Grid>
                              </STYLED_MENU_ITEM>
                            )
                          )}
                    </STYLED_BORDER_LESS_SELECT>
                  )}
                </div>
              </div>
            </div>
            <div className={this.props.customStylesProps.headingContainer}>
              <Typography
                color="primary"
                gutterBottom
                variant="h6"
                component="h2"
                align="center"
              >
                <b>{labels.USAGE_ELECTRICITY_USAGE_TITLE}</b>
              </Typography>
            </div>
            </Hidden>
            <Hidden mdUp xsDown>
            <div className={this.props.customStylesProps.headingContainer}>
              <div className={this.props.customStylesProps.textAlignLeft}>
                <Backlink />{" "}
                {/* {this.state.isLoading ? (
                  <CircularProgress disableShrink={true} />
                ) : (
                  ""
                )} */}
              </div>
              <div
                className={this.props.customStylesProps.headingLeftContainer}
              >
                <div
                  className={
                    isTabletOrMobile
                      ? this.props.customStylesProps.textAlignCenter
                      : this.props.customStylesProps.textAlignRight
                  }
                >
                  {this.stateInformation.accountInformation.accountDetails
                    .length === 1 && this.state.meterDetails
                    ? labelConnection[1]
                    : labels.USAGE_ELECTRICITY_USAGE_METER_ID_TITLE}
                </div>
                <div
                  className={
                    isTabletOrMobile
                      ? this.props.customStylesProps.textAlignCenter
                      : this.props.customStylesProps.textAlignRight
                  }
                >
                  {this.stateInformation.accountInformation.accountDetails
                    .length === 1 && this.state.meterDetails ? (
                    <Typography color="secondary">
                      {this.state.meterDetails.saDetails
                        ? this.state.meterDetails.saDetails[0].connectionId
                        : null}
                    </Typography>
                  ) : (
                    <STYLED_BORDER_LESS_SELECT
                      name="externalMeters"
                      value={
                        this.state.meterDetails.saDetails
                          ? this.state.meterDetails.saDetails[0].connectionId
                          : null
                      }
                      renderValue={(value) => `${value}`}
                      onChange={this.handleExternalMeterChange}
                      displayEmpty={true}
                      IconComponent={ExpandMoreSharpIcon}
                      disableUnderline={true}
                      MenuProps={ddlMenuProps}
                    >
                      {this.stateInformation.accountInformation
                        .accountDetails === undefined
                        ? null
                        : this.stateInformation.accountInformation.accountDetails.map(
                            (singleMeter) => (
                              <STYLED_MENU_ITEM
                                key={singleMeter.saDetails[0].connectionId}
                                value={singleMeter.saDetails[0].connectionId}
                                disabled={singleMeter.isDisabled}
                              >
                                <Grid container direction="column">
                                  <Grid
                                    item
                                    className={
                                      this.props.customStylesProps.listItem1
                                    }
                                  >
                                    <b>
                                      {singleMeter.saDetails[0].connectionId}
                                    </b>
                                  </Grid>
                                  <Grid
                                    item
                                    className={
                                      this.props.customStylesProps.listItem2
                                    }
                                  >
                                    {singleMeter.saDetails[0]
                                      .connectionDescription +
                                      ", " +
                                      singleMeter.accountName +
                                      " (" +
                                      singleMeter.accountRelationshipType +
                                      ")"}
                                  </Grid>
                                </Grid>
                              </STYLED_MENU_ITEM>
                            )
                          )}
                    </STYLED_BORDER_LESS_SELECT>
                  )}
                </div>
              </div>
            </div>
            <div className={this.props.customStylesProps.headingContainer}>
              <Typography
                color="primary"
                gutterBottom
                variant="h6"
                component="h2"
                align="center"
              >
                <b>{labels.USAGE_ELECTRICITY_USAGE_TITLE}</b>
              </Typography>
            </div>
            </Hidden>
            <Grid
              container
              justify="center"
              alignItems="center"
              className="margin-top-10"
            >
              <div
                className={
                  isTabletOrMobile
                    ? this.props.customStylesProps.flexColumn
                    : this.props.customStylesProps.flexRow
                }
              >
                <div className={this.props.customStylesProps.textAlignCenter}>
                  {this.state.possibleYears.length === 1 ? (
                    <Typography color="secondary" align="center">
                      <b className={this.props.BaseStyle.paragraphPrimary}>
                        {labels.USAGE_ELECTRICITY_USAGE_DROPDOWN1_LABEL} :
                      </b>{" "}
                      {this.state.possibleYears[0].label}
                    </Typography>
                  ) : (
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      direction="row"
                    >
                      <Grid>
                        <Typography
                          color="secondary"
                          align="center"
                          fontSize="large"
                        >
                          <b className={this.props.BaseStyle.paragraphPrimary}>
                            {labels.USAGE_ELECTRICITY_USAGE_DROPDOWN1_LABEL}
                          </b>
                        </Typography>
                      </Grid>
                      <Grid>
                        {" "}
                        &nbsp;
                        <STYLED_SELECT
                          name="ddlYear"
                          value={selectedYear.value}
                          onChange={this.yearChange}
                          displayEmpty={true}
                          IconComponent={ExpandMoreSharpIcon}
                          disableUnderline={true}
                          MenuProps={ddlMenuProps}
                        >
                          {this.state.possibleYears === undefined
                            ? null
                            : this.state.possibleYears.map((year) => (
                                <STYLED_MENU_ITEM
                                  key={year.value}
                                  value={year.value}
                                  className="text-field"
                                >
                                  {year.value}
                                </STYLED_MENU_ITEM>
                              ))}
                        </STYLED_SELECT>
                      </Grid>
                    </Grid>
                  )}
                </div>
              </div>
            </Grid>
            <div className="margin-top-10">
              {this.state.loading ? 
                  <Typography
                  align="center"
                  color="primary"
                  variant="h6"
                  className="noDataFound"
                >
                  <CircularProgress disableShrink={true} /> 
                </Typography>
              : 
              this.state.databar.datasets[0].data && this.state.databar.datasets[0].data.length !== 0 ?  (
                <div>
                  <div>
                  <Line
                    height={this.state.lingGraph}
                    data={this.state.databar}
                    options={this.state.chartOptions}
                    responsive={true}
                    maintainAspectRatio={true}
                  />
                  </div>
                  <ElectricityUsageTable
                    headTitle="Bill Cycle"
                    usageUnit={this.state.usageUnit}
                    tableInformation={this.state.tableDataArray}
                    tableStyles={this.props.customStylesProps}
                  />
                </div>
              ) : (
                <Typography
                  align="center"
                  color="primary"
                  variant="h6"
                  className="noDataFound"
                >
                  <b>{nodataLabel.NO_DATA_FOUND}</b>
                </Typography>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const actionCreators = {
  updateMeterDetails: updateMeterDetails,
};

const connectedAccountDropdown = connect(
  null,
  actionCreators
)(ElectricityUsage);
export { connectedAccountDropdown as ElectricityUsage };
