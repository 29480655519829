import React, { useEffect, useState } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { routePaths } from "../../config/route-paths";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedVal } from "../../../actions/profile.action";
const useStyles = makeStyles((theme) => ({
  updatePasswordLink: {
    textDecoration: "underline",
    color: "blue",
    cursor: "pointer",
  },
}));

export const MigrateUserBanner = (props) => {
  const [showBanner, setShowBanner] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  let updatePasswordStatus = useSelector(
    (store) => store?.isPasswordUpdated?.updatePasswordStatus
  );
  useEffect(() => {
    let bearer = JSON.parse(localStorage.getItem("bearer"));
    if (
      bearer?.migrateUser &&
      bearer?.migrateUser === true
    ) {
      setShowBanner(true);
    }
  }, []);
  
  useEffect(() => {
    if(updatePasswordStatus) {
      setShowBanner(false);
    }
  }, [showBanner, updatePasswordStatus])

  const handlePasswordUpdateLinkClick = () => {
    history.push(routePaths.PROFILE);
    dispatch(updateSelectedVal(1));
  };

  return (
    <>
      {showBanner && (
        <Alert severity="info">
          <AlertTitle>Please Update Your Password</AlertTitle>
          <span>
            Dear User, You are currently using system generated password. Please
            click &nbsp;
            <span
              onClick={handlePasswordUpdateLinkClick}
              className={classes.updatePasswordLink}
            >
              here
            </span>
            &nbsp; to update your password.
          </span>
        </Alert>
      )}
    </>
  );
};
