import React, { useRef, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Hidden,
  Card,
} from "@material-ui/core";
import Backlink from "../../self-help/backlink";
import { useStyles } from "../../app/base-style";
import { routePaths } from "../../config/route-paths";
import ReactToPrint from "react-to-print";
import { customStyles } from "../multiple-payment/initiate-payment-style";
import { appService } from "../../../services/app.service";
import { commonService } from "../../../services/common.service";
import headerLogo from "../../../assests/images/qc-default-logo.png";
import { store } from "../../../helpers/store";
import { Link } from "react-router-dom";
import { history } from "../../../helpers/history";
import "../../app/base-style.css";
import "../payment-receipt.css";
import { ticketAction } from "../../../actions/ticket.action";
import { connect, useSelector } from "react-redux";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

function PaymentConfirmationComponent(props) {
  let multipleAccountDetails = "";
  const accountInformation = store.getState().meterDetails;
  if (accountInformation) {
    multipleAccountDetails = accountInformation;
  }
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [appLogo, setAppLogo] = useState(null);
  const componentRef = useRef();
  const customStyle = customStyles();
  let headStyle = `tableBorder ${classes.h3Primary}`;
  let postPaymentProcessing = [];
  let paymentError = [];
  useEffect(() => {
    if (paymentError.status === "RETRY_NOT_ALLOWED") {
      commonService.logoutUser();
    }
  }, []);
  const supportTicketCall = () => {
    props.getTicketCategory("Payments", "PYAC");
    history.push(routePaths.GET_SUPPORT);
  };
  const retryPayment = () => {
    let paymentConnection = localStorage.getItem("PaymentConnections");
    if (paymentConnection) {
      paymentConnection = JSON.parse(paymentConnection);
      props.getConnection(paymentConnection);
    }
    localStorage.removeItem("PaymentConnections");
    history.push(routePaths.INITIATE_PAYMENT);
  };
  if (props.response) {
    postPaymentProcessing = props.response.response;
    paymentError = props.response.response.genericErrorInformation;
  }
  let labels = {};
  let subHeader = {};
  let contentData = {};
  let lastContent = {};
  let impContent = {};
  let receiptLabel = "";
  let connectionLabel = "";
  let labelsHide,
  labelsDetails = "";

  if (props.response.contentRes) {
    contentData = props.response.contentRes;
    for (let i = 0; i < contentData.length; i++) {
      if (contentData[i].contentCode === "INITIATE_PAYMENT_SUBTITLE") {
        subHeader = contentData[i].answer.replace(
          "***",
          customStyle.primaryColor
        );
      }
      if (contentData[i].contentCode === "PAYMENT_CONFIRMATION_LAST") {
        lastContent = contentData[i].answer.replace(
          "***",
          customStyle.greyColor
        );
      }
      if (contentData[i].contentCode === "IMPORTANT_INSTRUCTIONS") {
        impContent = contentData[i].answer.replace(
          "***",
          customStyle.impInstruction
        );
      }
    }
  }
  if (appService.getLabels()) {
    labels = appService.getLabels().getLabels.PAYMENT_CONFIRMATION;
    connectionLabel = appService.getLabels().getLabels.BILL_DETAILS
      .CONNECTION_DETAILS;
    receiptLabel = appService.getLabels().getLabels.PAYMENT_RECEIPT
      .PAYMENT_RECEIPT_PAY_ID;
    labelsHide = store.getState().labels.data.getLabels.USER_MANAGEMENT
      .QUICK_PAY_HIDE;
    labelsDetails = store.getState().labels.data.getLabels.USER_MANAGEMENT
      .QUICK_PAY_DETAILS;

  }

  const app_Logo = useSelector((state) => state?.appLogo);
  useEffect(() => {
    setAppLogo(app_Logo);
  }, [app_Logo]);

  const handleClicked = () => {
    if (open === true) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={1}>
          <Backlink backToHome={true} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Hidden xlUp xsDown>
            <Typography
              color="primary"
              gutterBottom
              variant="h6"
              component="h2"
              align="center"
            >
              <b>{labels.PAYMENT_CONFIRMATION_HEADER}</b>
            </Typography>
          </Hidden>
          <Hidden smUp>
            <Grid
              container
              direction="row"
              justify="flex-start"
              className="margin-top-10"
              spacing={1}
            >
              <Grid item>
                <Typography
                  color="primary"
                  gutterBottom
                  variant="h6"
                  component="h2"
                  align="center"
                >
                  <b>{labels.PAYMENT_CONFIRMATION_HEADER}</b>
                  &nbsp;&nbsp;
                  <Link onClick={handleClicked}>
                    {open ? (
                      <span className={`dividerCenterCss ${classes.linkColor}`}>
                        {labelsHide} <ExpandLess />
                      </span>
                    ) : (
                      <span className={`dividerCenterCss ${classes.linkColor}`}>
                        {labelsDetails} <ExpandMore />
                      </span>
                    )}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
        <Grid item md={1}>
          <div>&nbsp;&nbsp;</div>
        </Grid>
      </Grid>

      <div className={customStyle.printDisplay}>
        <div ref={componentRef} className={customStyle.printSize}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
          >
            <Grid item>
              <img
                height="4%"
                width="130%"
                src={appLogo ? appLogo.assetPath : headerLogo}
                alt={
                  appLogo
                    ? labels[appLogo.fallBackLabelKey]
                    : labels.ASSET_LOGO_FALLBACK_TEXT
                }
                loading="lazy"
              />
            </Grid>
            <Grid item>
              <Typography
                color="primary"
                gutterBottom
                variant="h6"
                component="h2"
                align="center"
              >
                <b>{labels.PAYMENT_CONFIRMATION_PRINT_TITLE}</b>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <div>&nbsp;&nbsp;</div>
            </Grid>
          </Grid>
          <br />
          <div className="text">
            <span className={customStyle.greyColor}>
              {labels.PAYMENT_CONFIRMATION_SUBTITLE}&nbsp;
              <span className={customStyle.linkColor}>
                <b> {labels.PAYMENT_CONFIRMATION_FAQ}</b>
              </span>
            </span>
          </div>
          <hr />
          {postPaymentProcessing.mainData.length > 0
            ? postPaymentProcessing.mainData.map((element, k) => {
                return (
                  <React.Fragment key={k}>
                    <Grid container direction="row" justify="center">
                      <Grid item xs={12} md={6}>
                        <br />
                        <Typography className={classes.h2Primary}>
                          {postPaymentProcessing.mainData.length > 1 ? (
                            <b>
                              {labels.PAYMENT_CONFIRMATION_INFO_TITLE +
                                `(${k + 1}/${
                                  postPaymentProcessing.mainData.length
                                })`}
                            </b>
                          ) : (
                            <b>{labels.PAYMENT_CONFIRMATION_INFO_TITLE}</b>
                          )}
                        </Typography>
                        <br />
                        {postPaymentProcessing ? (
                          <Table size="small">
                            <TableBody>
                              <TableRow>
                                <TableCell className={headStyle}>
                                  <b>{labels.PAYMENT_CONFIRMATION_INFO_TYPE}</b>
                                </TableCell>
                                <TableCell className={headStyle} align="left">
                                  {element.paymentInfo.input.paymentType}
                                </TableCell>
                              </TableRow>
                              {element.paymentInfo.input.creditCardInfo
                                .cardType &&
                              element.paymentInfo.input.creditCardInfo
                                .cardNumber ? (
                                <TableRow>
                                  <TableCell className={headStyle}>
                                    <b>
                                      {
                                        labels.PAYMENT_CONFIRMATION_INFO_CARD_DETAILS
                                      }
                                    </b>
                                  </TableCell>
                                  <TableCell className={headStyle} align="left">
                                    {element.paymentInfo.input.creditCardInfo
                                      .cardType +
                                      ":" +
                                      element.paymentInfo.input.creditCardInfo
                                        .cardNumber}
                                  </TableCell>
                                </TableRow>
                              ) : null}
                              {/* <TableRow>
                                <TableCell className={headStyle}>
                                  <b>Expiry Date</b>
                                </TableCell>
                                <TableCell className={headStyle} align="left">
                                  {element.paymentInfo.input.creditCardInfo
                                    .expMonth +
                                    "/" +
                                    element.paymentInfo.input.creditCardInfo
                                      .expYear}
                                </TableCell>
                              </TableRow> */}
                             {element.billingAddress &&
                              <TableRow>
                                <TableCell className={headStyle}>
                                  <b>{labels?.PAYMENT_BILLING_ADDRESS}</b>
                                </TableCell>
                                <TableCell className={headStyle} align="left">
                                  {element.billingAddress}
                                </TableCell>
                              </TableRow>
                              }
                            </TableBody>
                          </Table>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Card className={customStyle.lightblueColorPayment}>
                          <Typography className={classes.h3Primary}>
                            <b>{connectionLabel}</b>
                          </Typography>
                          <Typography className={classes.h3Primary}>
                            {element.head.connectionId}
                            <br />
                          </Typography>
                          <br />
                          {/* <Typography className={classes.h3Primary}>
                            <b>Account Id</b>
                          </Typography>
                          <Typography className={classes.h3Primary}>
                            {element.head.accountId}
                            <br />
                          </Typography>
                          <br /> */}
                          {paymentError.isInError === "true" ? null : (
                            <React.Fragment>
                              <Typography className={classes.h3Primary}>
                                <b>{receiptLabel}</b>
                              </Typography>
                              <Typography className={classes.h3Primary}>
                                {element.paymentInfo.inputOutput &&
                                  
                                    element.paymentInfo.inputOutput.paymentEventId }
                                <br />
                              </Typography>
                              <br />
                            </React.Fragment>
                          )}
                          <Typography className={classes.h3Primary}>
                            <b>{labels.PAYMENT_CONFIRMATION_PAYMENT_STATUS}</b>
                          </Typography>
                          <Typography className={classes.h3Primary}>
                            {paymentError.isInError === "true" ? (
                              <span className="redColor">
                                {labels.PAYMENT_CONFIRMATION_ERROR +
                                  ": " +
                                  paymentError.error}
                              </span>
                            ) : (
                              <span className="greenColor">
                                {labels.PAYMENT_CONFIRMATION_SUCCESS}
                              </span>
                            )}
                            <br />
                          </Typography>
                          <br />
                          <Typography className={classes.h3Primary}>
                            <b>{labels.PAYMENT_CONFIRMATION_PAYMENT_DATE}</b>
                          </Typography>
                          <Typography className={classes.h3Primary}>
                            {postPaymentProcessing.mainData
                              ? commonService.dateFormat(
                                  element.paymentInfo.input.paymentDateTime,
                                  "MMM DD, YYYY"
                                )
                              : null}
                          </Typography>
                          <br />
                          <Typography className={classes.h3Primary}>
                            <b>
                              {paymentError.isInError === "true" ? (
                                <span>
                                  {labels.PAYMENT_CONFIRMATION_AMOUNT_LABEL}
                                </span>
                              ) : (
                                labels.PAYMENT_CONFIRMATION_AMOUNT_PAID
                              )}
                            </b>
                          </Typography>
                          <Typography className={customStyle.amountTertiary}>
                            {postPaymentProcessing.mainData
                              ? element.paymentInfo.input.currency +
                                " " +
                                Number(parseFloat(element.paymentInfo.input.paymentAmount).toFixed(2)).toLocaleString('en', {
                                  minimumFractionDigits: 2
                              })
                              : null}
                          </Typography>
                        </Card>
                        {paymentError.isInError === "true" ? (
                          <React.Fragment>
                            <Grid item xs={12} md={12}>
                              <Card>
                                <div
                                  className={customStyle.lightblueColorPayment}
                                >
                                  <Grid container direction="row" spacing={2}>
                                    <Grid item>
                                      {paymentError.status ===
                                      "INTERNAL_ERROR" ? (
                                        <Button
                                          variant="contained"
                                          className={customStyle.greyButton}
                                          onClick={supportTicketCall}
                                        >
                                          {
                                            labels.PAYMENT_CONFIRMATION_BUTTON_REQUEST_HELP
                                          }
                                        </Button>
                                      ) : (
                                        <React.Fragment>
                                          <Button
                                            variant="contained"
                                            className={
                                              customStyle.secondaryButton
                                            }
                                            onClick={
                                              props.response.location ===
                                              "billpay"
                                                ? () => {
                                                    history.push(
                                                      routePaths.BILL_DETAILS
                                                    );
                                                  }
                                                : retryPayment
                                            }
                                          >
                                            {
                                              labels.PAYMENT_CONFIRMATION_BUTTON_RETRY_PAYMENT
                                            }
                                          </Button>
                                          <Button
                                            variant="contained"
                                            className={customStyle.greyButton}
                                            onClick={supportTicketCall}
                                          >
                                            {
                                              labels.PAYMENT_CONFIRMATION_BUTTON_REQUEST_HELP
                                            }
                                          </Button>
                                        </React.Fragment>
                                      )}
                                    </Grid>
                                    <Grid item></Grid>
                                  </Grid>
                                </div>
                              </Card>
                            </Grid>
                          </React.Fragment>
                        ) : null}
                      </Grid>
                    </Grid>
                    <hr />
                  </React.Fragment>
                );
              })
            : // : postPaymentProcessing.mainData && (
              //     <Grid container direction="row" justify="center">
              //       <Grid item xs={12} md={6}>
              //         <br />
              //         <Typography className={classes.h2Primary}>
              //           <b>{labels.PAYMENT_CONFIRMATION_INFO_TITLE}</b>
              //         </Typography>

              //         <br />
              //         {postPaymentProcessing ? (
              //           <Table size="small">
              //             <TableBody>
              //               <TableRow>
              //                 <TableCell className={headStyle}>
              //                   <b>{labels.PAYMENT_CONFIRMATION_INFO_TYPE}</b>
              //                 </TableCell>
              //                 <TableCell className={headStyle} align="left">
              //                   {
              //                     postPaymentProcessing.mainData.paymentInfo.input
              //                       .paymentType
              //                   }
              //                 </TableCell>
              //               </TableRow>
              //               <TableRow>
              //                 <TableCell className={headStyle}>
              //                   <b>
              //                     {labels.PAYMENT_CONFIRMATION_INFO_CARD_DETAILS}
              //                   </b>
              //                 </TableCell>
              //                 <TableCell className={headStyle} align="left">
              //                   {postPaymentProcessing.mainData.paymentInfo.input
              //                     .creditCardInfo.cardType +
              //                     ":" +
              //                     postPaymentProcessing.mainData.paymentInfo.input
              //                       .creditCardInfo.cardNumber}
              //                 </TableCell>
              //               </TableRow>

              //             </TableBody>
              //           </Table>
              //         ) : null}
              //       </Grid>
              //       <Grid item xs={12} md={6}>
              //         <Card className={customStyle.lightblueColorPayment}>
              //           <Typography className={classes.h3Primary}>
              //             <b> {connectionLabel}</b>
              //           </Typography>
              //           <Typography className={classes.h3Primary}>
              //            {postPaymentProcessing.mainData.head.connectionId}
              //             <br />
              //           </Typography>
              //           {multipleAccountDetails.saDetails && (
              //             <Grid container>
              //               <Grid item xs={8}>
              //                 <Typography color="primary">
              //                   <Grid item>
              //                     {multipleAccountDetails.saDetails[0]
              //                       .connectionDescription +
              //                       ", " +
              //                       multipleAccountDetails.accountName +
              //                       " (" +
              //                       multipleAccountDetails.accountRelationshipType +
              //                       ")"}
              //                   </Grid>
              //                 </Typography>
              //               </Grid>
              //             </Grid>
              //           )}
              //           <br />
              //           <Typography className={classes.h3Primary}>
              //             <b>{labels.PAYMENT_CONFIRMATION_PAYMENT_DATE}</b>
              //           </Typography>
              //           <Typography className={classes.h3Primary}>
              //             {postPaymentProcessing.mainData
              //               ? commonService.dateFormat(
              //                   postPaymentProcessing.mainData.paymentInfo.input
              //                     .paymentDateTime,
              //                   "MMM DD, YYYY"
              //                 )
              //               : null}
              //           </Typography>
              //           <br />
              //           <Typography className={classes.h3Primary}>
              //             <b>{labels.PAYMENT_CONFIRMATION_AMOUNT_PAID}</b>
              //           </Typography>
              //           <Typography className={customStyle.amountTertiary}>
              //             {postPaymentProcessing.mainData
              //               ? postPaymentProcessing.mainData.paymentInfo.input
              //                   .currency +
              //                 " " +
              //                 postPaymentProcessing.mainData.paymentInfo.input
              //                   .paymentAmount
              //               : null}
              //           </Typography>
              //         </Card>
              //       </Grid>
              //     </Grid>
              //   )
              null}
        </div>
      </div>
      <div>
      <Hidden smUp>
        {open &&
        <div className="textPayment">
          <span className={customStyle.greyColor}>
            {labels.PAYMENT_CONFIRMATION_SUBTITLE}&nbsp;
            <Link to={routePaths.SELF_HELP} className={customStyle.linkColor}>
              <b> {labels.PAYMENT_CONFIRMATION_FAQ}</b>
            </Link>
          </span>
        </div>
        }
        </Hidden>
        <Hidden xlUp xsDown>
        <div className="textPayment">
          <span className={customStyle.greyColor}>
            {labels.PAYMENT_CONFIRMATION_SUBTITLE}&nbsp;
            <Link to={routePaths.SELF_HELP} className={customStyle.linkColor}>
              <b> {labels.PAYMENT_CONFIRMATION_FAQ}</b>
            </Link>
          </span>
        </div>
        </Hidden>
        <hr />
        <div className={customStyle.maindivPayment}>
          {postPaymentProcessing.mainData.length > 0
            ? postPaymentProcessing.mainData.map((element, k) => {
                return (
                  <React.Fragment key={k}>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignContent="center"
                    >
                      <Grid item xs={12} md={6}>
                        <br />
                        <Typography className={classes.h2Primary}>
                          {postPaymentProcessing.mainData.length > 1 ? (
                            <b>
                              {labels.PAYMENT_CONFIRMATION_INFO_TITLE +
                                `(${k + 1}/${
                                  postPaymentProcessing.mainData.length
                                })`}
                            </b>
                          ) : (
                            <b>{labels.PAYMENT_CONFIRMATION_INFO_TITLE}</b>
                          )}
                        </Typography>
                        <br />
                        {postPaymentProcessing ? (
                          <Table size="small">
                            <TableBody>
                              <TableRow>
                                <TableCell className={headStyle}>
                                  <b>{labels.PAYMENT_CONFIRMATION_INFO_TYPE}</b>
                                </TableCell>
                                <TableCell className={headStyle} align="left">
                                  {element.paymentInfo.input.paymentType}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={headStyle}>
                                  <b>
                                    {labels.PAYMENT_CONFIRMATION_INFO_CARD_TYPE}
                                  </b>
                                </TableCell>
                                <TableCell className={headStyle} align="left">
                                  {
                                    element.paymentInfo.input.creditCardInfo
                                      .cardType
                                  }
                                </TableCell>
                              </TableRow>
                              {element.paymentInfo.input.creditCardInfo
                                .cardNumber && (
                                <TableRow>
                                  <TableCell className={headStyle}>
                                    <b>
                                      {
                                        labels.PAYMENT_CONFIRMATION_INFO_CARD_NUMBER
                                      }
                                    </b>
                                  </TableCell>
                                  <TableCell className={headStyle} align="left">
                                    {
                                      element.paymentInfo.input.creditCardInfo
                                        .cardNumber
                                    }
                                  </TableCell>
                                </TableRow>
                              )}
                              {element.paymentInfo.input.creditCardInfo
                                .expMonth && (
                                <TableRow>
                                  <TableCell className={headStyle}>
                                    <b>
                                      {
                                        labels.PAYMENT_CONFIRMATION_CARD_EXPIRY_DATE
                                      }
                                    </b>
                                  </TableCell>
                                  <TableCell className={headStyle} align="left">
                                    {element.paymentInfo.input.creditCardInfo
                                      .expMonth +
                                      "/" +
                                      element.paymentInfo.input.creditCardInfo
                                        .expYear}
                                  </TableCell>
                                </TableRow>
                              )}
                             {element.billingAddress &&
                              <TableRow>
                                <TableCell className={headStyle}>
                                  <b>{labels?.PAYMENT_BILLING_ADDRESS}</b>
                                </TableCell>
                                <TableCell className={headStyle} align="left">
                                  {element.billingAddress}
                                </TableCell>
                              </TableRow>
                              }
                            </TableBody>
                            <br />
                          </Table>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Card className={customStyle.lightblueColorPayment}>
                          <Typography className={classes.h3Primary}>
                            <b>
                              {element.head.connectionId
                                ? connectionLabel
                                : element.head.billId
                                ? "Bill Id"
                                : null}
                            </b>
                          </Typography>
                          <Typography className={classes.h3Primary}>
                            {element.head.connectionId
                              ? element.head.connectionId
                              : element.head.billId
                              ? element.head.billId
                              : null}
                            <br />
                          </Typography>
                          <br />

                          {paymentError.isInError === "true" ? null :
                          element.errorInformation.isInError === "true" ? null : (
                            <React.Fragment>
                              <Typography className={classes.h3Primary}>
                                <b>{receiptLabel}</b>
                              </Typography>
                              <Typography className={classes.h3Primary}>
                                {element.paymentInfo.output &&
                                 
                                    element.paymentInfo.inputOutput.paymentEventId}

                                <br />
                              </Typography>
                              <br />
                            </React.Fragment>
                          )}
                          <Typography className={classes.h3Primary}>
                            <b>{labels.PAYMENT_CONFIRMATION_PAYMENT_STATUS}</b>
                          </Typography>
                          <Typography className={classes.h3Primary}>
                            {paymentError.isInError === "true" ? (
                              <span className="redColor">
                                {labels.PAYMENT_CONFIRMATION_ERROR +
                                  ": " +
                                  paymentError.error}
                              </span>
                            ) :
                            element.errorInformation.isInError === "true"? 
                            <span className="redColor">
                            {labels.PAYMENT_CONFIRMATION_ERROR +
                              ": " +
                              element.errorInformation.errorMessage}
                          </span>
                            :(
                              <span className="greenColor">
                                {labels.PAYMENT_CONFIRMATION_SUCCESS}
                              </span>
                            )}
                            <br />
                          </Typography>
                          <br />
                          <Typography className={classes.h3Primary}>
                            <b>{labels.PAYMENT_CONFIRMATION_PAYMENT_DATE}</b>
                          </Typography>
                          <Typography className={classes.h3Primary}>
                            {postPaymentProcessing.mainData
                              ? commonService.dateFormat(
                                  element.paymentInfo.input.paymentDateTime,
                                  "MMM DD, YYYY"
                                )
                              : null}
                          </Typography>
                          <br />
                          <Typography className={classes.h3Primary}>
                            <b>
                              {paymentError.isInError === "true" || 
                              element.errorInformation.isInError === "true" ? (
                                <span>
                                  {labels.PAYMENT_CONFIRMATION_AMOUNT_LABEL}
                                </span>
                              ) : (
                                labels.PAYMENT_CONFIRMATION_AMOUNT_PAID
                              )}
                            </b>
                          </Typography>
                          <Typography
                            className={customStyle.amountTertiary}
                            variant="h5"
                          >
                            {postPaymentProcessing.mainData
                              ? element.paymentInfo.input.currency +
                                " " +
                                Number(parseFloat(element.paymentInfo.input.paymentAmount).toFixed(2)).toLocaleString('en', {
                                  minimumFractionDigits: 2
                              })
                              : null}
                          </Typography>
                        </Card>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row-reverse"
                      justify="space-between"
                      alignContent="center"
                    >
                      <Grid item xs={12} md={6}>
                        {paymentError.isInError === "true" || 
                         element.errorInformation.isInError === "true" ? (
                          <React.Fragment>
                            <Card>
                              <div
                                className={customStyle.lightblueColorPayment}
                              >
                                <Grid container direction="row" spacing={2}>
                                  <Grid item>
                                    {paymentError.status ===
                                    "INTERNAL_ERROR" ? (
                                      <Button
                                        variant="contained"
                                        className={customStyle.greyButton}
                                        onClick={supportTicketCall}
                                      >
                                        {
                                          labels.PAYMENT_CONFIRMATION_BUTTON_REQUEST_HELP
                                        }
                                      </Button>
                                    ) : (
                                      <React.Fragment>
                                        <Button
                                          variant="contained"
                                          className={
                                            customStyle.secondaryButton
                                          }
                                          onClick={
                                            props.response.location ===
                                            "billpay"
                                              ? () => {
                                                  history.push(
                                                    routePaths.BILL_DETAILS
                                                  );
                                                }
                                              : retryPayment
                                          }
                                        >
                                          {
                                            labels.PAYMENT_CONFIRMATION_BUTTON_RETRY_PAYMENT
                                          }
                                        </Button>
                                        <Button
                                          variant="contained"
                                          className={customStyle.greyButton}
                                          onClick={supportTicketCall}
                                        >
                                          {
                                            labels.PAYMENT_CONFIRMATION_BUTTON_REQUEST_HELP
                                          }
                                        </Button>
                                      </React.Fragment>
                                    )}
                                  </Grid>
                                  <Grid item></Grid>
                                </Grid>
                              </div>
                            </Card>
                          </React.Fragment>
                        ) : null}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={paymentError.isInError === "true" ? 6 : 12}
                      >
                        <hr />
                        {lastContent ? (
                          <Typography
                            className={customStyle.greyColor}
                            dangerouslySetInnerHTML={{
                              __html: lastContent,
                            }}
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                );
              })
            : null}
        </div>
        <br />
      </div>
    </div>
  );
}

const actionCreators = {
  getTicketCategory: ticketAction.getTicketCategory,
  getConnection: ticketAction.getConnection,
};

const connectedPaymentConfirmationComponent = connect(
  null,
  actionCreators
)(PaymentConfirmationComponent);
export { connectedPaymentConfirmationComponent as PaymentConfirmationComponent };
