import { makeStyles } from "@material-ui/core/styles";

export const inactiveAccountBannerStyles = makeStyles((theme) => ({
  container: {
    marginLeft:"-20px", 
    marginRight:"-20px",
  },  
  linkText: {
      color: theme.palette.primary.main,    
    },
  containerSM: {
    marginLeft:"-20px", 
    marginRight:"-10px",
  },
  insideData:{
    padding:"5px"
  }
  }));