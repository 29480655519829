import { adminService } from './../services/admin.service'
import { appConstants } from "../constants/app.constants";
import { authService } from './../services/auth.service';
import {languageActions} from "./language.actions"
import {appActions} from "./app.actions"
import {store} from "../helpers/store"
import { batch } from 'react-redux'
export {
    userDetails,
    accountDetails,
    updateMeterDetails,
    accountDetailsWithApiCall
}
function userDetails() {
    return dispatch => {
        adminService.profileDetails().then(profileResponse => {
            dispatch(dispatchFormat(appConstants.PROFILE_DETAILS_SUCESS, profileResponse.data))
            dispatch(accountDetails())
        }).catch(profileError => {
            dispatch(dispatchFormat(appConstants.PROFILE_DETAILS_ERROR, {
                email: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                address: {
                    city: '',country: '',stateCode: '' ,streetAddress1: '',streetAddress2: '',zipCode: ''
                }
            }))
        })
    }
}

function accountDetailsWithApiCall() {
    return dispatch => {
        authService.accountDetais().then(accountDetails => {           
            let bearer = JSON.parse(localStorage.getItem("bearer"));
            if(bearer){
                bearer.accountDetails = accountDetails;
                localStorage.setItem("bearer", JSON.stringify(bearer));
            }
            let getFirstMeter;
            if(localStorage.getItem('selectedConnection')){
                getFirstMeter = (accountDetails?.accountSummary?.accountDetails) ? accountDetails.accountSummary.accountDetails.find(singleAcc => singleAcc.saDetails[0].connectionId===localStorage.getItem('selectedConnection')) : {};
            }else{
                getFirstMeter = (accountDetails?.accountSummary?.accountDetails) ?  accountDetails.accountSummary.accountDetails.find(singleAcc => !singleAcc.isDisabled) : {};
            }
            batch(() => {
                dispatch(updateMeterDetails(getFirstMeter,getFirstMeter.saDetails[0]));
                dispatch(dispatchFormat(appConstants.ACCOUNT_DETAILS_SUCESS, accountDetails?.accountSummary));
            })
        }).catch(accountInfoError => {
            dispatch(dispatchFormat(appConstants.ACCOUNT_DETAILS_ERROR, {
                accountName: '',
                accountType: '',
                accountId: '',
                billingAddress: '',
                externalMeters: []
            }))
        })
    }
}

function accountDetails(){
    return (dispatch, getState)=> {
        let accountInfo = {};
        let bearer = JSON.parse(localStorage.getItem("bearer"));
            if(bearer.accountDetails){
                accountInfo = bearer.accountDetails.accountSummary;
            }
        let getFirstMeter;
        if(localStorage.getItem('selectedConnection')){
            getFirstMeter= (accountInfo.accountDetails) ? accountInfo.accountDetails.find(singleAcc => singleAcc.saDetails[0].connectionId===localStorage.getItem('selectedConnection')) : {};
        }else{
            getFirstMeter = (accountInfo.accountDetails) ? accountInfo.accountDetails.find(singleAcc => !singleAcc.isDisabled) : {};
        }
        batch(() => {
            getState().snackbar = true;
            dispatch(updateMeterDetails(getFirstMeter,getFirstMeter.saDetails[0]));
            dispatch(dispatchFormat(appConstants.ACCOUNT_DETAILS_SUCESS, accountInfo));
            getState().snackbar= false;
        })
    }
}

function updateMeterDetails(meterInformation,saId) {
    const sampleObj= {getSaInfo:saId};
    meterInformation={...meterInformation,...sampleObj}
    return dispatch => {
        dispatch(dispatchFormat(appConstants.UPDATE_OR_ADD_METER_DETAILS, meterInformation))
    }
}
function dispatchFormat(actionType, snack) {
    return { type: actionType, snack };
}