import { makeStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

export const HomeStyles = makeStyles(theme => ({
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: "white"
  },
  dueamount: {
    color: theme.palette.secondary.main,
    letterSpacing: "0",
    lineHeight: "25px",
    textAlign: "center"
  },
  dueamountBIllCYCLE: {
    color: "gray !important",
    letterSpacing: "0",
    lineHeight: "25px",
    textAlign: "center"
  },
 
    duetext: {
    color: theme.palette.primary.main,
    fontSize: "15px",
    textAlign: "center",
    marginBottom: "-5px"
  },
  duetextNew: {
    color: theme.palette.quaternary.main+ "!important",
    fontSize: "15px",
    textAlign: "center",
    marginBottom: "-5px"
  },
  amountDueTitle: {
    marginTop: "10px",
    marginBottom: "10px",
    color: theme.palette.primary.main
  },
  quaternaryColor: {
    color: theme.palette.quaternary.main + "!important"
  },
  lastBillTitle: {
    marginTop: "5px",
    marginBottom: "10px",
    color: "gray"
  },
 
  centerTitle: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center"
  },
  cDateColor: {
    color: theme.palette.quaternary.main + "!important"
  },
  responseDive: {
    marginTop:"10%"
  },
  lowerFont:{
    textTransform:"lowercase",
    display:"flex",
    justifyContent: "center",
    marginTop:"16px !important",
    "&:hover": {
      color:theme.palette.secondary.main
    }
  },
  lowerFontSM:{
    textTransform:"lowercase",
    display:"flex",
    justifyContent: "center",
    "&:hover": {
      color:theme.palette.secondary.main
    }
  },
  paragraphQuaternaryEmail:{
    wordBreak: "break-all",
    color: theme.palette.quaternary.main + " !important"
  }
}));

export const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

export const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});
export const dialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
export const dialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

export const dialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);