import { createMuiTheme } from "@material-ui/core/styles";
import "./app.css";

const font = "'Noto Sans'";

export const appStyles = {
  outerTheme: outerTheme(),
};

function outerTheme() {
  return createMuiTheme({
    palette: {
      primary: {
        main: "#004987",
      },
      secondary: {
        main: "#FFA400",
      },
      tertiary: {
        main: "#008996",
      },
      quaternary: {
        main: "#D3D3D3",
      },
      quinary: {
        main: "DDE6EE",
      },
      whiteColor: {
        main: "#FFFFFF",
      },
      blackColor: {
        main: "#000000",
      },
      usageNegativeColor: { 
        main: "#FF0000",
      },
      usagePositiveColor: {
        main: "#01B61E",
      },
      lightGrayColor: {
        main: "#ECECEC",
      },
      lightRedColor: {
        main: "#FFF1F0",
      },
      lightGraphColor: {
        main: "#f2dfde",
      },
      grayColor: {
        main: "#DCDCDC",
      }
    },
    fontFamily: font,
  });
}
