const BASE_URL = "https://my.qatarcool.com";
const OAUTH_BASE_URL = BASE_URL + "/auth/";
const ADMIN_BASE_URL = BASE_URL + "/admin/";
const LOGIN_APP_URL = BASE_URL + "/login/";
const ASSEST_MANAGER_URL = BASE_URL + "/assets";
const USAGE_SERVICE_ENDPOINT = BASE_URL + "/usage/";
const ROCKET_CHAT_WIDGET = BASE_URL + "/assets/qatarcool-helpbot/";
const BASE_TENANT_CODE = "QCC";
const TENANT_MAP_URL =
  "https://maps.google.com/maps?q=Qatar%20Cool%20Head%20Office%20%2044%20Maysaloun%20Street%20%20Po%20Box%2024979%20%20West%20Bay%2C%20Doha%2C%20Qatar%20%20&t=&z=13&ie=UTF8&iwloc=&output=embed";
const ADMIN_SERVICE_BASE_URL = BASE_URL + "/admin/admin-service";
const USAGE_SERVICE_BASE_URL = BASE_URL + "/usage/usage-service";
const COMMUNICATION_SERVICE_BASE_URL =
  BASE_URL + "/communication/communication-service";
const PAYMENT_URL = "https://ap-gateway.mastercard.com/checkout/version/60/checkout.js";
const CACHE_EXPIRATION_TIME= 30 * 24 * 60 * 60

const constants = {
  urls: {
    BASE_URL: BASE_URL,
    MAP_URL: TENANT_MAP_URL,
    LOGIN: OAUTH_BASE_URL + "login",
    USAGE_ELECTRICITY_DATA: USAGE_SERVICE_ENDPOINT + "usage-comparison",
    LANGUAGE_LIST: "https://api.myjson.com/bins/1fj62q",
    LOGIN_APP: LOGIN_APP_URL + "?logout=true",
    SIGNUP:BASE_URL+"/sign-up",
    LOGIN_VIA_OTP:BASE_URL+"/login-via-otp",
    QUICK_PAY:BASE_URL + "/path/quick-pay",
    PAYMENT:BASE_URL + "/path/payment",
    BILL_PAY:BASE_URL + "/path/bill-pay",
    VIEW_BALANCE:BASE_URL + "/view-balance",
    PAYMENT_BILLPAY:BASE_URL+"/path/payment/billpay", 
    NSA:BASE_URL+"/path/new-service-agreement", 
    LABELS: ADMIN_BASE_URL + "labels/" + BASE_TENANT_CODE,
    PARAMETER_WIDGET_CONTROL:
      ADMIN_BASE_URL + "parameter-controller/" + BASE_TENANT_CODE,
    ASSEST_CODE_API: ADMIN_BASE_URL + "assets/" + BASE_TENANT_CODE + "/",
    PROFILE_DETAILS_DATA: ADMIN_BASE_URL + "profile-details",
    ASSEST_URL_ENDPOINT: ASSEST_MANAGER_URL,
    ADMIN_BASE_URL,
    ROCKET_CHAT_WIDGET,
    USAGE_SERVICE_ENDPOINT,
    EXPIRE_SESSION: OAUTH_BASE_URL + "login/signout",
    REFRESH_BEARER: OAUTH_BASE_URL + "login/refresh",
    AUTH_SERVICE: OAUTH_BASE_URL + "auth-service",
    UPDATE_PASSWORD: OAUTH_BASE_URL + "login/update-password",
    ACCOUNT_SUMMARY_USAGE_GRAPH:
      USAGE_SERVICE_ENDPOINT + "usage-account-dashboard",
    ACCOUNT_SUMMARY_USAGE: USAGE_SERVICE_ENDPOINT + "account-summary-usage",
    ADMIN_SERVICE_BASE_URL: ADMIN_SERVICE_BASE_URL,
    USAGE_SERVICE_BASE_URL: USAGE_SERVICE_BASE_URL,
    OAUTH_SERVICE_BASE_URL: OAUTH_BASE_URL + "auth-service",
    LOGIN_RESENDOTP: OAUTH_BASE_URL + "otp/resend",
    VALIDATEOTP: OAUTH_BASE_URL + "otp/validate",
    COMMUNICATION_SERVICE_BASE_URL: COMMUNICATION_SERVICE_BASE_URL,
    PREPAYMENTBILL:USAGE_SERVICE_ENDPOINT+"billpay/prepayment-processing-bill-pay",
    POSTPAYMENTBILL_PAY: USAGE_SERVICE_ENDPOINT+"billpay/postpayment-processing-bill-pay",
    PAYMENT_URL,
    ADMIN_LOG_URL: ADMIN_BASE_URL + "log/external",
  },
  constants: {
    BASE_TENANT_CODE: BASE_TENANT_CODE,
    CACHE_EXPIRATION_TIME,
    ACCOUNT_DETAILS_UPDATE_MINIMUM_INTERVAL: "ACCOUNT_DETAILS_UPDATE_MINIMUM_INTERVAL_TIME",
    ACCOUNT_DETAILS_UPDATE_MINIMUM_INTERVAL_FALLBACK: "3600000"
  },
};
export const config = constants;
