import React from "react";
import { InitiatePaymentService } from "../multiple-payment/initiate-payment-service";
import { store } from "../../../helpers/store";
import { connect } from "react-redux";
import { history } from "../../../helpers/index";
import { routePaths } from "../../config/route-paths";
import { appService } from "../../../services/app.service";
import { PaymentConfirmationComponent } from "../payment-confirmation/payment-confirmation";
import { accountDetailsWithApiCall } from "../../../actions/home.action";
import { Typography } from "@material-ui/core";

let nodataLabel = {};
if (appService.getLabels()) {
  nodataLabel = appService.getLabels().getLabels.CONFIGURATIONS;
}
class PaymentConfirmationSkipCash extends React.Component {
  constructor(props) {
    super();
    this.state = {
      response: {},
      location: null,
      contentRes: "",
      waitContent: "",
      error: null,
    };
  }

  componentDidMount() {
    if (this.props.contentRes) {
      this.setState({
        contentRes: this.props.contentRes,
      });
      this.setState({
        waitContent: this.props.contentRes.find(
          (item) => item.contentCode === "PAYMENT_WAIT_MESSAGE"
        ),
      });
    }
    let creditCardGatewayCode = localStorage.getItem("creditCardGatewayCode");
    let connectionId = [];
    if (localStorage.getItem("PaymentConnections")) {
      let PaymentConnections = JSON.parse(
        localStorage.getItem("PaymentConnections")
      );
      PaymentConnections.forEach((element) => {
        connectionId.push(element.ConnectionId);
      });
    }
    const queryString = window.location.search
      .substring(1)
      .replace(/\+/g, "%2B");
    const urlParams = new URLSearchParams(queryString);
    const RequestInput = {};
    urlParams.forEach((value, key) => {
      const decodedKey = decodeURIComponent(key);
      const decodedValue = decodeURIComponent(value);
      RequestInput[decodedKey] = decodedValue;
    });

    let billId = localStorage.getItem("billID");
    if (RequestInput.statusId && RequestInput.status && RequestInput.id) {
      if (billId) {
        let accountId = localStorage.getItem("accountId");
        InitiatePaymentService.postPaymentProcessingCreditBillPay(
          creditCardGatewayCode,
          accountId,
          RequestInput
        ).then(
          (res) => {
            this.setState({
              response: res,
              location: "billpay",
            });
            store.getState().snackbar = false;
            this.props.accountDetailsWithApiCall();
          },
          (error) => {
            if (error?.response?.status === 400) {
              if (error?.response?.data?.message) {
                let errorCode = error.response.data.message;
                if (errorCode == "ORDER_NOT_FOUND") {
                  this.setState({ error: "Your order does not found" });
                } else if (errorCode == "INVALID_GATEWAY_CODE") {
                  this.setState({ error: "Invalid Gateway Code" });
                } else if (errorCode == "ORDER_ID_MISSING") {
                  this.setState({ error: "Order id is missing" });
                } else {
                  this.setState({
                    error: errorCode
                      ? errorCode
                      : "Error occurred: Transaction not successful",
                  });
                }
              }
            } else {
              this.setState({
                error: "Error occurred: Transaction not successful",
              });
            }
          }
        );
        localStorage.removeItem("accountId");
        localStorage.removeItem("orderId");
        localStorage.removeItem("billID");
        localStorage.removeItem("PaymentConnections");
      } else {
        InitiatePaymentService.postPaymentProcessingPay(
          creditCardGatewayCode,
          RequestInput
        ).then(
          (res) => {
            this.setState({
              response: res.data.data.postPaymentProcessing,
              location: "payment",
            });
            store.getState().snackbar = false;
            this.props.accountDetailsWithApiCall();
          },
          (error) => {
            if (error?.response?.status === 400) {
              if (error?.response?.data?.errors?.[0]?.message) {
                let errorCode = error.response.data.errors[0].message;
                if (errorCode == "ORDER_NOT_FOUND") {
                  this.setState({ error: "Your order does not found" });
                } else if (errorCode == "INVALID_GATEWAY_CODE") {
                  this.setState({ error: "Invalid Gateway Code" });
                } else if (errorCode == "ORDER_ID_MISSING") {
                  this.setState({ error: "Order id is missing" });
                } else {
                  this.setState({
                    error: errorCode
                      ? errorCode
                      : "Error occurred: Transaction not successful",
                  });
                }
              }
            } else {
              this.setState({
                error: "Error occurred: Transaction not successful",
              });
            }
          }
        );
        localStorage.removeItem("accountId");
        localStorage.removeItem("orderId");
        localStorage.removeItem("billID");
        localStorage.removeItem("PaymentConnections");
      }
    } else {
      localStorage.removeItem("accountId");
      localStorage.removeItem("orderId");
      localStorage.removeItem("billID");
      localStorage.removeItem("PaymentConnections");
      if (billId) {
        history.push(routePaths.BILL_DETAILS);
      } else {
        history.push(routePaths.INITIATE_PAYMENT);
      }
    }
  }

  render() {
    return (
      <div>
        {this.state.response && this.state.response.mainData ? (
          <PaymentConfirmationComponent response={this.state} />
        ) : (
          <Typography
            align="center"
            color="primary"
            variant="h6"
            className="noDataFound"
          >
            {this.state.error
              ? this.state.error
              : this.state.waitContent
              ? this.state.waitContent.answer
              : null}
          </Typography>
        )}
      </div>
    );
  }
}

const mapDispachToProps = {
  accountDetailsWithApiCall,
};
const connectedApp = connect(
  null,
  mapDispachToProps
)(PaymentConfirmationSkipCash);
export { connectedApp as PaymentConfirmationSkipCash };
