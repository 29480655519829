import { config } from "../enviroment";
import axios from "axios";
const profileInformation = JSON.parse(localStorage.getItem("bearer"));

const endPoints = config.urls;
export const adminService = {
  profileDetails,
  getContentData,
  log
};

async function profileDetails() {
  if (localStorage.getItem("bearer")) {
    return await axios.post(`${endPoints.ADMIN_BASE_URL}profile-details`, {
      email: (profileInformation.email).toLowerCase(),
      tenantCode: config.constants.BASE_TENANT_CODE,
    });
  }
}

 function getContentData(languageCode, categoryCode) {
  if (localStorage.getItem("bearer")) {
    return new Promise((resolve, reject) => {
      axios
        .post(config.urls.ADMIN_SERVICE_BASE_URL, {
          query:
            `{getContents(input:{languageCode:"` +
            languageCode +
            `",categoryCode:"` +
            categoryCode +
            `"})}`,
        })
        .then(function(response) {
          resolve(response.data);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  }
}
async function log({ key, message, logLevel, timeStamp, addOnLog, stackOrLog }) {
  let ADD_ON_LOGS_ENABLE = true;
  if(!message) return;
  if(key && key.indexOf("NSA") === 0 && localStorage.getItem("NSA_LOG_KEY")) {
    key = key.slice(0, 3) + `_${localStorage.getItem("NSA_LOG_KEY")}` + key.slice(3)
  }
  if(ADD_ON_LOGS_ENABLE && addOnLog) {
    message = message + " - " + JSON.stringify(addOnLog)
  }
  if(stackOrLog === "STACK") {
    let log = localStorage.getItem("LOG");
    log = log ? JSON.parse(log) : [];
    log.push({ key, message });
    localStorage.setItem("LOG", JSON.stringify(log));
    return;
  } else {
    let logs = localStorage.getItem("LOG");
    logs = logs ? JSON.parse(logs) : [];
    if(logs?.length) {
      key = logs[0]?.key ? logs[0].key : key;
      logs.forEach(log => {
        message += ` - ${log?.message}`;
      });
    }
    localStorage.removeItem("LOG");
  }
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.ADMIN_LOG_URL,
      method: "post",
      data: {
        key: key ? key : "UI_CLIENT",
        message: message ? message : "",
        logLevel: logLevel ? logLevel : "",
        timeStamp: timeStamp ? timeStamp : "",
      }
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
