import React from "react";
import { Bar } from "react-chartjs-2";
import { ColorTooltip } from "../app/base-style";
import { Link } from "react-router-dom";
import { routePaths } from "../config/route-paths";
import {
  FormControl,
  Grid,
  Typography,
  Card,
  CardContent,
  Hidden,
} from "@material-ui/core";
import { commonService } from "../../services/common.service";
import { appStyles } from "../app/app-style";
import {
  billComparisonStyles,
  STYLED_SELECT,
  STYLED_MENU_ITEM,
} from "./bill-comparison-style";
import { useStyles } from "../app/base-style";
import { appService } from "../../services/app.service";
import moment from "moment";
import "./bill-comparison-style.css";
import "../app/base-style.css";
import ExpandMoreSharpIcon from "@material-ui/icons/ExpandMoreSharp";

export default function BillComparisonComponent(props) {
  const classes = useStyles();
  const customStyle = billComparisonStyles();
  let labels = {};
  let response = props.res;
  if (appService.getLabels()) {
    labels = appService.getLabels().getLabels.BILLING_COMPARISON;
  }
  let previousData = [];
  let currentData = [];

  if (response && response.length > 1) {
    let dataLength = null;
    response[0].label = "BILLING_COMPARISON_CURRENT_BILL";
    if (response[1]) {
      response[1].label = "BILLING_COMPARISON_PREVIOUS_BILL";
    }
    response[0].date = commonService.dateFormat(
      response[0].date,
      "MMM DD, YYYY"
    );
    if (response[1]) {
      response[1].date = commonService.dateFormat(
        response[1].date,
        "MMM DD, YYYY"
      );
    }
    let dt = new Date(response[0].date);
    dt.setFullYear(dt.getFullYear() - 1);
    let lastYearDate = moment(dt).format("YYYY-MM-DD");

    for (var p = 2; p < (response.length > 13 ? 13 : response.length); p++) {
      if (response[p].date === lastYearDate) {
        response[p].label = "BILLING_COMPARISON_LAST_YEAR_BILL";
        response[p].date = commonService.dateFormat(
          response[p].date,
          "MMM DD, YYYY"
        );
      } else {
        dataLength = dataLength + 1;
      }
    }

    for (var m = 2; m < (response.length > 13 ? 13 : response.length); m++) {
      if (response[m].label !== "BILLING_COMPARISON_LAST_YEAR_BILL") {
        response[m].label = response[m].date;
        response[m].date = commonService.dateFormat(
          response[m].date,
          "MMM DD, YYYY"
        );
      }
    }

    let data = [];
    if (dataLength === (response.length > 13 ? 13 : response.length) - 2) {
      data = {
        date: "",
        amount: "0",
        label: "BILLING_COMPARISON_LAST_YEAR_BILL",
      };

      response.push(data);
    }
    currentData.push(response[0]);
    for (var i = 1; i < (response.length > 13 ? 13 : response.length); i++) {
      previousData.push(response[i]);
      currentData.push(response[i]);
    }
  }

  let theme = appStyles.outerTheme.palette;
  let positiveColor = commonService.convertHexToRGBA(
    theme.usagePositiveColor.main,
    50
  );

  let negativeColor = commonService.convertHexToRGBA(
    theme.usageNegativeColor.main,
    50
  );

  let positiveMainColor = commonService.convertHexToRGBA(
    theme.usagePositiveColor.main,
    90
  );

  let negativeMainColor = commonService.convertHexToRGBA(
    theme.usageNegativeColor.main,
    90
  );


  return (
    <ComparisonMain
      BaseStyle={classes}
      customStyle={customStyle}
      previousData={previousData}
      currentData={currentData}
      positiveColor={positiveColor}
      negativeColor={negativeColor}
      positiveMainColor={positiveMainColor}
      negativeMainColor={negativeMainColor}
      labels={labels}
    />
  );
}

class ComparisonMain extends React.Component {
  constructor(props) {
    super();
    this.state = {
      sentence: "",
      previousData: props.previousData,
      currentData: props.currentData,
      previousLabelColor:
        props.previousData[0].amount > props.currentData[0].amount
          ? props.negativeMainColor
          : props.positiveMainColor,
      currentLabelColor:
        props.currentData[0].amount > props.previousData[0].amount
          ? props.negativeMainColor
          : props.positiveMainColor,
      selectedPreviousOption: [
        props.previousData[0].label,
        props.previousData[0].amount,
        props.previousData[0].date,
      ],
      selectedCurrentOptions: [
        props.currentData[0].label,
        props.currentData[0].amount,
        props.currentData[0].date,
      ],
      previousAmount: props.previousData[0].amount,
      currentAmount: props.currentData[0].amount,
      databar: {
        labels: [
          props.previousData[0].label &&
            commonService.dateFormat(
              props.previousData[0].date,
              "MMM 01 - MMM " +
                commonService.get_days(props.previousData[0].date) +
                ", YYYY"
            ),
          props.currentData[0].label &&
            commonService.dateFormat(
              props.currentData[0].date,
              "MMM 01 - MMM " +
                commonService.get_days(props.currentData[0].date) +
                ", YYYY"
            ),
        ],
        datasets: [
          {
            label: props.currentData[0].currency,
            fill: true,
            backgroundColor:
              props.previousData[0].amount > props.currentData[0].amount
                ? [props.negativeColor, props.positiveColor]
                : [props.positiveColor, props.negativeColor],
            borderWidth: 1,
            data: [props.previousData[0].amount, props.currentData[0].amount],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: "white",
          titleFontColor: "#F14F3F",
          bodyFontColor: "#F14F3F",
          xPadding: 10,
          yPadding: 10,
          displayColors: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                display: false,
              },

              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                display: false,
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.currentHandleChange = this.currentHandleChange.bind(this);
  }

  handleChange = (selectedPreviousOption) => {
    this.props.previousData.forEach((content) => {
      if (selectedPreviousOption.target.value === content.label) {
        var parses = content.amount;
        return (
          this.setState({
            previousAmount: parses,
            selectedPreviousOption: [content.label, parses, content.date],
          }),
          (this.state.databar.datasets[0].data[0] = parses),
          content.label !== "BILLING_COMPARISON_CURRENT_BILL" &&
          content.label !== "BILLING_COMPARISON_PREVIOUS_BILL" &&
          content.label !== "BILLING_COMPARISON_LAST_YEAR_BILL"
            ? (this.state.databar.labels[0] = commonService.dateFormat(
                content.label,
                "MMM 01 - MMM " +
                  commonService.get_days(content.label) +
                  ", YYYY"
              ))
            : content.label === "BILLING_COMPARISON_PREVIOUS_BILL"
            ? (this.state.databar.labels[0] = commonService.dateFormat(
                content.date,
                "MMM 01 - MMM " +
                  commonService.get_days(content.date) +
                  ", YYYY"
              ))
            : content.label === "BILLING_COMPARISON_CURRENT_BILL"
            ? (this.state.databar.labels[0] = commonService.dateFormat(
                content.date,
                "MMM 01 - MMM " +
                  commonService.get_days(content.date) +
                  ", YYYY"
              ))
            : content.label === "BILLING_COMPARISON_LAST_YEAR_BILL"
            ? (this.state.databar.labels[0] = commonService.dateFormat(
                content.date,
                "MMM 01 - MMM " +
                  commonService.get_days(content.date) +
                  ", YYYY"
              ))
            : null
        );
      }
    });

    if (
      this.state.databar.datasets[0].data[0] >
      this.state.databar.datasets[0].data[1]
    ) {
      this.state.databar.datasets[0].backgroundColor = [
        this.props.negativeColor,
        this.props.positiveColor,
      ];
      this.setState({
        previousLabelColor: this.props.negativeMainColor,
        currentLabelColor: this.props.positiveMainColor,
      });
    } else {
      this.state.databar.datasets[0].backgroundColor = [
        this.props.positiveColor,
        this.props.negativeColor,
      ];
      this.setState({
        previousLabelColor: this.props.positiveMainColor,
        currentLabelColor: this.props.negativeMainColor,
      });
    }
  };

  currentHandleChange = (selectedCurrentOptions) => {
    this.props.currentData.forEach((content) => {
      if (selectedCurrentOptions.target.value === content.label) {
        var parses = content.amount;

        return (
          this.setState({
            currentAmount: parses,
            selectedCurrentOptions: [content.label, parses, content.date],
          }),
          (this.state.databar.datasets[0].data[1] = parses),
          content.label !== "BILLING_COMPARISON_CURRENT_BILL" &&
          content.label !== "BILLING_COMPARISON_PREVIOUS_BILL" &&
          content.label !== "BILLING_COMPARISON_LAST_YEAR_BILL"
            ? (this.state.databar.labels[1] = commonService.dateFormat(
                content.label,
                "MMM 01 - MMM " +
                  commonService.get_days(content.label) +
                  ", YYYY"
              ))
            : content.label === "BILLING_COMPARISON_PREVIOUS_BILL"
            ? (this.state.databar.labels[1] = commonService.dateFormat(
                content.date,
                "MMM 01 - MMM " +
                  commonService.get_days(content.date) +
                  ", YYYY"
              ))
            : content.label === "BILLING_COMPARISON_CURRENT_BILL"
            ? (this.state.databar.labels[1] = commonService.dateFormat(
                content.date,
                "MMM 01 - MMM " +
                  commonService.get_days(content.date) +
                  ", YYYY"
              ))
            : content.label === "BILLING_COMPARISON_LAST_YEAR_BILL"
            ? (this.state.databar.labels[1] = commonService.dateFormat(
                content.date,
                "MMM 01 - MMM " +
                  commonService.get_days(content.date) +
                  ", YYYY"
              ))
            : null
        );
      }
    });
    if (
      this.state.databar.datasets[0].data[0] >
      this.state.databar.datasets[0].data[1]
    ) {
      this.state.databar.datasets[0].backgroundColor = [
        this.props.negativeColor,
        this.props.positiveColor,
      ];
      this.setState({
        previousLabelColor: this.props.negativeMainColor,
        currentLabelColor: this.props.positiveMainColor,
      });
    } else {
      this.state.databar.datasets[0].backgroundColor = [
        this.props.positiveColor,
        this.props.negativeColor,
      ];
      this.setState({
        previousLabelColor: this.props.positiveMainColor,
        currentLabelColor: this.props.negativeMainColor,
      });
    }
  };

  render() {
    let linkStyle = `margin-top-30 ${this.props.BaseStyle.paragraphSecondary} ${this.props.BaseStyle.centerTitle}`;
    let sentenceStyle = `${this.props.BaseStyle.centerTitle} ${this.props.BaseStyle.paragraphPrimary} wrapWord`;
    let {
      selectedPreviousOption,
      selectedCurrentOptions,
      previousAmount,
      currentAmount,
      previousData,
      currentData,
    } = this.state;

    return (
      <Card className="card">
        <CardContent>
        <Typography
              color="primary"
              gutterBottom
              variant="h6"
              component="h2"
              align="center"
            >
            <b>
              {this.props.labels.BILLING_COMPARISON_TITLE}
            </b>
          </Typography>

          <Grid
            container
            spacing={1}
            direction="row"
            justify="center"
            alignItems="center"
          >
            {/* Previous Data Dropdown  */}
            <Grid item md={4} xs={3}>
              <Grid
                className="leftDropdown"
                container
                direction="column"
                justify="flex-end"
                alignItems="flex-end"
                spacing={6}
              >
                <Grid item>
                  <FormControl className={this.props.customStyle.formControl}>
                    <STYLED_SELECT
                      className="wrapSelect"
                      onChange={this.handleChange}
                      autoWidth={false}
                      value={selectedPreviousOption[0]}
                      IconComponent={ExpandMoreSharpIcon}
                      disableUnderline
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <STYLED_MENU_ITEM value={previousData[0].label}>
                        {this.props.labels.BILLING_COMPARISON_PREVIOUS_BILL}
                      </STYLED_MENU_ITEM>
                      {previousData.map(
                        (content, key) =>
                          content.label ===
                            "BILLING_COMPARISON_LAST_YEAR_BILL" && (
                            <STYLED_MENU_ITEM value={content.label} key={key}>
                              {
                                this.props.labels
                                  .BILLING_COMPARISON_LAST_YEAR_BILL
                              }
                            </STYLED_MENU_ITEM>
                          )
                      )}
                      {previousData.map((content, key) =>
                        content.label !== "BILLING_COMPARISON_LAST_YEAR_BILL" &&
                        content.label !== "BILLING_COMPARISON_CURRENT_BILL" &&
                        content.label !== "BILLING_COMPARISON_PREVIOUS_BILL" ? (
                          <STYLED_MENU_ITEM value={content.label} key={key}>
                            {commonService.dateFormat(
                              content.label,
                              "MMM DD, YYYY"
                            )}
                          </STYLED_MENU_ITEM>
                        ) : null
                      )}
                    </STYLED_SELECT>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    className="leftDropdownInner"
                    direction="column"
                    justify="flex-end"
                    alignItems="flex-end"
                  >
                    <Grid item>
                      <ColorTooltip title={previousAmount} placement="left-end">
                        <Typography
                          className="labelfont"
                          style={{ color: this.state.previousLabelColor }}
                        >
                          {currentData[0].currency}
                          {Number(parseFloat(previousAmount).toFixed(2)).toLocaleString('en', {
                                      minimumFractionDigits: 2
                                  })}
                        </Typography>
                      </ColorTooltip>
                    </Grid>
                    <Grid item>
                      <Typography
                        className={this.props.BaseStyle.paragraphPrimary}
                      >
                        {selectedPreviousOption[2]}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {selectedPreviousOption[2] ? (
                        <Typography
                          className={this.props.BaseStyle.paragraphPrimary}
                        >
                          {commonService.get_days(selectedPreviousOption[2])}
                          &nbsp;{this.props.labels.BILLING_COMPARISON_DAYS}
                        </Typography>
                      ) : (
                        <Typography
                          className={this.props.BaseStyle.paragraphPrimary}
                        >
                          {this.props.labels.BILLING_COMPARISON_NO_DATA}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* Graph */}
            <Grid item md={4} xs={5}>
              <Hidden xlUp smDown>
                <Bar
                  data={this.state.databar}
                  options={this.state.chartOptions}
                  height={500}
                  responsive={true}
                />
              </Hidden>
              <Hidden mdUp xsDown>
                <Bar
                  data={this.state.databar}
                  options={this.state.chartOptions}
                  height={400}
                  responsive={true}
                />
              </Hidden>
              <Hidden smUp>
                <Bar
                  data={this.state.databar}
                  options={this.state.chartOptions}
                  height={600}
                  responsive={true}
                />
              </Hidden>
            </Grid>
            {/* Current Data Dropdown */}
            <Grid item md={4} xs={3}>
              <Grid
                spacing={6}
                className="rightDropdown"
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Grid item>
                  <FormControl className={this.props.customStyle.formControl}>
                    <STYLED_SELECT
                      className="wrapSelect"
                      labelId="demo-simple-select-label-current"
                      id="demo-simple-select"
                      disableUnderline
                      displayEmpty
                      autoWidth={false}
                      value={selectedCurrentOptions[0]}
                      onChange={this.currentHandleChange}
                      IconComponent={ExpandMoreSharpIcon}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <STYLED_MENU_ITEM value={currentData[0].label}>
                        {this.props.labels.BILLING_COMPARISON_CURRENT_BILL}
                      </STYLED_MENU_ITEM>
                      <STYLED_MENU_ITEM value={currentData[1].label}>
                        {this.props.labels.BILLING_COMPARISON_PREVIOUS_BILL}
                      </STYLED_MENU_ITEM>
                      {currentData.map(
                        (content, key) =>
                          content.label ===
                            "BILLING_COMPARISON_LAST_YEAR_BILL" && (
                            <STYLED_MENU_ITEM value={content.label} key={key}>
                              {
                                this.props.labels
                                  .BILLING_COMPARISON_LAST_YEAR_BILL
                              }
                            </STYLED_MENU_ITEM>
                          )
                      )}
                      {currentData.map((content, key) =>
                        content.label !== "BILLING_COMPARISON_LAST_YEAR_BILL" &&
                        content.label !== "BILLING_COMPARISON_CURRENT_BILL" &&
                        content.label !== "BILLING_COMPARISON_PREVIOUS_BILL" ? (
                          <STYLED_MENU_ITEM value={content.label} key={key}>
                            {commonService.dateFormat(
                              content.label,
                              "MMM DD, YYYY"
                            )}
                          </STYLED_MENU_ITEM>
                        ) : null
                      )}
                    </STYLED_SELECT>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Grid
                    className="rightDropdownInner"
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <ColorTooltip title={currentAmount} placement="left-end">
                        <Typography
                          style={{ color: this.state.currentLabelColor }}
                          className="labelfont"
                        >
                          {currentData[0].currency}
                          {Number(parseFloat(currentAmount).toFixed(2)).toLocaleString('en', {
                                      minimumFractionDigits: 2
                                  })}
                        </Typography>
                      </ColorTooltip>
                    </Grid>
                    <Grid item>
                      <Typography
                        className={this.props.BaseStyle.paragraphPrimary}
                      >
                        {selectedCurrentOptions[2]}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {selectedCurrentOptions[2] ? (
                        <Typography
                          className={this.props.BaseStyle.paragraphPrimary}
                        >
                          {commonService.get_days(selectedCurrentOptions[2])}
                          &nbsp;{this.props.labels.BILLING_COMPARISON_DAYS}
                        </Typography>
                      ) : (
                        <Typography
                          className={this.props.BaseStyle.paragraphPrimary}
                        >
                          {this.props.labels.BILLING_COMPARISON_NO_DATA}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        {selectedCurrentOptions[2] && selectedPreviousOption[2] ? (
          Math.round(currentAmount) > Math.round(previousAmount) ? (
            <Typography className={sentenceStyle}>
              {this.props.labels.BILLING_COMPARISON_YOUR}&nbsp;
              {selectedCurrentOptions[2]}&nbsp;
              {this.props.labels.BILLING_COMPARISON_BILL}&nbsp;{" "}
              {this.props.labels.BILLING_COMPARISON_STATEMENT_IS}&nbsp;
              {currentData[0].currency}
              {Number(parseFloat(Math.round(currentAmount) - Math.round(previousAmount)).toFixed(2)).toLocaleString('en', {
                                      minimumFractionDigits: 2
                                  })}&nbsp;
              {this.props.labels.BILLING_COMPARISON_STATEMENT_HIGHER_THAN}&nbsp;
              {selectedPreviousOption[2]} &nbsp;
              {this.props.labels.BILLING_COMPARISON_BILL}
            </Typography>
          ) : Math.round(previousAmount) > Math.round(currentAmount) ? (
            <Typography className={sentenceStyle}>
              {this.props.labels.BILLING_COMPARISON_YOUR}&nbsp;
              {selectedCurrentOptions[2]}&nbsp;
              {this.props.labels.BILLING_COMPARISON_BILL}&nbsp;{" "}
              {this.props.labels.BILLING_COMPARISON_STATEMENT_IS}&nbsp;
              {currentData[0].currency}
              {Number(parseFloat(Math.round(previousAmount) - Math.round(currentAmount)).toFixed(2)).toLocaleString('en', {
                                      minimumFractionDigits: 2
                                  })}&nbsp;
              {this.props.labels.BILLING_COMPARISON_STATEMENT_LOWER_THAN}&nbsp;
              {selectedPreviousOption[2]} &nbsp;
              {this.props.labels.BILLING_COMPARISON_BILL}
            </Typography>
          ) : null
        ) : null}

        <Link to={routePaths.USAGE} className={linkStyle}>
          <Typography className="link">
            {this.props.labels.BILLING_COMPARISON_LINK}
          </Typography>
        </Link>
      </Card>
    );
  }
}
