import React from "react";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { Typography } from "@material-ui/core";
import { appService } from "../../services/app.service";

export default function ElectricityUsageTable(props) {
  const rows = props.tableInformation;  

  let page = 0;
  let rowsPerPage = 12;

  let labels = {};
    if(appService.getLabels()){
      labels = appService.getLabels().getLabels.CONFIGURATIONS;
    }
  return (
  rows.length !== 0 ? (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
        <TableCell align="center" className={props.tableStyles.tableHeaderLabel}><b>{props.headTitle}</b></TableCell>
        <TableCell align="center" className={props.tableStyles.tableHeaderLabel}><b>Usage ({props.usageUnit})</b></TableCell>
        </TableHead>   
        
        <TableBody className={props.tableStyles.tableDataCell}>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
             row
            ))}
          
        </TableBody>  
      </Table>
      
    </TableContainer>
    ) : (<Typography align="center" color="primary" variant="h6" className="noDataFound">
      <b>{labels.NO_DATA_FOUND}</b>
  </Typography>)
  );
}
