import { withStyles} from "@material-ui/core/styles";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

export const USAGE_COMPARISON_STYLE = makeStyles((theme) =>
  createStyles({    
    primaryColor: theme.palette.primary.main,
    secondaryColor: theme.palette.secondary.main,
    themeDirection: theme.direction,
    dropdownContainer: {
      display: "flex",
      justifyContent: "center",
      color: theme.palette.secondary.main + " !important",
    },
    dropdownLabel: {
      marginRight: "10px",
      paddingTop: "5px",      
      color: theme.palette.primary.main + " !important",
      fontSize: "15px",
    },
    usageContainer: {
      display: "flex",
      justifyContent: "center",      
      color: theme.palette.secondary.main + " !important",
    },
    previousUsageContainer: {
      width: "auto",
      alignSelf: "center",
      textAlign: "center",
      padding: "0px 10px",
    },
    previousUsageComponentTop: {
      display: "",
      textAlign: "center",
      color: theme.palette.quaternary.main + " !important",
      fontWeight: "600",
      fontSize: "14px",
      marginBottom: "-10px",
    },
    previousUsageComponentTopMobile: {
      display: "",
      textAlign: "center",
      color:theme.palette.quaternary.main + " !important",
      fontWeight: "600",
      fontSize: "14px",
    },
    previousUsageComponentMiddle: {
      textAlign: "center",
      fontSize: "50px",
      fontWeight: "600",
      color: theme.palette.quaternary.main + " !important",
    },
    previousUsageComponentMiddleMobile: {
      textAlign: "center",
      fontSize: "30px",
      fontWeight: "600",
      color: theme.palette.quaternary.main + " !important",
    },
    previousUsageComponentBottom: {
      textAlign: "center",
      color: theme.palette.quaternary.main + " !important",
      fontWeight: "bold",
      fontSize: "15px",
      marginTop: "-10px",
    },
    previousUsageComponentBottomMobile: {
      textAlign: "center",
      color: theme.palette.quaternary.main + " !important",
      fontWeight: "bold",
      fontSize: "15px",
    },
    currentUsageContainer: {
      width: "auto",
      padding: "0px 10px",
    },
    currentUsageComponentTop: {
      display: "block",
      textAlign: "center",
      color: theme.palette.primary.main + " !important",
      marginBottom: "-30px",
      fontSize: "18px",
      fontWeight: "600",  
    },
    currentUsageComponentTopMobile: {
      display: "block",
      textAlign: "center",
      color: theme.palette.primary.main + " !important",
      fontSize: "18px",
      fontWeight: "600",  
    },
    currentUsageComponentMiddle: {
      fontSize: "100px",
      fontWeight: "600",
      color: theme.palette.primary.main + " !important",
      textAlign: "center",
    },
    currentUsageComponentMiddleMobile: {
      fontSize: "60px",
      fontWeight: "600",
      color: theme.palette.primary.main + " !important",
      textAlign: "center",
    },
    currentUsageComponentBottom: {
      textAlign: "center",
      color: theme.palette.primary.main + " !important",
      fontWeight: "600",
      marginTop: "-25px",
      fontSize: "20px",
    },
    currentUsageComponentBottomMobile: {
      textAlign: "center",
      color: theme.palette.primary.main + " !important",
      fontWeight: "600",
      fontSize: "20px",
    },
    arrowContainer: {
      width: "auto",
      alignSelf: "center",     
    },

    btnRightActiveState: {
      color: theme.palette.whiteColor.main,
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "0px 19px 19px 0px",
      width: "80px",
      height: "32px",
      border: "2px solid " + theme.palette.secondary.main,
      fontFamily: "sans-serif",
      fontWeight: "200",
      fontSize: "18px",
    },

    btnLeftActiveState: {
      color: theme.palette.whiteColor.main,
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "19px 0px 0px 19px",
      width: "80px",
      height: "32px",
      border: "2px solid " + theme.palette.secondary.main,
      fontFamily: "sans-serif",
      fontWeight: "200",
      fontSize: "18px",
    },

    btnLeftPassiveState: {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.whiteColor.main,
      borderRadius: "19px 0px 0px 19px",
      width: "80px",
      height: "32px",
      border: "2px solid " + theme.palette.secondary.main,
      fontFamily: "sans-serif",
      fontWeight: "200",
      fontSize: "18px",
    },

    btnRightPassiveState: {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.whiteColor.main,
      borderRadius: "0px 19px 19px 0px",
      width: "80px",
      height: "32px",
      border: "2px solid " + theme.palette.secondary.main,
      fontFamily: "sans-serif",
      fontWeight: "200",
      fontSize: "18px",
    },
    arrowUpwardSharpIcon: {
      color: theme.palette.usageNegativeColor.main,
      fontSize: "65px",
    },
    arrowDownwardSharpIcon: {
      color: theme.palette.usagePositiveColor.main,
      fontSize: "65px",
    }    
  })
);

export const STYLED_SELECT = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main + " !important",
    fontSize: "15px",
    cursor:"pointer"
  },
  icon: {
    color: theme.palette.secondary.main + " !important",
  }  
}))(Select);

export const STYLED_MENU_ITEM = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main + " !important",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: theme.palette.quaternary.main + " !important",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.primary.main
      }
    },
    "&:focus": {
      backgroundColor: theme.palette.quaternary.main + " !important",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.primary.main
      }
    }
  }, 
}))(MenuItem);
