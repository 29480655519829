import React from "react";
import { PaymentConfirmationComponent } from "./payment-confirmation";
import "../../app/base-style.css";
import { usageService } from "../../../services/index";
import { store } from "../../../helpers/store";
import { connect } from "react-redux";
import { appService } from "../../../services/app.service";
import { InitiatePaymentService } from "../multiple-payment/initiate-payment-service";
import { routePaths } from "../../config/route-paths";
import { history } from "../../../helpers/history";
import { Card, CardContent } from "@material-ui/core";
import { PaymentConfirmationDebitCard } from "../payment-confirmation-debitCard/payment-confirmation-debitCard";
import Skeleton from "@material-ui/lab/Skeleton";
import { accountDetailsWithApiCall } from "../../../actions/home.action";
import { Typography } from "@material-ui/core";
import { PaymentConfirmationCreditCard } from "../payment-confirmation-creditCard/payment-confirmation-creditCard";
import { PaymentConfirmationSkipCash } from "../payment-confirmation-debitCard/payment-confirmation-skipcash";
let creditCardGatewayCode = "";
let nodataLabel = {};
store.subscribe(() => {
  if (store.getState().parametersWidgets.ParameterLookup) {
    creditCardGatewayCode = store
      .getState()
      .parametersWidgets.ParameterLookup.PAYMENT_GATEWAY_CODE.split(",")[2];
  }
});

class PaymentConfirmation extends React.Component {
  constructor(props) {
    super();
    this.state = {
      response: {},
      contentRes: null,
      patmentStatus: {},
      result: null,
      location: null,
      PaymentConnections: [],
      error: null,
    };
  }

  componentDidMount() {
    nodataLabel = {};
    InitiatePaymentService.getContent().then(
      (response) => {
        this.setState({
          contentRes: response.data.getContents.INITIATE_PAYMENT,
        });
        this.setState({
          waitContent: response.data.getContents.INITIATE_PAYMENT.find(
            (item) => item.contentCode === "PAYMENT_WAIT_MESSAGE"
          ),
        });
      },
      (error) => {}
    );
    if (appService.getLabels()) {
      nodataLabel = appService.getLabels().getLabels.CONFIGURATIONS;
    }
    store.getState().snackbar = true;
    let resultIndicator = new URLSearchParams(window.location.search).get(
      "resultIndicator"
    );
    let bearer = localStorage.getItem("bearer");
    let emailId = "";
    let userName = "";
    let session = "";

    if (bearer) {
      bearer = JSON.parse(bearer);
      emailId = bearer.email;
      userName = bearer.userName;
      session = bearer.sessionId;
    }
    let PaymentConnections = localStorage.getItem("PaymentConnections");
    let creditCardGatewayCode = localStorage.getItem("creditCardGatewayCode");
    let billId = localStorage.getItem("billID");
    if (resultIndicator && creditCardGatewayCode === "MiGS_Gateway") {
      if (billId) {
        let orderId = localStorage.getItem("orderId");
        let accountId = localStorage.getItem("accountId");
        InitiatePaymentService.postPaymentProcessingBillPay(
          billId,
          userName,
          emailId,
          "",
          "",
          session,
          creditCardGatewayCode,
          resultIndicator,
          accountId,
          orderId
        ).then(
          (res) => {
            this.setState({
              response: res,
              location: "billpay",
            });
            store.getState().snackbar = false;
            this.props.accountDetailsWithApiCall();
          },
          (error) => {
            if (error?.response?.status === 400) {
              if (error?.response?.data?.message) {
                let errorCode = error.response.data.message;
                if (errorCode == "ORDER_NOT_FOUND") {
                  this.setState({ error: "Your order does not found" });
                } else if (errorCode == "PROCESSING") {
                  this.setState({
                    error:
                      "Your payment has being processed, We will try to process your transaction in a while",
                  });
                } else if (errorCode == "INVALID_GATEWAY_CODE") {
                  this.setState({
                    error:
                      "Invalid Gateway Code, Please check your balance after sometime",
                  });
                } else if (errorCode == "ORDER_ID_MISSING") {
                  this.setState({
                    error:
                      "Order id is missing, Please check your balance after sometime",
                  });
                } else if (errorCode == "INPUT_VALIDATION_ERROR") {
                  this.setState({
                    error:
                      "Your payment has being processed, Please check your balance after sometime",
                  });
                } else {
                  this.setState({
                    error: errorCode
                      ? errorCode
                      : "Error occurred: We will try to process your transaction in a while",
                  });
                }
              }
            } else {
              this.setState({
                error:
                  "Error occurred: We will try to process your transaction in a while",
              });
            }
            return (
              <div>
                <p>Failed to Load the Resource</p>
              </div>
            );
          }
        );
        localStorage.removeItem("accountId");
        localStorage.removeItem("orderId");
        localStorage.removeItem("billID");
        localStorage.removeItem("PaymentConnections");
      } else if (PaymentConnections) {
        this.setState({
          PaymentConnections,
        });
        InitiatePaymentService.postPaymentProcessing(
          creditCardGatewayCode,
          resultIndicator,
          emailId,
          userName
        ).then(
          (res) => {
            if (res.status === 500) {
              history.push(routePaths.HOME);
            } else {
              this.setState({
                response: res.data.data.postPaymentProcessing,
                location: "payment",
              });
            }
            store.getState().snackbar = false;
            this.props.accountDetailsWithApiCall();
          },
          (error) => {
            if (error?.response?.status === 400) {
              if (error?.response?.data?.errors?.[0]?.message) {
                let errorCode = error.response.data.errors[0].message;
                if (errorCode == "ORDER_NOT_FOUND") {
                  this.setState({ error: "Your order does not found" });
                } else if (errorCode == "PROCESSING") {
                  this.setState({
                    error:
                      "Your payment has being processed, Please check your balance after sometime",
                  });
                } else if (errorCode == "INVALID_GATEWAY_CODE") {
                  this.setState({
                    error:
                      "Invalid Gateway Code, Please check your balance after sometime",
                  });
                } else if (errorCode == "ORDER_ID_MISSING") {
                  this.setState({
                    error:
                      "Order id is missing, Please check your balance after sometime",
                  });
                } else if (errorCode == "INPUT_VALIDATION_ERROR") {
                  this.setState({
                    error:
                      "Your payment has being processed, Please check your balance after sometime",
                  });
                } else {
                  this.setState({
                    error: errorCode
                      ? errorCode
                      : "Error occurred: We will try to process your transaction in a while",
                  });
                }
              }
            } else {
              this.setState({
                error:
                  "Error occurred: We will try to process your transaction in a while",
              });
            }
            return (
              <div>
                <p>Failed to Load the Resource</p>
              </div>
            );
          }
        );
        localStorage.removeItem("accountId");
        localStorage.removeItem("orderId");
        localStorage.removeItem("billID");
        localStorage.removeItem("PaymentConnections");
      } else {
        localStorage.removeItem("accountId");
        localStorage.removeItem("orderId");
        localStorage.removeItem("billID");
        localStorage.removeItem("PaymentConnections");
        history.push(routePaths.HOME);
      }
    } else {
      // history.push(routePaths.HOME);
    }
  }

  render() {
    let resultIndicator = new URLSearchParams(window.location.search).get(
      "resultIndicator"
    );
    let decision = new URLSearchParams(window.location.search).get("decision");
    let statusId = new URLSearchParams(window.location.search).get("statusId");
    let creditCardGatewayCode = localStorage.getItem("creditCardGatewayCode");

    return (
      <Card className="card">
        <CardContent>
          {resultIndicator && creditCardGatewayCode === "MiGS_Gateway" ? (
            <div>
              {this.state.response && this.state.response.mainData ? (
                <PaymentConfirmationComponent response={this.state} />
              ) : (
                <Typography
                  align="center"
                  color="primary"
                  variant="h6"
                  className="noDataFound"
                >
                  {this.state.error
                    ? this.state.error
                    : this.state.waitContent
                    ? this.state.waitContent.answer
                    : null}
                </Typography>
              )}
            </div>
          ) : decision && creditCardGatewayCode === "CyberSource_Gateway" ? (
            <PaymentConfirmationCreditCard contentRes={this.state.contentRes} />
          ) : statusId && creditCardGatewayCode === "SkipCash_Gateway" ? (
            <PaymentConfirmationSkipCash contentRes={this.state.contentRes} />
          ) : this.state.contentRes ? (
            <PaymentConfirmationDebitCard contentRes={this.state.contentRes} />
          ) : (
            <Typography>
              <Skeleton variant="text" className="heightSkeleton" />
              <Skeleton animation="wave" className="heightSkeleton" />
              <Skeleton variant="text" className="heightSkeleton" />
              <Skeleton animation="wave" className="heightSkeleton" />
            </Typography>
          )}
        </CardContent>
      </Card>
    );
  }
}

const mapDispachToProps = {
  accountDetailsWithApiCall,
};
const connectedApp = connect(null, mapDispachToProps)(PaymentConfirmation);
export { connectedApp as PaymentConfirmation };
