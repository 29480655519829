import { withStyles, makeStyles,  createStyles} from "@material-ui/core/styles";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";

export const customStyles = makeStyles(theme => createStyles({  
  chartLabels : {
    boxWidth: 8,
    fontColor:theme.palette.secondary.main + " !important",
    fontSize:15,
  },
  headingContainer: {
    textAlign: "center",
    clear: "both"
  },
  headingLeftContainer: {
    color: theme.palette.primary.main + " !important",
    fontSize: "18px",
  },
  tableHeaderLabel: {
    color: theme.palette.primary.main,
    fontSize: "15px",
  },
  textAlignCenter : {
    textAlign: "center"
  },
  textAlignRight : {
    textAlign: "right"
  },
  textAlignLeft : {
    textAlign: "left"
  },
  padding10:{
    padding: "10px"
  },
  flexRow:{
    width: "80%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
  }, 
  flexColumn:{
    width: "80%",
    display: "flex",
    justifyContent: "center",
    flexDirection:  "column",
  }, 
  whiteSpace:{
    whiteSpace: "nowrap"
  }, 
  tableDataCell: {
    fontSize: "14px",
  },
  rootPanel: {
    boxShadow:"none",
  },
  rootPanelSummary: {
    width:"180px", 
    left:"calc(50% - 90px)",
    color: theme.palette.secondary.main,
  },
  rootPanelSummaryIcon: {
    color: theme.palette.secondary.main,
  },
  listItem1:{
    color:theme.palette.quaternary.main+" !important",   
  },
  listItem2:{
    color:theme.palette.quaternary.main+" !important",    
    whiteSpace: "normal",         
    paddingBottom: "10px"
  }
}));

export const customSelectStyles =  makeStyles(theme => createStyles({
  control: (styles, state) => ({
    ...styles,
    border: "1px solid " + theme.palette.secondary.main,
    width: "auto",
    minWidth: "110px",
    boxShadow: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    border: "0px solid red",  
    color: theme.palette.secondary.main,
  }),
  dropdownIndicator: (base, state) => ({
    color:  theme.palette.secondary.main,
    padding: "10px",
  }),
  singleValue: (provided, state) => { 
 const opacity =  1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
}));

export const STYLED_SELECT = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main + " !important",
    fontSize: "15px",    
    border: "1px solid " + theme.palette.secondary.main + " !important",
    borderRadius: "4px",      
    padding: "7px 10px",
  },
  select: {
    "&:focus": {
      borderRadius: "4px",
    }
  },
  icon: {
    color: theme.palette.secondary.main + " !important",
  }, 
}))(Select);

export const STYLED_MENU_ITEM = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main + " !important",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: theme.palette.quinary.main + " !important",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.primary.main,
      },
    },
    "&:focus": {
      backgroundColor: theme.palette.quinary.main + " !important",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.primary.main,
      },
    },
  }, 
}))(MenuItem);

export const STYLED_BORDER_LESS_SELECT = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main + " !important",
    fontSize: "15px",    
    cursor:"pointer"
  },
  icon: {
    color: theme.palette.secondary.main + " !important",
  },
 
}))(Select);

