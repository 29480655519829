import axios from "axios";
import { config } from "../../enviroment";
import { history } from "../../helpers/index";
import { routePaths } from "../config/route-paths";

type BillingHistoryResponse = {
  dateInput: string,
  periodInput: BigInteger,
  accountID: string,
  connectionID: string,
};
export const billingHistoryService = {
  gettingData: getBillingHistoryDetails,
  gettingLinks: gettingLinksDataDetails,
  gettingDropdown: getBillingPeriods,
};

async function getBillingHistoryDetails(
  dateInput: string,
  accountID: string,
  startDate: string
): Promise<BillingHistoryResponse> {
  let bearer = localStorage.getItem("bearer");
  let personId = "";
  if (bearer) {
    bearer = JSON.parse(bearer);
    personId = bearer.personId;
  }
  let APIquery =
    `{getBillPayHistory(input:{
    endDate:"` +
    dateInput +
    `",
    startDate:"` +
    startDate +
    `"}){financialBillHistory}}`;
  return await new Promise(async (resolve, reject) => {
    await axios({
      url: config.urls.USAGE_SERVICE_BASE_URL,
      method: "post",
      data: {
        query: APIquery,
      },
      headers: {
        accountId: accountID,
          },
    })
      .then(function(response) {
        resolve(response.data);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
async function gettingLinksDataDetails() {
  let APIquery = `{
    getContents(input:{categoryCode:"BILLS_AND_PAYMENTS"})
  }`;
  return await new Promise(async (resolve, reject) => {
    await axios({
      url: config.urls.ADMIN_SERVICE_BASE_URL,
      method: "post",
      data: {
        query: APIquery,
      },
    })
      .then(function(response) {
        if(response){
        resolve(response.data);
        }else{
          gettingLinksDataDetails();
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
async function getBillingPeriods() {
  let APIQuery = `{
    usageBill:getDropdownData(input:{groupCode: "USAGE_BILLS"})
    records:getDropdownData(input:{groupCode: "RECORDS_BILL_PAYMENT"})
    }`;
  return await new Promise(async (resolve, reject) => {
    await axios({
      url: config.urls.ADMIN_SERVICE_BASE_URL,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: {
        query: APIQuery,
      },
    })
      .then(function(response) {
        let getRetryPage = localStorage.getItem("getRetryPage");
        if (getRetryPage && getRetryPage == "true") {
          history.push(routePaths.RETRY);
        }
        resolve(response.data);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

export default billingHistoryService;
