import { usageConstants } from "../constants/usage.constants";

export default {
    updateExternalMeterDetail,
    updateUsageUserData
}
function updateExternalMeterDetail(meterInformation) {   
    return dispatch => {
        dispatch(dispatchFormat(usageConstants.USAGE_METER_DETAIL, meterInformation))
    }
}
function updateUsageUserData(userData) {       
    return dispatch => {
        dispatch(dispatchFormat(usageConstants.USAGE_USER_DATA, userData))
    }
}
function dispatchFormat(actionType, snack) {
    return { type: actionType, snack };
}