export const appConstants = {
  PARAMETER_WIDGET_SUCCESS: "PARAMETER_WIDGET_SUCCESS",
  PARAMETER_WIDGET_FAILURE: "PARAMETER_WIDGET_FAILURE",

  SNACKBAR_SUCCESS: "SNACKBAR_SUCCESS",
  SNACKBAR_FAILURE: "SNACKBAR_FAILURE",
  PROFILE_DETAILS_SUCESS: "PROFILE_DETAILS_SUCESS",
  PROFILE_DETAILS_ERROR: "PROFILE_DETAILS_ERROR",
  ACCOUNT_DETAILS_SUCESS: "ACCOUNT_DETAILS_SUCESS",
  ACCOUNT_DETAILS_ERROR: "ACCOUNT_DETAILS_ERROR",
  UPDATE_OR_ADD_METER_DETAILS: "UPDATE_OR_ADD_METER_DETAILS",

  GET_CATEGORY:"GET_TICKET_CATEGORY_SUCCESS",
  APPLOGO_SUCCESS : "APPLOGO_SUCCESS",
  APPLOGO_FAILURE:"APPLOGO_FAILURE",

  PROFILE_ICON_SUCCESS :"PROFILE_ICON_SUCCESS",
  PROFILE_ICON_FAILURE:"PROFILE_ICON_FAILURE",

  PROFILE_DARK_ICON_SUCCESS :"PROFILE_DARK_ICON_SUCCESS",
  PROFILE_DARK_ICON_FAILURE:"PROFILE_DARK_ICON_FAILURE",

  GET_CONNECTION_SUCCESS :"GET_CONNECTION_SUCCESS",
  PASSWORD_UPDATE_SUCCESS: "PASSWORD_UPDATE_SUCCESS"
};
