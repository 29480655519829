import React from "react";
import SearchStyle from "./search-style";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import "../app/base-style.css";
import "./self-help.css";
import { useMediaQuery } from "react-responsive";
import { appService } from "../../services/app.service";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { routePaths } from "../config/route-paths";

const Backlink = (props) => {
  let labels = {};

  if (appService.getLabels()) {
    labels = appService.getLabels().getLabels.CONFIGURATIONS;
  }
  const classes = SearchStyle();
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 768px)" });
  const history = useHistory();
  const backCall = () => {
    if(props.backToHome){
      history.push(routePaths.HOME)
    } else {
      history.length >= 1 ? history.goBack() : history.push(routePaths.HOME);
    }
    
  };
  return (
    <div>
      {isTabletOrMobile ? (
        <div className="float-left">
          <Button
            className={classes.primaryColor}
            size="small"
            startIcon={
              <ArrowBackIosIcon fontSize="small" className="marginRight" />
            }
            onClick={backCall}
          >
            <b>{labels.BACK_BUTTON}</b>
          </Button>
        </div>
      ) : (
        <Button
          className={classes.primaryColorSF}
          size="small"
          startIcon={<ArrowBackIosIcon fontSize="small" />}
          onClick={backCall}
        >
          <b>{labels.BACK_BUTTON}</b>
        </Button>
      )}
    </div>
  );
};

export default Backlink;
