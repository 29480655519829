// @flow

import React from "react";
import { Router } from "react-router-dom";
import { history } from "../../helpers/index";
import { createMuiTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Layout } from "../layout/layout-component.jsx";
import { ThemeProvider } from "@material-ui/core/styles";
import { appStyles } from "./app-style";
import setupAxiosInterceptors from "./axios-setup";
import { commonService } from "../../services/common.service";
import CssBaseline from "@material-ui/core/CssBaseline";
import "../app/base-style.css";
import {
  userDetails,updateMeterDetails
} from "./../../actions/home.action";
import NeoSansBold from "../../assests/fonts/neo-sans-bold.woff2";
import NeoSansLight from "../../assests/fonts/neo-sans-light.woff2";
import NeoSansRegular from "../../assests/fonts/neo-sans-regular.woff2";
import ChatWidget from "../chat-widget/chat.widget";
import { getAppReady_Labels } from "./../../actions/language.actions";
import { getAppReady_widget } from "./../../actions/app.actions";

type AppProps = {
  getAppReady_widget: () => Function,
  getAppReady_Labels: () => Function,
  getParams: () => Function,
  userDetails: () => Function,
  accountDetails: () => Function,
  store: any,
};

type AppState = {
  theme: any,
};

const neoSansRegular = {
  fontFamily: "NeoSansRegular",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `
    local('NeoSansRegular'),    
    url(${NeoSansRegular}) format('woff2')
  `,
};

const neoSansLight = {
  fontFamily: "NeoSansLight",
  fontStyle: "light",
  fontDisplay: "swap",
  src: `
    local('NeoSansLight'),    
    url(${NeoSansLight}) format('woff2')
  `,
};

const neoSansBold = {
  fontFamily: "NeoSansBold",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('NeoSansBold'),    
    url(${NeoSansBold}) format('woff2')
  `,
};

class App extends React.Component<AppProps, AppState> {
  constructor(props) {
    ChatWidget();
    super(props);

    function noop() { }
    if (process.env.NODE_ENV !== 'development') {
      console.log = noop;
      console.warn = noop;
      console.error = noop;
    }
    let bearer = localStorage.getItem("bearer");
    if (bearer === null) {
      commonService.logoutUser();
    }
    if(bearer){
    setupAxiosInterceptors();
      this.props.getAppReady_widget();
      this.props.getAppReady_Labels();
      this.props.userDetails();
    }
    this.state = {
      theme: appStyles.outerTheme,
    };
  }

  render() {
    //subscribing store to observe changes
    this.props.store.subscribe(() => {
      const state = this.props.store.getState();
      if (state.parametersWidgets.ParameterLookup) {
        const theme = createMuiTheme({
          palette: {
            primary: {
              main: state.parametersWidgets.ParameterLookup.PRIMARY_COLOR,
            },
            secondary: {
              main: state.parametersWidgets.ParameterLookup.SECONDARY_COLOR,
            },
            tertiary: {
              main: state.parametersWidgets.ParameterLookup.TERTIARY_COLOR,
            },
            quaternary: {
              main: state.parametersWidgets.ParameterLookup.QUATERNARY_COLOR,
            },
            quinary: {
              main: state.parametersWidgets.ParameterLookup.QUINARY_COLOR,
            },
            whiteColor: {
              main: "#FFFFFF",
            },
            blackColor: {
              main: "#000000",
            },
            usageNegativeColor: {
              main: "#FF0000",
            },
            usagePositiveColor: {
              main: "#01B61E",
            },
            background: {
              default: state.parametersWidgets.ParameterLookup.QUINARY_COLOR,
            }
          },
          typography: {
            fontFamily: "NeoSansRegular, NeoSansLight, NeoSansBold",
          },
          overrides: {
            MuiCssBaseline: {
              "@global": {
                "@font-face": [neoSansRegular, neoSansLight, neoSansBold],
              },
            },
          },
        });

        this.setState({
          theme: theme,
        });
      }
    });
    return (
      <ThemeProvider theme={this.state.theme}>
        <CssBaseline />
        {/* TODO: Change change-coded ltr/rtl and take from language type */}
        <div
          className="back-banner"
          style={{ backgroundColor: this.state.theme.palette.primary.main }}
        />
        <div className="bottom-bar"
          style={{ backgroundColor: this.state.theme.palette.quinary.main }}
        />
        <div className="stack-top" dir="ltr">
          <Router history={history}>
            <Layout history={history} store={this.props.store} />
          </Router>
        </div>
      </ThemeProvider>
    );
  }
}

const actionCreators = {
  userDetails,
  updateMeterDetails,
  getAppReady_widget,
  getAppReady_Labels
};
const connectedApp = connect(null, actionCreators)(App);
export { connectedApp as App };
