import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.background.default,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.primary.main
      }
    }
  },
}))(MenuItem);

export const useStyles = makeStyles((theme) => ({
  sideMenuItem: {
    textAlign: "center",
    fontSize: "small",
    color: theme.palette.primary.main,
    marginTop: "10px",
    width: "100%",
    cursor: "pointer"
  },
  listitemCls: {
    marginTop: "-20px",
  },
  textdecnon: {
    textDecoration: "none !important",
  },
}));
