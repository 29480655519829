import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { store } from "../../helpers/store"

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed !important',
    zIndex: '1',
    marginTop: '-65px'
  },
});
export default function LinearDeterminate(props) {
    const classes = useStyles();
    const [progress, setProgress] = React.useState(0);
    let progressLoad = store.getState().snackbar;
    
    React.useEffect(() => {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0;
          }
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500);
  
      return () => {
        clearInterval(timer);
      };
    }, []);
    return (
        progressLoad ? (
        <div className={classes.root}>
          <LinearProgress variant="determinate" value={progress} />
        </div>
        ) : null
      );
    }