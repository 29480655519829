import axios from "axios";
import { config } from "../../enviroment";

export const selfHelpService = {
  selfHelp: getSelfHelpData,
};

async function getSelfHelpData(languageCode: string ,categoryCode : string) {
  return new Promise((resolve, reject) => {
    axios
      .post(config.urls.ADMIN_SERVICE_BASE_URL, {
        query:
        `{getContents(input:{languageCode:"`
        +languageCode+
        `",categoryCode:"`
        +categoryCode+
        `"})}` 
       
      })
      .then(function(response) {
        resolve(response.data.data.getContents);
       
      })
      .catch(function(error) {
        reject(error);
      });
  });
}



