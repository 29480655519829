import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import "./usage.css";
import "../app/base-style";
import { ElectricityUsage } from "./electricity-usage-graph";
import { store } from "../../helpers/store";
import { history } from "../../helpers";
import { routePaths } from "../config/route-paths";
import usageActions from "../../actions/usage.actions";
import "../app/base-style.css";

const getSortOrder = (prop) => {
  return (a, b) => {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
};

let usageWidgets = [];


function Usage(props) {
  const widgets = store.getState().parametersWidgets.WidgetLookUp;
  const accountDetails = store.getState().accountInformation.accountDetails;

  if (accountDetails === undefined || accountDetails.length === 0) {
    // history.push(routePaths.USAGE);
  } else {
    if (widgets) {
      usageWidgets = widgets.filter((item) => {
        return (
          item.widgetCode === "SIDEBAR.USAGE" &&
          item.widgetDefaultValue === "true"
        );
      });
      if (usageWidgets.length > 0) {
        usageWidgets = usageWidgets[0].children.sort(
          getSortOrder("widgetRank")
        );
      } else {
        history.push(routePaths.HOME);
      }
    }
  }
  return (
    <div className="dividerHorizontal">
      <Grid container spacing={2}>
        {usageWidgets
          ? usageWidgets.map((widget) => {
              return  widget.widgetCode === "USAGE.USAGE_TREND" &&
              widget.widgetDefaultValue === "true" ? (
              <Grid item xs={12} sm={12}>
                <ElectricityUsage store={store} usageProps={props} />
              </Grid>
            ) : null;
            })
          : null}
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return state;
};
const mapDispachToProps = {
  updateExternalMeterDetail: usageActions.updateExternalMeterDetail,
  updateUsageUserData: usageActions.updateUsageUserData,
};
export default connect(mapStateToProps, mapDispachToProps)(Usage);
