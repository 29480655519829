import { Button, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useStyles } from "../../app/base-style";
import "./retry.css";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import { commonService } from "../../../services/common.service";
import { appService } from "../../../services/app.service";
import { store } from "../../../helpers/store";
import { adminService } from "../../../services/admin.service";

function Retry(props) {
  const classes = useStyles();
  let labels = {};
  let label = {};
  let lastContent =
    '<span> Let us know at <a href="mailto:inquiry@qatarcool.com" class="***">inquiry@qatarcool.com</a> if you are seeing this again.</span>';
  const [appLogo, setAppLogo] = React.useState(null);
  const [count, setCount] = React.useState(0);

  useEffect(() => {
    let FirstHit = localStorage.getItem("retry_cmp");
    if (FirstHit) {
      setCount(count + 1);
    }
    localStorage.getItem("getRetryPage");
    if (localStorage.getItem("getRetryPage") === "false") {
      window.history.back();
    }
  }, []);
  if (appService.getLabels()) {
    labels = appService.getLabels().getLabels.SERVER_EXCEPTIONS;
  }
  if (appService.getLabels()) {
    label = appService.getLabels().getLabels.HEADER;
  }
  if (!appLogo && store.getState().parametersWidgets.ParameterLookup) {
    commonService
      .getAssestUrl(
        store.getState().parametersWidgets.ParameterLookup.TENANT_APP_LOGO
      )
      .then((response) => {
        setAppLogo(response);
      });
  }

  const anchorRef = React.useRef(null);
  const logout = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    commonService.logoutUser();
  };
  if (
    lastContent ===
    '<span> Let us know at <a href="mailto:inquiry@qatarcool.com" class="***">inquiry@qatarcool.com</a> if you are seeing this again.</span>'
  ) {
    lastContent = lastContent.replace("***", "link");
  }
  const reloadPage = () => {
    let retry_cmp = localStorage.getItem("retry_cmp");
    if (retry_cmp) {
      setCount(count + 1);
      window.history.back();
    } else {
      localStorage.setItem("retry_cmp", 1);
      window.history.back();
      if (localStorage.getItem("getRetryPage") === "false") {
        window.history.back();
      }
    }
  };
  return (
    <div className="div">
      <div className="card-style">
        <div style={{ marginTop: "5%" }}>
          <div className={classes.whiteColor} align="center">
            <CloudDownloadOutlinedIcon className="icons" />
            <Typography className={classes.whiteColor} align="center">
              <ClearOutlinedIcon
                style={{ color: "#F14F3F", fontSize: "3rem" }}
              />{" "}
              <br />
            </Typography>
          </div>
          <Typography
            className={classes.whiteColor}
            style={{ fontSize: "2rem" }}
            align="center"
          >
            <b>
              {" "}
              {labels?.SERVER_EXCEPTIONS_NOT_RIGHT
                ? labels.SERVER_EXCEPTIONS_NOT_RIGHT
                : "Something is not right here ..."}{" "}
            </b>
          </Typography>
          <br />
          <div align="center">
            <Button
              onClick={reloadPage}
              variant="contained"
              color="secondary"
              className={classes.whiteColor}
            >
              {labels?.SERVER_EXCEPTIONS_RELOAD
                ? labels.SERVER_EXCEPTIONS_RELOAD
                : "Reload Page"}
            </Button>
            <br />
            <br />
            {count && count > 0 ? (
              <span className={classes.whiteColor} align="center">
                {labels.length > 0 ? (
                  <Typography className={classes.whiteColor} align="center">
                    {labels.SERVER_EXCEPTIONS_TRY}
                    <Button onClick={logout} className="link">
                      {label.PROFILE_MENU_OPTION_LOGOUT}
                    </Button>
                    {labels.SERVER_EXCEPTIONS_LOGIN}
                  </Typography>
                ) : (
                  <Typography>
                    Alternatively you can try to{" "}
                    <Button onClick={logout} className="link">
                      logout
                    </Button>
                    and then login again.
                  </Typography>
                )}
              </span>
            ) : null}
            <br />
            {count && count > 0 ? (
              <span className={classes.whiteColor} align="center">
                {props.contentData ? (
                  <Typography
                    className={classes.whiteColor}
                    align="center"
                    dangerouslySetInnerHTML={{
                      __html: props.contentData,
                    }}
                  />
                ) : (
                  <Typography>
                    Let us know at{" "}
                    <a href="mailto:inquiry@qatarcool.com" className="link">
                      inquiry@qatarcool.com
                    </a>{" "}
                    if you are seeing this again.
                  </Typography>
                )}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default class RetryMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      contentData: "",
    };
  }
  componentDidMount() {
    adminService.getContentData("en", "SERVER_EXCEPTIONS").then((res) => {
      this.setState({
        data: res.data.getContents.SERVER_EXCEPTIONS,
      });
      res.data.getContents.SERVER_EXCEPTIONS.forEach((item) => {
        if (item.contentCode === "MAIL_PARAGRAPH") {
          this.setState({
            contentData: item.answer.replace("***", "link"),
          });
        }
      });
    });
  }

  render() {
    let { data, contentData } = this.state;
    return (
      <React.Fragment>
        <Retry data={data} contentData={contentData} />
      </React.Fragment>
    );
  }
}
