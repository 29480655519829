import React from "react";
import { InitiatePaymentService } from "../multiple-payment/initiate-payment-service";
import { store } from "../../../helpers/store";
import { connect } from "react-redux";
import { history } from "../../../helpers/index";
import { routePaths } from "../../config/route-paths";
import { appService } from "../../../services/app.service";
import { PaymentConfirmationComponent } from "../payment-confirmation/payment-confirmation";
import { accountDetailsWithApiCall } from "../../../actions/home.action";
import { Typography } from "@material-ui/core";

let nodataLabel = {};
if (appService.getLabels()) {
  nodataLabel = appService.getLabels().getLabels.CONFIGURATIONS;
}
class PaymentConfirmationDebitCard extends React.Component {
  constructor(props) {
    super();
    this.state = {
      response: {},
      location: null,
      contentRes: "",
      waitContent:"",
      error: null,
    };
  }
  componentDidMount() {
    if (this.props.contentRes) {
      this.setState({
        contentRes: this.props.contentRes,
      });
      this.setState({
          waitContent: (this.props.contentRes.find(
            (item) => item.contentCode === "PAYMENT_WAIT_MESSAGE"
          ))})
    }
    let creditCardGatewayCode = localStorage.getItem("creditCardGatewayCode");
    let connectionId = [];

    if (localStorage.getItem("PaymentConnections")) {
      let PaymentConnections = JSON.parse(
        localStorage.getItem("PaymentConnections")
      );
      PaymentConnections.forEach((element) => {
        connectionId.push(element.ConnectionId);
      });
    }
    let Response_Amount = new URLSearchParams(window.location.search).get(
      "Response.Amount"
    );
    let Response_CurrencyCode = new URLSearchParams(window.location.search).get(
      "Response.CurrencyCode"
    );
    let Response_PUN = new URLSearchParams(window.location.search).get(
      "Response.PUN"
    );
    let Response_EZConnectResponseDate = new URLSearchParams(
      window.location.search
    ).get("Response.EZConnectResponseDate");
    let Response_ConfirmationID = new URLSearchParams(
      window.location.search
    ).get("Response.ConfirmationID");
    let Response_MerchantModuleSessionID = new URLSearchParams(
      window.location.search
    ).get("Response.MerchantModuleSessionID");
    let Response_Status = new URLSearchParams(window.location.search).get(
      "Response.Status"
    );
    let Response_StatusMessage = new URLSearchParams(window.location.search)
      .get("Response.StatusMessage")
      .replaceAll(" ", "+");
    let Response_MerchantID = new URLSearchParams(window.location.search).get(
      "Response.MerchantID"
    );
    let Response_BankID = new URLSearchParams(window.location.search).get(
      "Response.BankID"
    );
    let Response_Lang = new URLSearchParams(window.location.search).get(
      "Response.Lang"
    );
    let Response_AcquirerID =
      new URLSearchParams(window.location.search).get("Response.AcquirerID") ||
      " ";
    let Response_CardNumber =
      new URLSearchParams(window.location.search).get("Response.CardNumber") ;

    let Response_CardExpiryDate =
      new URLSearchParams(window.location.search).get(
        "Response.CardExpiryDate"
      ) ;
    let Response_CardHolderName =
      new URLSearchParams(window.location.search).get(
        "Response.CardHolderName"
      ) || " ";
    let Response_SecureHash = new URLSearchParams(window.location.search).get(
      "Response.SecureHash"
    );
    let Response_AgentID = new URLSearchParams(window.location.search)
      .get("Response.AgentID")
      ?.replace(null, "");
    let billId = localStorage.getItem("billID");

    if (Response_CardNumber && Response_CardExpiryDate) {
      if (billId) {
        let accountId = localStorage.getItem("accountId");
        InitiatePaymentService.postPaymentProcessingDebitBillPay(
          creditCardGatewayCode,
          Response_Amount,
          Response_BankID,
          Response_CurrencyCode,
          Response_EZConnectResponseDate,
          Response_Lang,
          Response_MerchantID,
          Response_MerchantModuleSessionID,
          Response_PUN,
          Response_SecureHash,
          billId,
          Response_ConfirmationID,
          Response_Status,
          Response_StatusMessage,
          Response_AcquirerID,
          Response_CardNumber,
          Response_CardExpiryDate,
          Response_CardHolderName,
          Response_AgentID,
          accountId
        ).then(
          (res) => {
            this.setState({
              response: res,
              location: "billpay",
            });
            store.getState().snackbar = false;
            this.props.accountDetailsWithApiCall();
          },
          (error) => {
            if(error?.response?.status === 400) {
              if(error?.response?.data?.message) {
                let errorCode = error.response.data.message;
                if(errorCode == "ORDER_NOT_FOUND") {
                  this.setState({ error: "Your order does not found" })
                }else if(errorCode == "INVALID_GATEWAY_CODE") {
                  this.setState({ error: "Invalid Gateway Code" })
                } else if(errorCode == "ORDER_ID_MISSING") {
                  this.setState({ error: "Order id is missing" })
                } else {
                  this.setState({ error: errorCode ? errorCode : "Error occurred: Transaction not successful" })
                }
              }
            } else {
              this.setState({ error: "Error occurred: Transaction not successful" })
            }
          }
        );
        localStorage.removeItem("accountId");
        localStorage.removeItem("orderId");
        localStorage.removeItem("billID");
        localStorage.removeItem("PaymentConnections");
      } else {
        InitiatePaymentService.postPaymentProcessingDebitPay(
          creditCardGatewayCode,
          Response_Amount,
          Response_BankID,
          Response_CurrencyCode,
          Response_EZConnectResponseDate,
          Response_Lang,
          Response_MerchantID,
          Response_MerchantModuleSessionID,
          Response_PUN,
          Response_SecureHash,
          Response_ConfirmationID,
          Response_Status,
          Response_StatusMessage,
          Response_AcquirerID,
          Response_CardNumber,
          Response_CardExpiryDate,
          Response_CardHolderName,
          Response_AgentID
        ).then(
          (res) => {
            this.setState({
              response: res.data.data.postPaymentProcessing,
              location: "payment",
            });
            store.getState().snackbar = false;
            this.props.accountDetailsWithApiCall();
          },
          (error) => {
            if(error?.response?.status === 400) {
              if(error?.response?.data?.errors?.[0]?.message) {
                let errorCode = error.response.data.errors[0].message;
                if(errorCode == "ORDER_NOT_FOUND") {
                  this.setState({ error: "Your order does not found" })
                }else if(errorCode == "INVALID_GATEWAY_CODE") {
                  this.setState({ error: "Invalid Gateway Code" })
                } else if(errorCode == "ORDER_ID_MISSING") {
                  this.setState({ error: "Order id is missing" })
                } else {
                  this.setState({ error: errorCode ? errorCode : "Error occurred: Transaction not successful" })
                }
              }
            } else {
              this.setState({ error: "Error occurred: Transaction not successful" })
            }
          }
        );
        localStorage.removeItem("accountId");
        localStorage.removeItem("orderId");
        localStorage.removeItem("billID");
        localStorage.removeItem("PaymentConnections");
      }
    } else {
      localStorage.removeItem("accountId");
      localStorage.removeItem("orderId");
      localStorage.removeItem("billID");
      localStorage.removeItem("PaymentConnections");
      if (billId) {
        history.push(routePaths.BILL_DETAILS);
      } else {
        history.push(routePaths.INITIATE_PAYMENT);
      }
    }
  }

  render() {
    return (
      <div>
        {this.state.response &&
        this.state.response.mainData &&
        this.state.contentRes ? (
          <PaymentConfirmationComponent response={this.state} />
        ) : (
          <Typography
                  align="center"
                  color="primary"
                  variant="h6"
                  className="noDataFound"
                >
                {
                  this.state.error ? (
                    this.state.error
                  ) : (
                    this.state.waitContent? this.state.waitContent.answer :null
                  )
                }
                </Typography>
        )}
      </div>
    );
  }
}

const mapDispachToProps = {
  accountDetailsWithApiCall
};
const connectedApp = connect(null, mapDispachToProps)(PaymentConfirmationDebitCard);
export { connectedApp as PaymentConfirmationDebitCard };