import axios from "axios";
import { config } from "../../../enviroment";
import { routePaths } from "../../config/route-paths";
import { history } from "../../../helpers/index";
import { store } from "../../../helpers/index";

export const InitiatePaymentService = {
  getContent: getContentDetails,
  prePaymentProcessing,
  postPaymentProcessing,
  postPaymentProcessingBillPay,
  postPaymentProcessingDebitPay,
  postPaymentProcessingDebitBillPay,
  postPaymentProcessingCreditBillPay,
  postPaymentProcessingPay,
};
function generateGraphQLQuery(requestInput) {
  let requestInputFields = "";

  for (const [key, value] of Object.entries(requestInput)) {
    const escapedValue =
      typeof value === "string" ? value.replace(/"/g, '\\"') : value;
    requestInputFields += `${key}: "${escapedValue}",\n`;
  }

  let APIquery = `
    query {
      postPaymentProcessing (
        input: {
          RequestInput: {
            ${requestInputFields}
          }
        }
      )
    }
  `;

  return APIquery;
}
async function getContentDetails() {
  let APIquery = `query{
        getContents(input:{categoryCode:"INITIATE_PAYMENT"})
        }`;
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.ADMIN_SERVICE_BASE_URL,
      method: "post",
      data: {
        query: APIquery,
      },
    })
      .then(function(response) {
        let getRetryPage = localStorage.getItem("getRetryPage");
        if (getRetryPage && getRetryPage == "true") {
          history.push(routePaths.RETRY);
        }
        resolve(response.data);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function prePaymentProcessing(
  connectionId,
  totalSelAmount,
  creditCardGatewayCode
) {
  let bearer = localStorage.getItem("bearer");
  let emailId = "";
  let userName = "";
  let sessionId = "";
  if (bearer) {
    bearer = JSON.parse(bearer);
    emailId = bearer?.email;
    userName = bearer?.userName;
    sessionId = bearer?.sessionId;
  }
  let globalStore = null;
  let firstName = "";
  let lastName = "";
  if (globalStore === null) {
    globalStore = store.getState();
  }
  if (globalStore) {
    firstName = globalStore.accountInformation.demographicDetails.firstName;
    lastName = globalStore.accountInformation.demographicDetails.lastName;
  }
  let tenantCode = config.constants.BASE_TENANT_CODE;
  let details = localStorage
    .getItem("PaymentConnections")
    .replace(/"([^"]+)":/g, "$1:");
  let APIquery =
    `query {
    prePaymentProcessing(
      input: {
        RequestInput:{
        Amount: ` +
    totalSelAmount +
    `
        PaymentType:"` +
    creditCardGatewayCode +
    `"
        tenantCode: "` +
    tenantCode +
    `"
        Connections: 
          ` +
    details +
    ` 
    userName:"` +
    userName +
    `"
    primaryEmail:"` +
    emailId +
    `"     
    userSessionId:"` +
    sessionId +
    `"
        firstName: "` +
    firstName +
    `"
      lastName: "` +
    lastName +
    `"
      }
    }
    ) 
  }`;
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.USAGE_SERVICE_BASE_URL,
      method: "post",
      data: {
        query: APIquery,
      },
    })
      .then(function(response) {
        resolve(response.data);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function postPaymentProcessing(
  paymentType,
  finalresult,
  email,
  userName
) {
  let tenantCode = config.constants.BASE_TENANT_CODE;
  let details = localStorage
    .getItem("PaymentConnections")
    .replace(/"([^"]+)":/g, "$1:");
  let bearer = localStorage.getItem("bearer");
  let orderId = localStorage.getItem("orderId");
  let sessionId = "";
  if (bearer) {
    bearer = JSON.parse(bearer);
    sessionId = bearer.sessionId;
  }
  let APIquery =
    `query
  {postPaymentProcessing
    (input:{RequestInput:
      {tenantCode:"` +
    tenantCode +
    `"
      vpc_OrderId:"` +
    orderId +
    `"
      Connections:` +
    details +
    `
      userName:"` +
    userName +
    `"
      primaryEmail:"` +
    email +
    `"     
      userSessionId:"` +
    sessionId +
    `"
      PaymentType:"` +
    paymentType +
    `",
      resultIndicator:"` +
    finalresult +
    `" source : "REGULAR_PAY"
    }})}`;
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.USAGE_SERVICE_BASE_URL,
      method: "post",
      data: {
        query: APIquery,
      },
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function postPaymentProcessingBillPay(
  billId,
  userName,
  primaryEmail,
  propertyCode,
  MobileNumber,
  userSessionId,
  creditCardGatewayCode,
  resultIndicator,
  accountId,
  vpc_OrderId
) {
  let tenantCode = config.constants.BASE_TENANT_CODE;

  return new Promise((resolve, reject) => {
    axios
      .post(config.urls.POSTPAYMENTBILL_PAY, {
        RequestInput: {
          tenantCode: tenantCode,
          userName: userName,
          primaryEmail: primaryEmail,
          propertyCode: propertyCode,
          MobileNumber: MobileNumber,
          languageCode: "en",
          userSessionId: userSessionId,
          PaymentType: creditCardGatewayCode,
          resultIndicator: resultIndicator,
          accountId: accountId,
          vpc_OrderId: vpc_OrderId,
          billId: billId,
          source: "UNPAID_PAY",
        },
      })

      .then(function(response) {
        resolve(response.data);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function postPaymentProcessingDebitPay(
  paymentType,
  amount,
  bankId,
  CurrencyCode,
  responseDate,
  lang,
  MerchantID,
  MerchantModuleSessionID,
  PUN,
  SecureHash,
  Response_ConfirmationID,
  Response_Status,
  Response_StatusMessage,
  Response_AcquirerID,
  Response_CardNumber,
  Response_CardExpiryDate,
  Response_CardHolderName,
  Response_AgentID
) {
  let tenantCode = config.constants.BASE_TENANT_CODE;
  let details = localStorage
    .getItem("PaymentConnections")
    .replace(/"([^"]+)":/g, "$1:");
  let bearer = localStorage.getItem("bearer");
  let sessionId,
    username,
    email = "";
  if (bearer) {
    bearer = JSON.parse(bearer);
    sessionId = bearer.sessionId;
    username = bearer.userName;
    email = bearer.email;
  }

  let APIquery =
    `query {
      postPaymentProcessing (
        input:
        {
          RequestInput:{
            PaymentType: "` +
    paymentType +
    `"
            Amount:"` +
    amount +
    `"
            CurrencyCode:"` +
    CurrencyCode +
    `"
            PUN:"` +
    PUN +
    `"
            EZConnectResponseDate:"` +
    responseDate +
    `"
            ConfirmationID:"` +
    Response_ConfirmationID +
    `"
            MerchantModuleSessionID:"` +
    MerchantModuleSessionID +
    `"
            Status:"` +
    Response_Status +
    `"
            StatusMessage:"` +
    Response_StatusMessage +
    `"
            MerchantID:"` +
    MerchantID +
    `"
            BankID:"` +
    bankId +
    `"
            Lang:"` +
    lang +
    `"
            AcquirerID:"` +
    Response_AcquirerID +
    `"
            ItemID:""
            CardNumber:"` +
    Response_CardNumber +
    `"
            CardExpiryDate:"` +
    Response_CardExpiryDate +
    `"
            CardHolderName:"` +
    Response_CardHolderName +
    `"
            AgentID:"` +
    Response_AgentID +
    `"
            SecureHash:"` +
    SecureHash +
    `"
            
            userSessionId:"` +
    sessionId +
    `",
            tenantCode:"` +
    tenantCode +
    `",
            userName:"` +
    username +
    `" ,
            primaryEmail:"` +
    email +
    `",
            propertyCode:"",
            MobileNumber:"",
            languageCode:"` +
    lang +
    `",
            Connections:` +
    details +
    `  source : "REGULAR_PAY"

            }
        })
    }`;
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.USAGE_SERVICE_BASE_URL,
      method: "post",
      data: {
        query: APIquery,
      },
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function postPaymentProcessingPay(paymentType, RequestInput) {
  let tenantCode = config.constants.BASE_TENANT_CODE;
  let details = localStorage
    .getItem("PaymentConnections")
    .replace(/"([^"]+)":/g, "$1:");
  let bearer = localStorage.getItem("bearer");
  let sessionId,
    username,
    email = "";
  if (bearer) {
    bearer = JSON.parse(bearer);
    sessionId = bearer.sessionId;
    username = bearer.userName;
    email = bearer.email;
  }

  const additionalFields = {
    PaymentType: paymentType,
    tenantCode: tenantCode,
    userSessionId: sessionId,
    userName: username,
    primaryEmail: email,
    Connections: details,
    source: "REGULAR_PAY",
  };
  Object.assign(RequestInput, additionalFields);
  let APIquery = generateGraphQLQuery(RequestInput);

  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.USAGE_SERVICE_BASE_URL,
      method: "post",
      data: {
        query: APIquery,
      },
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
async function postPaymentProcessingDebitBillPay(
  paymentType,
  amount,
  bankId,
  CurrencyCode,
  responseDate,
  lang,
  MerchantID,
  MerchantModuleSessionID,
  PUN,
  SecureHash,
  billId,
  ConfirmationID,
  status,
  statusMessage,
  acquirerId,
  cardNumber,
  cardExpiryDate,
  cardHolderName,
  agentID,
  accountId
) {
  let sessionId,
    username,
    email = "";
  let bearer = localStorage.getItem("bearer");
  let tenantCode = config.constants.BASE_TENANT_CODE;

  if (bearer) {
    bearer = JSON.parse(bearer);
    sessionId = bearer.sessionId;
    username = bearer.userName;
    email = bearer.email;
  }

  return new Promise((resolve, reject) => {
    axios
      .post(config.urls.POSTPAYMENTBILL_PAY, {
        RequestInput: {
          PaymentType: paymentType,
          Amount: amount,
          CurrencyCode: CurrencyCode,
          PUN: PUN,
          EZConnectResponseDate: responseDate,
          ConfirmationID: ConfirmationID,
          MerchantModuleSessionID: MerchantModuleSessionID,
          Status: status,
          StatusMessage: statusMessage,
          MerchantID: MerchantID,
          BankID: bankId,
          Lang: lang,
          AcquirerID: acquirerId,
          ItemID: "",
          CardNumber: cardNumber,
          CardExpiryDate: cardExpiryDate,
          CardHolderName: cardHolderName,
          AgentID: agentID === null ? "" : agentID,
          SecureHash: SecureHash,
          tenantCode: tenantCode,
          userName: username,
          primaryEmail: email,
          propertyCode: "",
          languageCode: lang,
          userSessionId: sessionId,
          billId: billId,
          accountId: accountId,
          source: "UNPAID_PAY",
        },
      })

      .then(function(response) {
        resolve(response.data);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
async function postPaymentProcessingCreditBillPay(
  paymentType,
  accountId,
  RequestInput
) {
  let sessionId,
    username,
    email = "";
  let bearer = localStorage.getItem("bearer");
  let billID = localStorage.getItem("billID");
  let tenantCode = config.constants.BASE_TENANT_CODE;

  if (bearer) {
    bearer = JSON.parse(bearer);
    sessionId = bearer.sessionId;
    username = bearer.userName;
    email = bearer.email;
  }
  const additionalFields = {
    PaymentType: paymentType,
    tenantCode: tenantCode,
    userSessionId: sessionId,
    userName: username,
    primaryEmail: email,
    propertyCode: "",
    source: "UNPAID_PAY",
    ItemID: "",
    accountId: accountId,
    billId:billID
  };
  Object.assign(RequestInput, additionalFields);
  return new Promise((resolve, reject) => {
    axios
      .post(config.urls.POSTPAYMENTBILL_PAY, {
        RequestInput: RequestInput,
      })

      .then(function(response) {
        resolve(response.data);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

export default InitiatePaymentService;
