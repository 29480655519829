import React, { useState, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import HomeWorkRoundedIcon from "@material-ui/icons/HomeWorkRounded";
import { appService } from "../../../services/index";
import InfoIcon from "@material-ui/icons/Info";
import Paper from "@material-ui/core/Paper";
import MenuIcon from "@material-ui/icons/Menu";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { history } from "../../../helpers/index";
import { mobiletabStyles } from "./mobile-tabs-style";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { store } from "../../../helpers/store";
import "../sidebar-component/mobile-tabs.css";
import AccountBalanceWallet from "@material-ui/icons/AccountBalanceWallet";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import { selfHelpService } from "../../self-help/self-help.service";
import { useLocation } from "react-router-dom";
import { Grid, Tooltip } from "@material-ui/core";
import SpeedIcon from "@material-ui/icons/Speed";

let isFooter = false;
function LinkTab(props) {
  const setComponent = (component) => {
    props.props.propsDetails.history.push(component);
  };
  return (
    <React.Fragment>
      {props.tooltip ? (
        <Tooltip arrow title={props.tooltip}>
          <Tab
            component="a"
            onClick={(event) => {
              setComponent(props.route);
            }}
            {...props}
          />
        </Tooltip>
      ) : (
        <Tab
          component="a"
          onClick={(event) => {
            setComponent(props.route);
          }}
          {...props}
        />
      )}
    </React.Fragment>
  );
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function IconTabs(props) {
  let widgets = store.getState().parametersWidgets.WidgetLookUp;
  const classes = mobiletabStyles();
  let labels = {};
  let footerlabels = {};
  let components = [];
  if (appService.getLabels()) {
    labels = appService.getLabels().getLabels.SIDEBAR;
    footerlabels = appService.getLabels().getLabels.FOOTER;
    components = [
      {
        title: labels.SIDEBAR_HOME,
        route: "",
        icon: <HomeWorkRoundedIcon className={classes.sidebaricons} />,
        widgetCode: "SIDEBAR.DASHBOARD",
      },
      {
        title: labels.SIDEBAR_USAGE,
        route: "",
        icon: <SpeedIcon className={classes.sidebaricons} />,
        widgetCode: "SIDEBAR.USAGE",
      },
      {
        title: labels.SIDEMENU_BILLING,
        route: "",
        icon: <AccountBalanceWallet className={classes.sidebaricons} />,
        widgetCode: "SIDEBAR.BILLING",
        desc: "",
      },
      {
        title: labels.SIDEBAR_SELFHELP,
        route: "",
        icon: <InfoIcon className={classes.sidebaricons} />,
        widgetCode: "SIDEBAR.SELFHELP",
        desc: "",
      },
      {
        title: labels.SIDEBAR_SUPPORT,
        route: "",
        icon: <QuestionAnswerIcon className={classes.sidebaricons} />,
        widgetCode: "SIDEBAR.SUPPORT",
        desc: "",
        tooltip: labels.SIDEBAR_SUPPORT_TOOLTIP,
      },
      {
        title: labels.SIDEBAR_MAKEPAYMENTS,
        route: "",
        icon: <QuestionAnswerIcon className={classes.sidebaricons} />,
        widgetCode: "SIDEBAR.MAKEPAYMENTS",
        desc: "",
      },
    ];
  }

  const componentsTabs = [];

  let presetComponents = [];

  if (widgets) {
    widgets.forEach((item, key) => {
      components.forEach((com, k) => {
        if (item.widgetCode === com.widgetCode) {
          if (item.widgetType === "MENU") {
            if (item.widgetDefaultValue === "true") {
              com.route = item.widgetRoute;
              presetComponents.push(com);
              componentsTabs.push(com);
            }
          }
        }
      });
    });
    if (widgets) {
      widgets.forEach((item, key) => {
        if (
          item.widgetCode === "FOOTER" &&
          item.widgetDefaultValue === "true"
        ) {
          isFooter = true;
        }
      });
    }

    let moreTab = {
      title: labels.SIDEBAR_MORE_TITLE,
      route: "",
      icon: <MenuIcon className={classes.sidebaricons} />,
      widgetCode: "SIDEBAR_MORE_TITLE",
    };
    presetComponents.push(moreTab);
  }
  let location = useLocation();
  useEffect(() => {}, [location]);
  const [value, setValue] = React.useState(0);
  useEffect(() => {
    if (presetComponents) {
      presetComponents.forEach((item, key) => {
        if (item.route === history.location.pathname) {
          setValue(key);
        }
      });
    }
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, route) => {
    let getValue = "";
    presetComponents.forEach((item, i) => {
      if (history.location.pathname === item.route) {
        getValue = i;
      }
    });
    setValue(getValue);
    setOpen(false);
  };
  const routingfn = (event, route) => {
    history.push(route);
    setOpen(false);
  };
  const [footerData, setFooterData] = useState(null);
  const [policyLink, setPolicyLink] = useState(null);
  const [LinkdinLink, setLinkdinLink] = useState(null);
  const [FacebookLink, setFacebookLink] = useState(null);
  useEffect(() => {
    selfHelpService.selfHelp("en", "LINKS").then(
      (response) => {
        setFooterData(response.LINKS);
      },
      (error) => {
        return (
          <div>
            <p>Failed to Load the Resource</p>
          </div>
        );
      }
    );
  }, []);
  if (footerData && FacebookLink === null && LinkdinLink === null) {
    footerData.forEach((item) => {
      item.contentCode === "TERMS_AND_POLICIES" && setPolicyLink(item.answer);
      item.contentCode === "FACEBOOK" && setFacebookLink(item.answer);
      item.contentCode === "LINKEDIN" && setLinkdinLink(item.answer);
    });
  }
  return (
    <Paper square className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example"
        className={classes.tab}
      >
        {presetComponents.map((component, k) => {
          if (component.title === labels.SIDEBAR_MORE_TITLE) {
            return (
              <LinkTab
                className={classes.tabIcons}
                icon={component.icon}
                props={props}
                onClick={handleClickOpen}
                key={k}
                label={component.title}
              />
            );
          } else {
            if (k < 4) {
              return (
                <LinkTab
                  className={classes.tabIcons}
                  icon={component.icon}
                  props={props}
                  route={component.route}
                  key={k}
                  label={component.title}
                  tooltip={component.tooltip}
                />
              );
            }
          }
        })}
      </Tabs>
      <Dialog
        className={classes.dialog}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <IconButton color="inherit" onClick={handleClose} aria-label="close">
            <Typography className="mobile-copyright smallLabel">
              {labels.SIDEBAR_NAVIGATION}
            </Typography>
            <span>
              <CloseIcon className="closeIcon" />
            </span>
          </IconButton>
        </AppBar>
        <List className={classes.list}>
          {componentsTabs.map((component, k) => (
            <div key={k}>
              <ListItem
                className={classes.listItem}
                button
                props={props}
                onClick={() => routingfn(this, component.route)}
              >
                <ListItemText primary={component.title} />
              </ListItem>
              <Divider className={classes.divider} />
            </div>
          ))}
          <div>
            {isFooter ? (
              <div className={classes.footer}>
                <div className="footer-cls">
                  <Grid
                    container
                    alignItems="center"
                    direction="row"
                    justify="center"
                  >
                    <Grid item>
                      <a
                        className={classes.footerLinks}
                        href={policyLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {footerlabels.FOOTER_TERMS_AND_POLICIES}
                      </a>
                      <span className={classes.mobilebulletCls}>&#8226;</span>
                    </Grid>
                    <Grid>
                      <a
                        className={classes.footerLinks}
                        href={FacebookLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {footerlabels.FOOTER_FACEBOOK}
                      </a>
                      <span className={classes.mobilebulletCls}>&#8226;</span>
                    </Grid>
                    <Grid align="center">
                      <a
                        className={classes.footerLinks}
                        href={LinkdinLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {footerlabels.FOOTER_LINKEDIN}
                      </a>
                    </Grid>
                  </Grid>
                </div>
                <Box>
                  <Copyright />
                </Box>
              </div>
            ) : null}
          </div>
        </List>
      </Dialog>
    </Paper>
  );
}

function Copyright() {
  let footerlabels = {};
  if (appService.getLabels()) {
    footerlabels = appService.getLabels().getLabels.FOOTER;
  }
  return (
    <div className="mobile-copyright smallLabel">
      <Typography>
        {/* &#169; &nbsp; */}
        {footerlabels.FOOTER_COPY_RIGHT_COMPANY_NAME}&nbsp;
        {/* {"v1.0, "} */}
        {new Date().getFullYear()}
        {footerlabels.FOOTER_COPY_RIGHT_PRIVATE}
      </Typography>
    </div>
  );
}
