import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Card,
  AppBar,
  Toolbar,
  Drawer,
  Slide,
  useScrollTrigger,
  Box,
  Grid,
} from "@material-ui/core";
import Routes from "../config/routes";
import { useMediaQuery } from "react-responsive";
import { connect } from "react-redux";
import useStyles from "./layout-style";
import { languageActions } from "../../actions";
import {UserProfileMenu} from "./header/profile-menu";
import Sidebar from "./sidebar-component/sidebar-component";
import { commonService } from "../../services/common.service";
import "../app/base-style.css";
import "../layout/layout.css";
import { routePaths } from "../config/route-paths";
import { appService } from "../../services/app.service";
import Footer from "../layout/footer";
import headerLogo from "../../assests/images/qc-default-logo.png";
import { store } from "../../helpers/store";
import PropTypes from "prop-types";
import InactiveAccountBanner from "./inactive-account-banner/inactive-account-banner";
import LinearProgress from "./linear-loader";
import Retry from "../common/retry/retry";
import { history } from "../../helpers/history";
import { appActions } from "../../actions/index";
import { MigrateUserBanner } from "./migrate-user-banner/MigrateUserBanner";
import ErrorBoundary from "./error-boundary"
function Layout(props) {
  const classes = useStyles();
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 768px)" });
  const [progressLoader, setprogressLoader] = useState(null);

  store.subscribe(() => {
    if (store.getState().parametersWidgets.ParameterLookup) {
      setprogressLoader(store.getState().snackbar);
    }
  });
  return (
    <ErrorBoundary>
    <div className={classes.root}>
      <div>
        <LinearProgress progressLoad={progressLoader} />
      </div>
      <HideOnScroll {...props}>
        <AppBar className={clsx(classes.appBar)}>
          <Appbardisplay propsLayout={props} />
        </AppBar>
      </HideOnScroll>
      <InactiveAccountBanner />
      <MigrateUserBanner />
      <div>
        {isTabletOrMobile ? (
          <div className="sidemenuContent">
            <div>
              <Box m={4}>
                <Card className={classes.sideNavigation}>
                  <div>
                    <Sidebar history={props.history} view={isTabletOrMobile} />
                  </div>
                </Card>
              </Box>
            </div>
          </div>
        ) : (
          <Drawer variant="persistent" anchor="bottom" open={true}>
            {window.location.pathname != routePaths.RETRY && (
              <Sidebar history={props.history} view={isTabletOrMobile} />
            )}
          </Drawer>
        )}
      </div>
      <div className="main-content">
        <Routes />
        <Footer store={store} />
      </div>
    </div>
    </ErrorBoundary>
  );
}

function Appbardisplay(props) {
  const classes = useStyles();
  const [appLogo, setAppLogo] = useState(null);
  const [tenantLogo, setTenantLogo] = useState(null);
  const [progressLoader, setprogressLoader] = useState(null);
  let labels = {};
  if (appService.getLabels()) {
    labels = appService.getLabels().getLabels.HEADER;
  }
  store.subscribe(() => {
    if (store.getState().parametersWidgets.ParameterLookup) {
      setTenantLogo(
        store.getState().parametersWidgets.ParameterLookup.TENANT_APP_LOGO
      );
      setprogressLoader(store.getState().snackbar);
    }
  });
  useEffect(() => {
    if (tenantLogo) {
      props.propsLayout.getAppLogo(tenantLogo);
    }
  }, [tenantLogo]);
  store.subscribe(() => {
    if (store.getState().appLogo) {
      setAppLogo(store.getState().appLogo);
    }
  });
  return (
    <div>
      <Grid>
        <Toolbar className={classes.toolbar}>
          <a href={routePaths.HOME}>
            <img
              src={appLogo && appLogo.assetPath ? appLogo.assetPath : headerLogo}
              alt={
                appLogo
                  ? labels[appLogo.fallBackLabelKey]
                  : labels.ASSET_LOGO_FALLBACK_TEXT
              }
              className={classes.appLogo}
            />
          </a>

          <div className={classes.rightMenu}>
            {/* <div className="float-left">
          <PopoverPopupState className={classes.notificationCls} />
        </div> */}
            <div>
              <UserProfileMenu />
            </div>
          </div>
        </Toolbar>
      </Grid>
      <div>
        <LinearProgress progressLoad={progressLoader} />
      </div>
    </div>
  );
}

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const actionCreators = {
  getLanguageList: languageActions.getLanguageList,
  getAppLogo: appActions.getAppLogo,
};

const connectedLayout = connect(null, actionCreators)(Layout);
export { connectedLayout as Layout };
