import axios from "axios";
import { config } from "../enviroment";
import { store } from "../helpers/store";
import { refreshBearerService } from "../services/refresh.bearer.service";

export const appService = {  
  getParametersWidgets: getParametersWidgets,
  getLabels: getLabels,
  downloadLabels: downloadLabels
};

async function getParametersWidgets() {
  return new Promise((resolve, reject) => {
    axios
      .get(config.urls.PARAMETER_WIDGET_CONTROL)
      .then(async response => {
        if(response){
        resolve(response.data);
        }else{
          const callRefresh = await refreshBearerService.refreshBearerToken();
          getParametersWidgets();
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

async function downloadLabels() {
  let APIquery = `{
    getLabels(input:{
      languageCode: "en"})
  }`;
  return new Promise((resolve, reject) => {
    axios({
      url: config.urls.ADMIN_SERVICE_BASE_URL,
      method: 'post',
      data: {
        query: APIquery
      }
    })
      .then(function(response) {
        resolve(response.data);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

function getLabels() {
  var labels = store.getState().labels.data;
  return labels;
}
