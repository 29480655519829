import { appConstants } from "../constants/index";

export function ticketCategory(state = "", action) {
    switch (action.type) {
      case appConstants.GET_CATEGORY: {
        return {
          ...state,
          category: action.category,
          ticketType:action.ticketType
        };
      }
      default:
        return state;
    }
  }

  export function getConnectionDetails(state = "", action) {
    switch (action.type) {
      case appConstants.GET_CONNECTION_SUCCESS: {
        return {
          ...state,
          connectionDetails: action.connectionDetails,
        };
      }
      default:
        return state;
    }
  }