import React from "react";
import BillComparisonComponent from "./bill-comparison-component";
import { appService } from "../../services/app.service";
import { Card, CardContent, Typography } from "@material-ui/core";
import "./bill-comparison-style.css";
import { useStyles } from "../app/base-style";
import Skeleton from "@material-ui/lab/Skeleton";
import { store } from "../../helpers/store";
import { billingHistoryService } from "../billing-history/billing-history-service";
import moment from "moment";

export default class BillComparison extends React.Component {
  constructor(props) {
    super();
    this.state = {
      response: null,
      error: false,
      connectionId: null,
      accountId: null,
    };
    this.billService = this.billService.bind(this);
  }
  componentWillMount() {
    if (this.props) {
      this.setState({
        accountId: this.props.accountId,
      });
      if (this.props.accountId) {
        this.billService(this.props.accountId);
      }
    }
  }

  billService(accountId) {
    let startDate = "2010-10-10";
    let current = new Date();
    let endDate = moment(current).format("YYYY-MM-DD");
    billingHistoryService.gettingData(endDate, accountId, startDate).then(
      (res) => {
        if (res.status === 204) {
          this.setState({
            error: true,
          });
        } else {
          if (
            res.data &&
            res.data.getBillPayHistory.financialBillHistory.history
          ) {
            this.setState({
              error: false,
            });
            let data = [];
            res.data.getBillPayHistory.financialBillHistory.history.forEach(
              (element) => {
                if (element.label === "Bill") {
                  data.push(element);
                }
              }
            );
            if (data) {
              this.setState({
                response: data,
              });
            }
          } else {
            this.setState({
              error: true,
            });
          }
        }
      },
      (error) => {
        this.setState({
          error: true,
        });
      }
    );
  }

  componentDidUpdate() {
    if (store.getState().meterDetails.accountId !== this.state.accountId) {
      this.setState({
        accountId: store.getState().meterDetails.accountId,
      });
      this.billService(store.getState().meterDetails.accountId);
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.error === false ? (
          this.state.response ? (
            this.state.response.length > 1 ? (
              <BillComparisonComponent res={this.state.response} />
            ) : (
              <NoBill />
            )
          ) : (
            <Card className="card">
              <Skeleton variant="text" />
            </Card>
          )
        ) : (
          <NoBill />
        )}
      </React.Fragment>
    );
  }
}

function NoBill() {
  let labels = {};
  if (appService.getLabels()) {
    labels = appService.getLabels().getLabels.BILLING_COMPARISON;
  }
  const classes = useStyles();

  return (
    <Card className="card">
      <CardContent>
        <Typography
          color="primary"
          gutterBottom
          variant="h6"
          component="h2"
          align="center"
        >
          <b>{labels.BILLING_COMPARISON_TITLE}</b>
        </Typography>

        <Typography className="cardFallback" align="center">
          <b className={classes.h2Secondary}>
            {labels.BILLING_COMPARISON_NO_BILL}
          </b>
        </Typography>
      </CardContent>
    </Card>
  );
}
