import { appConstants } from "../constants/index";

export const ticketAction = {
  getTicketCategory,
  getConnection
};

function getTicketCategory(category,ticketType){
    return dispatch => {
      dispatch(getCategory(category,ticketType));
    };
  
    function getCategory(category,ticketType) {
      return { type: appConstants.GET_CATEGORY, category:category,ticketType:ticketType };
    }
  }

function getConnection(connectionDetails){
    return dispatch => {
      dispatch(getPreselectConnection(connectionDetails));
    };
  
    function getPreselectConnection(connectionDetails) {
      return { type: appConstants.GET_CONNECTION_SUCCESS, connectionDetails:connectionDetails };
    }
 }