import { makeStyles } from "@material-ui/core/styles";

const SearchStyle = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#D4D8DC",
    marginLeft: 0,
    width: "70%"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 2),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  back: {
    left: "-30px"
  },
  maindiv: {
    position: "absolute",
    right: "-30px",
    width: "300px"
  },
  titleE: {
    textAlign: "start"
  },
  titleA: {
    textAlign: "end"
  },
  expansionPanelSummaryContent: {
    display: "none",
    textAlign: "right"
  },
  expansionPanelSummaryExpandIcon: {
    backgroundColor: "darkseagreen",
    padding: "5px",
    display: "left"
  },
  searchBox: {
    background: "#D7DBDF",
    padding: "5px",
    borderRadius: "5px",
    height: "35px",
    width:"100%"
  },
  closeIcon: {
    fontSize: "20px"
  },
  searchdiv: {
    float: "right",
    marginTop: "-33px",
    width:"25%",
    marginRight: "-5px"
  },
  searchMobileView: {
    float: "right",
    marginTop: "-5px",
    width: "70%",
    marginRight: "-5px"
  },
  backText: {
    fontSize: "Large",
    color: theme.palette.primary.main,
    marginTop:"10px"
  },
  iconstyle:{
    paddingBottom:"4px !important"
  },
  clearFont:{
    color: theme.palette.primary.main,
    fontSize: "13px",
    fontWeight: "600",
    cursor: "default"
  },
  faqTitle: {
    marginLeft: "40%",
    marginBottom: "-25px"
  },
  topicContains: {
    color: "#C0C0C0"
  },
  cursor:{
    cursor: "default"
  },
  primaryColor: {
    color: theme.palette.primary.main + " !important",
    backgroundColor: "none"
  },
  primaryColorSF: {
    color: theme.palette.primary.main + " !important",
    backgroundColor: "none",
    marginBottom: "5px"
  }
}));

export default SearchStyle;
