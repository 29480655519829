import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import Avatar from "@material-ui/core/Avatar";
import { appService } from "../../../services/app.service";
import { commonService } from "../../../services/common.service";
import "../../app/base-style.css";
import "../layout.css";
import { useHistory } from "react-router-dom";
import { routePaths } from "../../config/route-paths";
import profileImageDark from "../../../assests/images/profile-dark.png";
import { useDispatch } from "react-redux";
import { updateSelectedVal } from "../../../actions/profile.action";
import { store } from "../../../helpers/store";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { appActions } from "../../../actions/index";

function UserProfileMenu(props) {
  let labels,
    labelPay = {};
  if (appService.getLabels()) {
    labels = appService.getLabels().getLabels.HEADER;
    labelPay = appService.getLabels().getLabels.ACCOUNT_SUMMARY
      .ACCOUNT_SUMMARY_USAGE_PAYNOW;
  }
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [profileImgDark, setProfileImgDark] = useState(null);
  const prevOpen = React.useRef(open);
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const profileDarkIcon = useSelector(
    (state) => state?.parametersWidgets?.ParameterLookup?.PROFILE_DARK_ICON
  );

  useEffect(() => {
    if (profileDarkIcon) {
      props.getprofileDarkIcon(profileDarkIcon);
    }
  }, [profileDarkIcon]);

  const profile_image = useSelector((state) => state?.profileDarkIcon);
  useEffect(() => {
    setProfileImgDark(profile_image);
  }, [profile_image]);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    history.push(routePaths.PROFILE);
    store.dispatch(updateSelectedVal(event.target.value));
    setOpen(false);
  };

  const handleClick = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    history.push(routePaths.GET_SUPPORT);
    setOpen(false);
  };
  const handleClickMakePayment = (e) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return;
    }
    history.push(routePaths.INITIATE_PAYMENT);
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const logout = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    commonService.logoutUser();
  };

  const handleCloseAway = () => {
    setOpen(false);
  };
  return (
    <div className="cursor-pointer">
      <Avatar
        className="imageWidth"
        src={
          profileImgDark && profileImgDark.assetPath
            ? profileImgDark.assetPath
            : profileImageDark
        }
        alt="profile_image"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      />

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseAway}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    className="profile-menu-item"
                    id="profile-menu-option-details"
                    onClick={handleClose}
                    value={0}
                  >
                    {labels.PROFILE_MENU_OPTION_PROFILE_DETAILS}
                  </MenuItem>
                  <MenuItem
                    className="profile-menu-item"
                    id="profile-menu-option-password"
                    onClick={handleClose}
                    value={2}
                  >
                    {labels.PROFILE_MENU_OPTION_LINKED_ACCOUNTS}
                  </MenuItem>
                  <MenuItem
                    className="profile-menu-item"
                    id="profile-menu-option-preferences"
                    onClick={handleClick}
                  >
                    {labels.PROFILE_MENU_OPTION_GET_HELP}
                  </MenuItem>
                  <MenuItem
                    className="profile-menu-item"
                    id="profile-menu-option-preferences"
                    onClick={handleClickMakePayment}
                  >
                    {labelPay}
                  </MenuItem>
                  <MenuItem
                    className="profile-menu-item"
                    id="profile-menu-option-logout"
                    onClick={logout}
                  >
                    {labels.PROFILE_MENU_OPTION_LOGOUT}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

const actionCreators = {
  getprofileDarkIcon: appActions.getprofileDarkIcon,
};

const connectedUserProfileMenu = connect(null, actionCreators)(UserProfileMenu);
export { connectedUserProfileMenu as UserProfileMenu };
