import React from "react";
import { adminService } from "../../services/admin.service";
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      sendingLog: false,
    };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    let errorObj = { error: error, errorInfo: errorInfo, sendingLog: true };
    this.setState({ ...errorObj, sendingLog: true });
    adminService
      .log({
        key: "UI_ERROR",
        message: JSON.stringify(errorObj),
        stackOrLog: "LOG",
      })
      .then((res) => {
        this.setState({ sendingLog: false });
      })
      .catch((error) => {
        this.setState({ sendingLog: false });
      });
  }
  render() {
    if (this.state.hasError || this.state.errorInfo || this.state.error) {
      // You can render any custom fallback UI
      return (
        <React.Fragment>
          <h1>Something went wrong.</h1>
          {this.state.error && (
            <>
              <p>Error Information</p>
              <p>{this.state.error.toString()}</p>
            </>
          )}
          {this.state.errorInfo && (
            <>
              <p>More Error Information</p>
              <p>{JSON.stringify(this.state.errorInfo)}</p>
            </>
          )}
          {this.state.sendingLog && (
            <>
              <h4>
                <b>Please Wait: Sending Logs...</b>
              </h4>
            </>
          )}
        </React.Fragment>
      );
    }
    return this.props.children;
  }
}
